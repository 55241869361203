import { Modal } from "bootstrap";
import React from "react";
import templatePresetService from "../../services/templatePresetService";
import { ItemData, TemplatePreset } from "../../types";

interface ConfimationModal {
  modal: Modal | null;
  preset: TemplatePreset;
  data: ItemData;
  onSave: (preset: TemplatePreset | null) => void;
  onMessage: (type: string, text: string) => void;
}

// eslint-disable-next-line react/display-name
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line react/display-name
const SaveTemplatePresetConfirmationDialog = React.forwardRef(
  (props: ConfimationModal, ref: React.Ref<HTMLDivElement>) => {
    const handleCancel = () => {
      props.modal?.hide();
    };

    const handleSubmit = () => {
      if (props.data && props.preset.name) {
        const presetData: Omit<ItemData, "identifier"> = {
          colorPreset: props.data.colorPreset,
          width: props.data.width,
          height: props.data.height,
          sheetType: props.data.sheetType,
          mountingMethod: props.data.mountingMethod,
          plateType: props.data.plateType,
          extraType: props.data.extraType,
          backSide: props.data.backSide,
          texts: props.data.texts,
        };

        const presetToSave = { name: props.preset.name, data: presetData };
        templatePresetService
          .updateTemplatePreset(props.preset.id, presetToSave)
          .then((response) => {
            if (response.status === 200) {
              props.onMessage("success", "Asetuspohja tallennettu");
              props.onSave(response.data);
              props.modal?.hide();
            } else {
              console.dir(response);
              props.onMessage("danger", "Virhe asetuspohjaa tallennettaessa");
              props.onSave(null);
              props.modal?.hide();
            }
          });
      }
    };

    return (
      <div
        id="saveTemplatPresetConfirmationModal"
        className="modal"
        tab-index="-1"
        ref={ref}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Vahvista tallennus</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => handleCancel()}
              ></button>
            </div>
            <form>
              <div className="modal-body">
                Haluatko varmasti tallentaa pohjan{" "}
                <span className="text-danger">{props.preset?.name}</span>?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => handleCancel()}
                >
                  Ei
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => handleSubmit()}
                >
                  Kyllä
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  },
);

export default SaveTemplatePresetConfirmationDialog;
