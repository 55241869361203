import React, { useCallback, useEffect, useReducer, useState } from "react";

import Navbar from "./components/Navbar";
import NavbarHelper from "./components/NavbarHelper";

import Container from "./components/Container";

import Templates from "./Templates";
import Orders from "./Orders";
import Users from "./Users";
import Alert from "./components/Alert";
import Editor from "./Editor";
import { Order, OrderItem, Project, TemplateStub } from "./types";
import Projects from "./Projects";
import ProjectItemEditor from "./ProjectItemEditor";

interface InitialState {
  component: string;
  previousComponent: string;
  order: Order | null;
  orderItem: Partial<OrderItem> | null;
  template: TemplateStub | null;
  project: Partial<Project> | null;
  projectItemId: string | null;
}

type Action = {
  component: string;
  previousComponent: string;
  order: Order | null;
  orderItem: Partial<OrderItem> | null;
  template: TemplateStub | null;
  project: Partial<Project> | null;
  projectItemId: string | null;
};

function homeReducer(state: InitialState, action: Action) {
  return {
    ...state,
    component: action.component,
    previousComponent: action.previousComponent,
    order: action.order,
    orderItem: action.orderItem,
    template: action.template,
    project: action.project,
    projectItemId: action.projectItemId,
  };
}

const initialState: InitialState = {
  component: "/",
  previousComponent: "",
  order: null,
  orderItem: null,
  template: null,
  project: null,
  projectItemId: null,
};

const defaultPage = "/templates";

function Home(): JSX.Element {
  const [state, dispatch] = useReducer(homeReducer, initialState);

  const [message, setmessage] = useState({ type: "success", text: "" });

  const [messageTimeout, setMessageTimeout] = useState<number | null>(null);

  const updateHistory = (nextComponent: string, args: string) => {
    switch (nextComponent) {
      case "/":
      case "/templates":
        history.pushState(
          { component: "/templates", args: args },
          "Templates",
          "/templates",
        );
        break;
      case "/orders":
        history.pushState(
          { component: "/orders", args: args },
          "Orders",
          "/orders",
        );
        break;
      case "/projects":
        history.pushState(
          { component: "/projects", args: args },
          "Projects",
          "/projects",
        );
        break;
      case "/users":
        history.pushState(
          { component: "/users", args: args },
          "Users",
          "/users",
        );
        break;
      case "/editor":
        history.pushState(
          { component: "/editor", args: args },
          "Editor",
          `/editor`,
        );
        break;
      case "/project-item-editor":
        history.pushState(
          { component: "/project-item-editor", args: args },
          "ProjectItemEditor",
          `/project-item-editor`,
        );
        break;
      default:
        break;
    }
  };

  const followLocation = useCallback(
    (shouldUpdateHistory = false) => {
      const current_path = window.location.pathname;

      // eslint-disable-next-line prefer-const
      let [nextComponent, args] = current_path.split("#");

      if (nextComponent == "/") {
        nextComponent = defaultPage;
      }

      if (state.component !== nextComponent) {
        if (shouldUpdateHistory) {
          updateHistory(nextComponent, args);
        }
        handleComponentChange(
          nextComponent,
          null,
          null,
          null,
          null,
          null,
          false,
        );
      }
    },
    [state.component],
  );

  useEffect(() => {
    followLocation(true);

    window.onpopstate = function () {
      followLocation(false);
    };

    return () => {
      if (messageTimeout) {
        clearTimeout(messageTimeout);
        setMessageTimeout(null);
      }
      window.onpopstate = null;
    };
  }, []);

  const handleMessage = (type: string, text: string) => {
    if (messageTimeout) {
      clearTimeout(messageTimeout);
    }

    setmessage({ type, text });

    setMessageTimeout(
      setTimeout(() => {
        setmessage({ type: "", text: "" });
      }, 3000) as unknown as number,
    );
  };

  const handleComponentChange = (
    nextComponent: string,
    order: Order | null,
    orderItem: Partial<OrderItem> | null,
    templateStub: TemplateStub | null,
    project: Project | null,
    projectItemId: string | null,
    shouldUpdateHistory = true,
  ) => {
    dispatch({
      component: nextComponent,
      previousComponent: state.component == "/" ? defaultPage : state.component,
      order,
      orderItem,
      template: templateStub,
      project,
      projectItemId: projectItemId,
    });

    if (shouldUpdateHistory) {
      updateHistory(nextComponent, "");
    }
  };

  const content: React.FC = () => {
    let _content = null;
    switch (state.component) {
      case "/orders":
        _content = (
          <Orders
            order={state.order}
            onNavigate={(component, order, orderItem, template) =>
              handleComponentChange(
                component,
                order,
                orderItem,
                template,
                null,
                null,
              )
            }
            onMessage={(type, text) => handleMessage(type, text)}
          />
        );
        break;
      case "/projects":
        _content = (
          <Projects
            project={state.project}
            onNavigate={(
              component,
              order: Order | null,
              project: Project | null,
              projectItemId: string | null,
            ) =>
              handleComponentChange(
                component,
                order,
                null,
                null,
                project,
                projectItemId,
              )
            }
            onMessage={(type, text) => handleMessage(type, text)}
          />
        );
        break;
      case "/users":
        _content = (
          <Users onMessage={(type, text) => handleMessage(type, text)} />
        );
        break;
      case "/editor":
        _content = (
          <Editor
            order={state.order}
            orderItem={state.orderItem}
            templateStub={state.template}
            previousPage={state.previousComponent}
            onNavigate={(component: string, order: Order | null) =>
              handleComponentChange(component, order, null, null, null, null)
            }
            onMessage={(type, text) => handleMessage(type, text)}
          />
        );
        break;
      case "/project-item-editor":
        _content = (
          <ProjectItemEditor
            project={state.project}
            projectItemId={state.projectItemId}
            previousPage={state.previousComponent}
            onNavigate={(component: string, project: Project | null) =>
              handleComponentChange(component, null, null, null, project, null)
            }
            onMessage={(type: string, text: string) =>
              handleMessage(type, text)
            }
          />
        );
        break;
      case "/templates":
      default:
        _content = (
          <Templates
            onMessage={(type, text) => handleMessage(type, text)}
            onNavigate={(component: string, order, orderItem, template) =>
              handleComponentChange(
                component,
                order,
                orderItem,
                template,
                null,
                null,
              )
            }
          />
        );
    }

    if (
      state.component !== "/editor" &&
      state.component !== "/project-item-editor"
    ) {
      return (
        <Container>
          <div className="content">{_content}</div>
        </Container>
      );
    } else {
      return _content;
    }
  };

  const getClassNames = useCallback(
    () => `background-paper ${state.component.replace("/", "") + "Page"}`,
    [state.component],
  );

  return (
    <div className={getClassNames()}>
      <Navbar
        onOpenFrontPage={() =>
          handleComponentChange("/templates", null, null, null, null, null)
        }
      />
      {state.component !== "/editor" &&
        state.component !== "/project-item-editor" && (
          <NavbarHelper
            component={state.component}
            onNavigate={(component) =>
              handleComponentChange(component, null, null, null, null, null)
            }
          />
        )}
      {message.text && (
        <Alert label={message.type} message={message.text}></Alert>
      )}
      {content({})}
    </div>
  );
}

export default Home;
