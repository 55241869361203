/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useRef, useCallback } from "react";
import Sign, { SignErrors } from "./Sign";
import "./Editor.scss";
import ordersService from "./services/ordersService";
import templatePresetservice from "./services/templatePresetService";
import {
  CreateOrder,
  Order,
  OrderItem,
  Template,
  TemplatePreset,
  TemplateStub,
  UpdateOrder,
  ItemTextMapping,
  ExtraType,
  ItemData,
  TextMapping,
  SizeConstraintConditionalBy,
  SizeConstraintType,
  TextSide,
} from "./types";
import SaveTemplatePresetAsModal from "./components/Dialog/SaveTemplatePresetAsDialog";
import SaveTemplatePresetConfirmationDialog from "./components/Dialog/SaveTemplatePresetConfirmationDialog";
import ConfirmTemplatePresetDeleteDialog from "./components/Dialog/ConfirmTemplatePresetDeleteDialog";

import { useFormik } from "formik";
import * as Yup from "yup";
import { Modal } from "bootstrap";
import { FieldNames } from "./FieldNames";

enum Dimension {
  HORIZONTAL,
  VERTICAL,
}

interface EditorProps {
  order: Order | null;
  orderItem: Partial<OrderItem> | null;
  templateStub: TemplateStub | null;
  previousPage: string;
  onMessage: (type: string, text: string) => void;
  onNavigate: (
    type: string,
    order: Order | null,
    orderItem: Partial<OrderItem> | null,
    template: Template | null,
  ) => void;
}

const getEditorSchema = (textErrorChecker: (id: string) => string | null) =>
  Yup.object().shape({
    reference: Yup.string().required("Pakollinen kenttä"),
    description: Yup.string().optional(),
    identifier: Yup.string().required("Pakollinen kenttä"),
    colorPreset: Yup.string().required("Pakollinen kenttä"),
    texts: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(),
        text: Yup.string()
          .min(0)
          .test({
            name: "overflow",
            test: function () {
              if (textErrorChecker) {
                const textError = textErrorChecker(this.parent.id);
                return textError
                  ? this.createError({ message: "" + textError })
                  : true;
              } else {
                return true;
              }
            },
          }),
        fontSize: Yup.number().typeError("Pakollinen kenttä"),
        fontFamily: Yup.string().required("Pakollinen kenttä"),
      }),
    ),
    width: Yup.number()
      .min(1, "Pakollinen kenttä")
      .required("Pakollinen kenttä"),
    height: Yup.number()
      .min(1, "Pakollinen kenttä")
      .required("Pakollinen kenttä"),
    plateType: Yup.string().required("Anna valinta"),
    mountingMethod: Yup.string().required("Anna valinta"),
    extraType: Yup.string().required("Anna valinta"),
    sheetType: Yup.string().required("Pakollinen kenttä"),
    backSide: Yup.boolean().required("Pakollinen kenttä"),
  });

function Editor(props: EditorProps): JSX.Element {
  const [mounted, setMounted] = useState<boolean>(false);
  const [templatePresets, setTemplatePresets] = useState<TemplatePreset[]>([]);
  const [templatePreset, setTemplatePreset] =
    useState<TemplatePreset | null>(null);
  const [prevOrder] = useState<Partial<Order> | null>(
    props.order ? { ...props.order } : null,
  );
  const [saving, setSaving] = useState<boolean>(false);

  const [exporting, setExporting] = useState<boolean>(false);

  const [exitTimeout, setExitTimeout] = useState<number | null>(null);

  const [saveTemplatePresetAsModal, setSaveTemplatePresetAsModal] =
    useState<Modal | null>(null);
  const saveTemplatePresetAsModalRef = useRef<HTMLDivElement>(null);
  const [saveTemplatePresetModal, setSaveTemplatePresetModal] =
    useState<Modal | null>(null);
  const saveTemplatePresetModalRef = useRef<HTMLDivElement>(null);
  const [
    confirmTemplatePresetDeleteModal,
    setConfirmTemplatePresetDeleteModal,
  ] = useState<Modal | null>(null);
  const confirmTemplatePresetDeleteModalRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedTemplatePresetId, setSelectedTemplatePresetId] =
    useState<string | undefined>(undefined);

  const [signErrors, setSignErrors] =
    useState<SignErrors | undefined>(undefined);

  const textErrorChecker = (id: string): string | null => {
    if (signErrors && signErrors[id]) {
      return signErrors[id].join("\n");
    }
    return null;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reference: props.order?.reference ?? "",
      description: props.order?.description ?? "",
      identifier: props.orderItem?.identifier ?? "",
      colorPreset:
        props.orderItem?.data?.colorPreset ||
        props.templateStub?.properties.colorPresets[0].name ||
        "",
      texts:
        props.templateStub?.properties.texts
          .filter((tt) => tt.side !== TextSide.BACK)
          .reduce((out: ItemTextMapping[], templateText: TextMapping) => {
            out.push(
              props.orderItem?.data?.texts.find(
                (t) => t.id === templateText.id,
              ) ?? {
                id: templateText.id,
                fontFamily: templateText.fonts[0],
                fontSize:
                  props.templateStub?.properties.defaultFontSize &&
                  templateText.fontSizes.includes(
                    props.templateStub?.properties.defaultFontSize,
                  )
                    ? props.templateStub?.properties.defaultFontSize
                    : templateText.fontSizes[0],
                text: templateText.placeHolder ?? "",
                ...(templateText.allowSizeReference
                  ? { referenceText: "" }
                  : {}),
              },
            );
            return out;
          }, []) ?? [],
      width: props.orderItem?.data?.width ?? 0,
      height: props.orderItem?.data?.height ?? 0,
      plateType: props.orderItem?.data?.plateType ?? "",
      mountingMethod: props.orderItem?.data?.mountingMethod ?? "",
      extraType: props.orderItem?.data?.extraType ?? "",
      sheetType: props.orderItem?.data?.sheetType ?? "",
      backSide: props.orderItem?.data?.backSide ?? false,
    },
    validateOnMount: true,
    validationSchema: getEditorSchema(textErrorChecker),
    // onSubmit: values => {
    //   alert(JSON.stringify(values, null, 2))
    // },
    onSubmit: () => {
      handleSubmit();
    },
  });

  const handleInputChange = (e: React.ChangeEvent<any>): void => {
    formik.handleChange(e);
  };

  const handleSelectChange = (
    type: string,
    e: React.FormEvent<HTMLSelectElement>,
  ): void => {
    const name = (e.target as HTMLSelectElement)?.name as string;
    formik.setFieldValue(
      name,
      type == "string"
        ? e.currentTarget.value
        : parseInt(e.currentTarget.value, 10),
    );
  };

  const handleCheckBoxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const name = (e.target as HTMLInputElement)?.name as string;
    formik.setFieldValue(name, e.currentTarget.checked);
  };

  const handleDimensionChange = (
    e: React.FormEvent<HTMLSelectElement>,
  ): void => {
    const name = (e.target as HTMLSelectElement)?.name as string;
    formik.setFieldValue(name, parseInt(e.currentTarget.value, 10) || 0);
  };

  const getDimensions = (
    cross_axis_value: number | string,
    dimension: Dimension,
  ): number[] => {
    if (props.templateStub) {
      let template_size_constraints = [];
      switch (dimension) {
        case Dimension.HORIZONTAL:
          //const crossDimension = data.height || 0
          template_size_constraints =
            props.templateStub.properties.horizontalSizes;
          break;
        case Dimension.VERTICAL:
          template_size_constraints =
            props.templateStub.properties.verticalSizes;
          break;
      }

      const sizes: number[] = [];

      for (const constraints of template_size_constraints) {
        if (
          constraints.condition == SizeConstraintConditionalBy.CROSS_AXIS &&
          (!cross_axis_value ||
            cross_axis_value < (constraints.crossAxisMin ?? 0) ||
            cross_axis_value >
              (constraints.crossAxisMax ?? Number.MAX_SAFE_INTEGER))
        ) {
          //if constraints do not match to cross axis value, continue
          continue;
        }

        if (constraints.type == SizeConstraintType.STEPS && constraints.steps) {
          sizes.push(...constraints.steps);
        }
      }

      return [...new Set(sizes)].sort((a, b) => {
        return a - b;
      });
    }
    return [];
  };

  const handleTemplatePresetChange = useCallback(
    (e: React.FormEvent<HTMLSelectElement>) => {
      e.preventDefault();
      let value: string | undefined = e.currentTarget.value;

      switch (value) {
        case "save":
          saveTemplatePresetModal?.show();
          value = selectedTemplatePresetId;
          break;
        case "saveAs":
          saveTemplatePresetAsModal?.show();
          value = selectedTemplatePresetId;
          break;
        case "delete":
          confirmTemplatePresetDeleteModal?.show();
          value = selectedTemplatePresetId;
          break;
      }
      setSelectedTemplatePresetId(value || undefined);
    },
    [
      saveTemplatePresetModal,
      saveTemplatePresetAsModal,
      confirmTemplatePresetDeleteModal,
      setSelectedTemplatePresetId,
      selectedTemplatePresetId,
    ],
  );

  const handleSubmit = () => {
    setSaving(true);
    const data = { ...formik.values };
    if (prevOrder && prevOrder.id && props.templateStub) {
      console.log("Case existing order");
      if (
        prevOrder.remoteId ||
        prevOrder.reference !== data.reference ||
        prevOrder.description !== data.description
      ) {
        console.log("Order data changed, sync order and order item");
        const orderData: UpdateOrder = {
          reference: data.reference,
          description: data.description,
        };

        ordersService.updateOrder(prevOrder.id, orderData).then((response) => {
          console.log("Order updated", response);

          if (response.status === 200 && prevOrder.id) {
            submitOrderItem(prevOrder.id);
          } else {
            props.onMessage("danger", "Tarjouksen päivitys epäonnistui");
            setSaving(false);
          }
        });
      } else {
        console.log("Order data not changed, sync only order item");
        submitOrderItem(prevOrder.id);
      }
      // } else if (order && template) {
    } else {
      const orderData: CreateOrder = {
        reference: formik.values.reference,
        description: formik.values.description,
      };

      ordersService.createOrder(orderData).then((response) => {
        if (response?.status === 201 && response?.data?.id) {
          submitOrderItem(response.data.id);
        } else {
          props.onMessage("danger", "Tarjouksen luominen epäonnistui");
          setSaving(false);
        }
      });
    }
  };

  const submitOrderItem = (orderId: string) => {
    if (orderId && props.templateStub) {
      // Dig svg data directly from dom (could be fetched from SignEditor later on)
      const svgString = document.querySelector(
        ".editor-preview-sign .svg-first",
      )?.outerHTML;

      const isTwoSided = formik.values.extraType == ExtraType.TWO_SIDE;
      const backSvgString = isTwoSided
        ? document.querySelector(".editor-preview-sign .svg-second")?.outerHTML
        : null;

      const data = { ...formik.values };
      if (svgString && (!isTwoSided || backSvgString)) {
        const orderItemData = {
          identifier: data.identifier,
          orderId: orderId,
          templateId: props.templateStub.id,
          svg: svgString,
          backSvg: backSvgString,
          data: {
            colorPreset: data.colorPreset,
            sheetType: data.sheetType,
            mountingMethod: data.mountingMethod,
            plateType: data.plateType,
            extraType: data.extraType,
            backSide: data.backSide,
            width: data.width,
            height: data.height,
            texts: data.texts,
          } as ItemData,
        };

        if (props.orderItem?.id) {
          ordersService
            .updateOrderItem(props.orderItem?.id, orderItemData)
            .then((response) => {
              console.log("Create order item response", response);
              if (response?.status === 200) {
                props.onNavigate(
                  props.previousPage,
                  props.order || null,
                  null,
                  null,
                );
                props.onMessage("success", "Kyltin tallentaminen onnistui");
              } else {
                setSaving(false);
                props.onMessage("danger", "Kyltin päivittäminen epäonnistui");
              }
            });
        } else {
          ordersService.createOrderItem(orderItemData).then((response) => {
            console.log("Create order item response", response);
            if (response?.status === 201) {
              props.onNavigate(
                props.previousPage,
                props.order || null,
                null,
                null,
              );
              props.onMessage("success", "Kyltin tallentaminen onnistui");
            } else {
              setSaving(false);
              props.onMessage("danger", "Kyltin luominen epäonnistui");
            }
          });
        }
      } else {
        setSaving(false);
        alert("Cannot read SVG datas from dom");
      }
    } else {
      setSaving(false);
    }
  };

  useEffect(() => {
    setMounted(true);
    if (!props.templateStub) {
      props.onMessage(
        "danger",
        "Näkymä on avattava uusiksi kylttipohja-näkymän tai tarjous-näkymän kautta",
      );

      setExitTimeout(
        setTimeout(() => {
          if (mounted) {
            props.onNavigate(
              props.previousPage,
              props.order || null,
              null,
              null,
            );
          }
        }, 3000) as unknown as number,
      );
    }

    return () => {
      if (exitTimeout) {
        clearTimeout(exitTimeout);
        setExitTimeout(null);
      }
      setMounted(false);
    };
  }, [mounted]);

  const getAllTemplatePresets = useCallback(
    (presetId?: string) => {
      if (mounted && props.templateStub) {
        templatePresetservice
          .getTemplatePresetsForTemplate(props.templateStub.id)
          .then((response) => {
            if (response.status === 200 && mounted) {
              setTemplatePresets(response.data);

              setSelectedTemplatePresetId(presetId);
            }
          });
      }
    },
    [
      mounted,
      setTemplatePresets,
      setTemplatePreset,
      setSelectedTemplatePresetId,
    ],
  );

  useEffect(() => {
    if (mounted) {
      getAllTemplatePresets(undefined);
    }
  }, [mounted]);

  useEffect(() => {
    if (selectedTemplatePresetId !== undefined) {
      const preset = templatePresets.find(
        (t) => t.id === selectedTemplatePresetId,
      );

      if (preset) {
        const presetData: Omit<ItemData, "identifier"> = {
          colorPreset: preset.data.colorPreset,
          sheetType: preset.data.sheetType,
          mountingMethod: preset.data.mountingMethod,
          plateType: preset.data.plateType,
          extraType: preset.data.extraType,
          backSide: preset.data.backSide,
          width: preset.data.width,
          height: preset.data.height,
          texts: preset.data.texts,
        };

        formik.setValues({
          ...formik.values,
          ...presetData,
        });

        setTemplatePreset(preset);
      }
    } else {
      setTemplatePreset(null);
    }
  }, [selectedTemplatePresetId, setTemplatePreset]);

  const exportSVG = () => {
    setExporting(true);
    try {
      const exportWindow = window.open("", "PRINT", "height=400,width=600");
      const svgString = document.querySelector(
        ".editor-preview-sign",
      )?.innerHTML;
      if (exportWindow && svgString) {
        const title = [
          "Kaskea Group",
          formik.values?.reference,
          formik.values?.identifier,
        ]
          .filter((item) => !!item)
          .join(" - ");
        const buildWaterMark = (text: string) => {
          return `<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='200px' width='200px'><text transform='translate(20, 200) rotate(-45)' fill='rgba(45,45,45,0.08)' font-size='30'>${text}</text></svg>`;
        };

        exportWindow.document.write(`<html><head><title>${title}</title>`);
        exportWindow.document.write(
          `<link rel="stylesheet" href="${process.env.PUBLIC_URL}/static/fonts.css" />`,
        );
        exportWindow.document.write(
          `<style>* {-webkit-print-color-adjust: exact !important; color-adjust: exact !important; } html, body {background-color: #eee; font-family: Traficom20;} .svg-component {width:80%; max-height: 90%;}\n.fill {left:0; top:50; right:0; bottom:0; position:absolute; text-align: center;}\n.overlay {background-image: url("data:image/svg+xml;utf8,${buildWaterMark(
            "Kaskea Group",
          )}");}</style>`,
        );
        exportWindow.document.write("</head><body>");
        exportWindow.document.write(
          `<h1 style="text-align: center;">${title}</h1>`,
        );
        exportWindow.document.write(`<div class="fill">${svgString}</div>`);
        exportWindow.document.write(`<div class="fill overlay"></div>`);
        exportWindow.document.write("</body></html>");
        exportWindow.document.close(); // necessary for IE >= 10
        exportWindow.focus();

        // Wait fonts and page to be loaded before printing the page
        exportWindow.addEventListener("load", () => {
          const fontDocument = exportWindow.document as unknown as {
            fonts: any;
          };
          fontDocument.fonts.ready.then(() => {
            exportWindow.print();
            exportWindow.close();
            setExporting(false);
          });
        });
      } else {
        setExporting(false);
      }
    } catch (error) {
      console.error("Error while exporting");
      setExporting(false);
    }
    return true;
  };

  useEffect(() => {
    if (saveTemplatePresetAsModalRef.current) {
      setSaveTemplatePresetAsModal(
        new Modal(saveTemplatePresetAsModalRef.current, { backdrop: "static" }),
      );
    }
  }, [saveTemplatePresetAsModalRef.current]);

  useEffect(() => {
    if (saveTemplatePresetModalRef.current) {
      setSaveTemplatePresetModal(
        new Modal(saveTemplatePresetModalRef.current, { backdrop: "static" }),
      );
    }
  }, [saveTemplatePresetModalRef.current]);

  useEffect(() => {
    if (confirmTemplatePresetDeleteModalRef.current) {
      setConfirmTemplatePresetDeleteModal(
        new Modal(confirmTemplatePresetDeleteModalRef.current, {
          backdrop: "static",
        }),
      );
    }
  }, [confirmTemplatePresetDeleteModalRef.current]);

  const hasPresetSaveIssues = useCallback(
    () =>
      Object.keys(formik.errors).some(
        (value) => !["reference", "description", "identifier"].includes(value),
      ),
    [formik.errors],
  );

  return (
    <>
      <div className="editor">
        <div className="editor-sidebar">
          <form id="editor-form" onSubmit={formik.handleSubmit}>
            <h3>Tarjouksen tiedot</h3>
            <div className="mb-3">
              <label htmlFor="reference" className="form-label">
                Asiakkaan viite
              </label>
              <input
                type="text"
                className="form-control"
                id="reference"
                name="reference"
                onChange={(e) => handleInputChange(e)}
                value={formik.values.reference}
              />
              {formik.errors.reference && formik.touched.reference ? (
                <div className="text-danger">{formik.errors.reference}</div>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Kuvaus
              </label>
              <input
                type="text"
                className="form-control"
                id="description"
                name="description"
                onChange={(e) => handleInputChange(e)}
                value={formik.values.description}
              />
              {formik.errors.description && formik.touched.description ? (
                <div className="text-danger">{formik.errors.description}</div>
              ) : null}
            </div>
            <h3>Kyltin tiedot</h3>
            <div className="mb-3">
              <label htmlFor="identifier" className="form-label">
                Lisänimike
              </label>
              <input
                type="text"
                className="form-control"
                id="identifier"
                name="identifier"
                onChange={(e) => handleInputChange(e)}
                value={formik.values.identifier}
              />
              {formik.errors.identifier && formik.touched.identifier ? (
                <div className="text-danger">{formik.errors.identifier}</div>
              ) : null}
            </div>
            <div className="mb-3">
              <div className="row align-items-center">
                <label htmlFor="colorPreset" className="form-label">
                  Värit
                </label>
                {props.templateStub?.properties.colorPresets?.map((c, i) => (
                  <div key={i} className="col-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="colorPreset"
                        id={`colorPreset${i}`}
                        checked={formik.values.colorPreset === c.name}
                        onChange={(e) => handleInputChange(e)}
                        value={c.name}
                      />
                      <label
                        className="form-check-label"
                        htmlFor={`colorPreset${i}`}
                      >
                        {c.name}
                      </label>
                    </div>
                    {formik.errors.colorPreset && formik.touched.colorPreset ? (
                      <div className="text-danger">
                        {formik.errors.colorPreset}
                      </div>
                    ) : null}
                  </div>
                ))}
              </div>
              {formik.errors.colorPreset && formik.touched.colorPreset ? (
                <div className="text-danger">{formik.errors.colorPreset}</div>
              ) : null}
            </div>
            {formik.values.texts.map((tm, index) => {
              const templateData = props.templateStub?.properties?.texts.find(
                (tpl) => tpl.id == tm.id,
              );
              return templateData ? (
                <div className="card card-body mb-2" key={`t${tm.id}${index}`}>
                  <div className="mb-3">
                    <label
                      htmlFor={`texts.${index}.text`}
                      className="form-label"
                    >
                      {`Teksti ${
                        formik.values.texts.length > 1 ? `- ${tm.id}` : ""
                      }`}
                    </label>
                    <textarea
                      rows={3}
                      className="form-control"
                      id={`texts.${index}.text`}
                      name={`texts.${index}.text`}
                      cols={templateData.maxLines}
                      onChange={(e) => handleInputChange(e)}
                      value={formik.values.texts[index].text}
                    />
                    {formik.errors.texts &&
                    formik.errors.texts[index] &&
                    (formik.errors.texts[index] as any).text
                      ? (formik.errors.texts[index] as any).text
                          .split("\n")
                          .map((line: string, ei: number) => (
                            <div
                              className="text-danger"
                              key={`te_${index}_${ei}`}
                            >
                              {line}
                            </div>
                          ))
                      : null}
                  </div>
                  {templateData.allowSizeReference && (
                    <div className="mb-3">
                      <label
                        htmlFor={`texts.${index}.referenceText`}
                        className="form-label"
                      >
                        {`Pituusreferenssi ${
                          formik.values.texts.length > 1 ? `- ${tm.id}` : ""
                        }`}
                      </label>
                      <textarea
                        rows={3}
                        className="form-control"
                        id={`texts.${index}.referenceText`}
                        name={`texts.${index}.referenceText`}
                        cols={templateData.maxLines}
                        onChange={(e) => handleInputChange(e)}
                        value={formik.values.texts[index].referenceText}
                      />
                    </div>
                  )}
                  <div className="mb-3">
                    <label
                      htmlFor={`texts.${index}.fontFamily`}
                      className="form-label"
                    >
                      {`Kirjasintyyppi ${
                        formik.values.texts.length > 1 ? `- ${tm.id}` : ""
                      }`}
                    </label>
                    <select
                      className="form-select"
                      id={`texts.${index}.fontFamily`}
                      name={`texts.${index}.fontFamily`}
                      onChange={(e) => handleSelectChange("string", e)}
                      value={formik.values.texts[index].fontFamily}
                    >
                      <option key={0} value={""}>
                        Valitse
                      </option>
                      {templateData.fonts?.map((fontFamily, i) => (
                        <option key={i} value={fontFamily}>
                          {fontFamily}
                        </option>
                      ))}
                    </select>
                    {formik.errors.texts &&
                    formik.touched.texts &&
                    (formik.errors.texts[index] as any) &&
                    (formik.touched.texts[index] as any).fontFamily ? (
                      <div className="text-danger">
                        {(formik.errors.texts[index] as any).fontFamily}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor={`texts.${index}.fontSize`}
                      className="form-label"
                    >
                      {`Tekstin koko ${
                        formik.values.texts.length > 1 ? `- ${tm.id}` : ""
                      }`}
                    </label>
                    <select
                      className="form-select"
                      id={`texts.${index}.fontSize`}
                      name={`texts.${index}.fontSize`}
                      onChange={(e) => handleSelectChange("number", e)}
                      value={formik.values.texts[index].fontSize}
                    >
                      <option key={0} value={undefined}>
                        Valitse
                      </option>
                      {templateData.fontSizes?.map((fontSize, i) => (
                        <option key={i} value={fontSize}>
                          {fontSize}
                        </option>
                      ))}
                    </select>
                    {formik.errors.texts &&
                    formik.touched.texts &&
                    (formik.errors.texts[index] as any) &&
                    (formik.touched.texts[index] as any).fontSize ? (
                      <div className="text-danger">
                        {(formik.errors.texts[index] as any).fontSize}
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null;
            })}
            <div className="mb-3">
              <div className="row g-3 align-items-center">
                <div className="col-6">
                  <label htmlFor="width" className="col-form-label">
                    Leveys (mm)
                  </label>
                  <select
                    className="form-select"
                    id="width"
                    name="width"
                    value={formik.values.width}
                    onChange={(e) => handleDimensionChange(e)}
                  >
                    <option key={0} value={undefined}>
                      Valitse
                    </option>
                    {getDimensions(
                      formik.values.height,
                      Dimension.HORIZONTAL,
                    )?.map((width, i) => (
                      <option key={i} value={width}>
                        {width}
                      </option>
                    ))}
                  </select>
                  {formik.errors.width && formik.touched.width ? (
                    <div className="text-danger">{formik.errors.width}</div>
                  ) : null}
                </div>
                <div className="col-6">
                  <label htmlFor="height" className="col-form-label">
                    Korkeus (mm)
                  </label>
                  <select
                    className="form-select"
                    id="height"
                    name="height"
                    value={formik.values.height}
                    onChange={(e) => handleDimensionChange(e)}
                  >
                    <option key={0} value={undefined}>
                      Valitse
                    </option>
                    {getDimensions(
                      formik.values.width,
                      Dimension.VERTICAL,
                    )?.map((height, i) => (
                      <option key={i} value={height}>
                        {height}
                      </option>
                    ))}
                  </select>
                  {formik.errors.height && formik.touched.height ? (
                    <div className="text-danger">{formik.errors.height}</div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="plateType" className="form-label">
                Taustamateriaali
              </label>
              <select
                className="form-select"
                id="plateType"
                name="plateType"
                onChange={(e) => handleSelectChange("string", e)}
                value={formik.values.plateType}
              >
                <option key={0} value={""}>
                  Valitse
                </option>
                {props.templateStub?.properties.plateTypes?.map((plate, i) => (
                  <option key={i} value={plate}>
                    {FieldNames.plateType[plate]}
                  </option>
                ))}
              </select>
              {formik.errors.plateType && formik.touched.plateType ? (
                <div className="text-danger">{formik.errors.plateType}</div>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="mountingMethod" className="form-label">
                Kiinnitys
              </label>
              <select
                className="form-select"
                id="mountingMethod"
                name="mountingMethod"
                onChange={(e) => handleSelectChange("string", e)}
                value={formik.values.mountingMethod}
              >
                <option key={0} value={""}>
                  Valitse
                </option>
                {props.templateStub?.properties.mountingMethods?.map(
                  (mount, i) => (
                    <option key={i} value={mount}>
                      {FieldNames.mountingMethod[mount]}
                    </option>
                  ),
                )}
              </select>
              {formik.errors.mountingMethod && formik.touched.mountingMethod ? (
                <div className="text-danger">
                  {formik.errors.mountingMethod}
                </div>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="extraType" className="form-label">
                Lisämääritys
              </label>
              <select
                className="form-select"
                id="extraType"
                name="extraType"
                onChange={(e) => handleSelectChange("string", e)}
                value={formik.values.extraType}
              >
                <option key={0} value={""}>
                  Valitse
                </option>
                {props.templateStub?.properties.extraTypes?.map((et, i) => (
                  <option key={i} value={et}>
                    {FieldNames.extraType[et]}
                  </option>
                ))}
              </select>
              {formik.errors.extraType && formik.touched.extraType ? (
                <div className="text-danger">{formik.errors.extraType}</div>
              ) : null}
            </div>
            <div className="mb-3 form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id="backSide"
                name="backSide"
                disabled={formik.values.extraType === ExtraType.TWO_SIDE}
                onChange={(e) => handleCheckBoxChange(e)}
                checked={formik.values.backSide}
              />
              <label htmlFor="backSide" className="form-check-label">
                Käännetty
              </label>
            </div>
            <div className="mb-3">
              <div className="row align-items-center">
                <div className="row align-items-center">
                  <label htmlFor="sheetType" className="form-label">
                    Heijastusluokka
                  </label>
                  {props.templateStub?.properties.sheetTypes?.map((s, i) => (
                    <div key={i} className="col-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="sheetType"
                          id={`sheetType${i}`}
                          checked={formik.values.sheetType === s}
                          onChange={(e) => handleInputChange(e)}
                          value={s}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`sheetType${i}`}
                        >
                          {s}
                        </label>
                      </div>
                      {formik.errors.sheetType && formik.touched.sheetType ? (
                        <div className="text-danger">
                          {formik.errors.sheetType}
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <h3>Asetuspohjat</h3>
            <div className="mb-3">
              <select
                className="form-select"
                name="preset"
                value={selectedTemplatePresetId || ""}
                onClick={() => formik.validateForm()}
                onChange={(e) => handleTemplatePresetChange(e)}
              >
                <optgroup label="Asetuspohjat">
                  <option key={0} value="">
                    Ei valittu
                  </option>
                  {templatePresets?.map((t) => (
                    <option key={t.id} value={t.id}>
                      {t.name}
                    </option>
                  ))}
                </optgroup>
                <optgroup label="Toiminnot">
                  {templatePresets && templatePresets.length && (
                    <option key="delete" value="delete">
                      Poista
                    </option>
                  )}
                  {templatePreset && (
                    <option
                      key="save"
                      value="save"
                      disabled={!templatePreset || hasPresetSaveIssues()}
                    >
                      Tallenna
                    </option>
                  )}
                  <option
                    key="saveAs"
                    value="saveAs"
                    disabled={hasPresetSaveIssues()}
                  >
                    Tallenna nimellä
                  </option>
                </optgroup>
              </select>
            </div>
          </form>
        </div>
        <div className="editor-preview">
          <div className="editor-preview-sign">
            {props.templateStub && formik.values && (
              <Sign
                key={1}
                template={props.templateStub}
                isBackSide={
                  formik.values.extraType == ExtraType.TWO_SIDE
                    ? false
                    : formik.values.backSide
                }
                className="svg-first"
                data={formik.values as Partial<ItemData>}
                onErrors={(errors: SignErrors) => {
                  setSignErrors(errors);
                  formik.validateForm();
                }}
              />
            )}
            {props.templateStub &&
              formik.values &&
              formik.values.extraType == ExtraType.TWO_SIDE && (
                <Sign
                  key={2}
                  template={props.templateStub}
                  isBackSide={true}
                  className="svg-second"
                  data={formik.values as Partial<ItemData>}
                />
              )}
          </div>
          <div className="editor-actions">
            <div className="editor-preview-action-buttons">
              <div className="action-button">
                <button
                  type="button"
                  className="btn btn-kaskea-inverse bold uppercase"
                  disabled={saving}
                  onClick={() =>
                    props.onNavigate(
                      props.previousPage,
                      props.order || null,
                      null,
                      null,
                    )
                  }
                >
                  Sulje
                </button>
              </div>
              <div className="action-button">
                <button
                  type="button"
                  disabled={saving}
                  className="btn btn-kaskea-inverse bold uppercase"
                  onClick={() => exportSVG()}
                >
                  Export
                </button>
              </div>
              <div className="action-button">
                <button
                  type="submit"
                  disabled={saving}
                  form="editor-form"
                  className="btn btn-primary btn-kaskea bold uppercase"
                >
                  Tallenna
                </button>
              </div>
            </div>
          </div>
        </div>
        {props.templateStub && (
          <SaveTemplatePresetAsModal
            modal={saveTemplatePresetAsModal}
            data={formik.values as ItemData}
            templateId={props.templateStub.id}
            onMessage={(type, text) => props.onMessage(type, text)}
            onSave={(preset) => {
              if (preset) {
                getAllTemplatePresets(preset.id);
              }
            }}
            ref={saveTemplatePresetAsModalRef}
          />
        )}
        {props.templateStub && templatePreset && (
          <SaveTemplatePresetConfirmationDialog
            modal={saveTemplatePresetModal}
            data={formik.values as ItemData}
            preset={templatePreset}
            onMessage={(type, text) => props.onMessage(type, text)}
            onSave={() => {
              getAllTemplatePresets(selectedTemplatePresetId);
            }}
            ref={saveTemplatePresetModalRef}
          />
        )}
        {templatePresets && (
          <ConfirmTemplatePresetDeleteDialog
            modal={confirmTemplatePresetDeleteModal}
            presets={templatePresets}
            onDelete={() => {
              getAllTemplatePresets(undefined);
            }}
            onMessage={(type, text) => props.onMessage(type, text)}
            ref={confirmTemplatePresetDeleteModalRef}
          />
        )}
      </div>
      {exporting && (
        <div className="editor-export-overlay">
          Tulostetaan kylttiä, sulje tulostusikkuna toisessa ikkunassa
          jatkaaksesi editointia
        </div>
      )}
    </>
  );
}

export default Editor;
