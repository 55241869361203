import SplittedSign from "./SplittedSign";
import { LayoutItem, LayoutItemType } from "./types";
import { SHEET_HEIGHT_REDUCTION } from "./utils/splitCalculator";
import { SplittedSvgData, AXIS, SignSplitLine } from "./utils/svgUtils";
import React from "react";

type SplittedLayoutProps = {
  snap: boolean;
  visibleExtendAreas: boolean;
  sizeFactor: number;
  splittedObjects: SplittedSvgData[];
  layoutItem: LayoutItem | null;
  editable: boolean;
  productCode: string;
  splitAxis: AXIS;
  ctrlPressed: boolean;
  shiftPressed: boolean;
  onSplitRequest: (
    index: number,
    splitAxis: AXIS,
    offset: number,
    signSplitLine?: SignSplitLine,
  ) => void;
  onExtendArea: (
    index: number,
    expandExis: AXIS,
    start: number,
    end: number,
    symmetric: boolean,
  ) => void;
};

function SplittedLayout(props: SplittedLayoutProps): JSX.Element {
  const getSplittedObjectIndex = (layout_index: number) => {
    return props.splittedObjects.findIndex((so) => so.nodeId === layout_index);
  };

  if (!props.layoutItem) {
    return <div>Loading...</div>;
  }

  const buildTitle = (obj: SplittedSvgData) => {
    return `${obj.targetWidth}x${obj.targetHeight}`;
  };

  switch (props.layoutItem.type) {
    case LayoutItemType.NODE: {
      const obj_index = getSplittedObjectIndex(props.layoutItem.node_id);
      if (obj_index >= 0) {
        const obj = props.splittedObjects[obj_index];
        const width =
          (props.ctrlPressed || !props.editable ? obj.width : obj.targetWidth) *
          props.sizeFactor;
        const height =
          (props.ctrlPressed || !props.editable
            ? obj.height
            : obj.targetHeight - SHEET_HEIGHT_REDUCTION) * props.sizeFactor;
        return (
          <div>
            <div className="splitted-sign-title">{buildTitle(obj)}</div>
            <div style={{ width, height }}>
              <SplittedSign
                snap={props.snap}
                visibleExtendAreas={props.visibleExtendAreas}
                editable={props.editable}
                key={obj.key}
                obj={obj}
                productCode={props.productCode}
                splitAxis={props.splitAxis}
                ctrlPressed={props.ctrlPressed}
                shiftPressed={props.shiftPressed}
                sizeFactor={props.sizeFactor}
                onSplitRequest={(
                  axis: AXIS,
                  offset: number,
                  signSplitLine?: SignSplitLine,
                ) =>
                  props.onSplitRequest(obj_index, axis, offset, signSplitLine)
                }
                onExtendArea={(
                  expandExis: AXIS,
                  start: number,
                  end: number,
                  symmetric: boolean,
                ) =>
                  props.onExtendArea(
                    obj_index,
                    expandExis,
                    start,
                    end,
                    symmetric,
                  )
                }
              />
            </div>
          </div>
        );
      }
      break;
    }
    case LayoutItemType.VERTICAL:
    case LayoutItemType.HORIZONTAL: {
      return (
        <div
          className={
            props.layoutItem.type == LayoutItemType.HORIZONTAL
              ? "layout-column"
              : "layout-row"
          }
        >
          {props.layoutItem.children.map((li: LayoutItem, index: number) => (
            <SplittedLayout
              snap={props.snap}
              visibleExtendAreas={props.visibleExtendAreas}
              sizeFactor={props.sizeFactor}
              key={index}
              layoutItem={li}
              splittedObjects={props.splittedObjects}
              editable={props.editable}
              productCode={props.productCode}
              splitAxis={props.splitAxis}
              ctrlPressed={props.ctrlPressed}
              shiftPressed={props.shiftPressed}
              onSplitRequest={props.onSplitRequest}
              onExtendArea={props.onExtendArea}
            ></SplittedLayout>
          ))}
        </div>
      );
    }
  }
  return <div>Not supported layout: {props.layoutItem.type}</div>;
}

export default SplittedLayout;
