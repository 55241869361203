import axios, { AxiosError, AxiosResponse } from "axios";
import { CreateTemplateRequest } from "../types";

const baseUrl =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3000/api/"
    : process.env.REACT_APP_API_URL;
const config = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

const getAllTemplates = async (
  page: number,
  limit: number,
  sortField: string,
  sortDirection: number,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(
      baseUrl +
        `templates?page=${page}&limit=${limit}&sortField=${sortField}&sortDirection=${sortDirection}`,
      config,
    );
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const getTemplateById = async (id: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(baseUrl + `templates/${id}`, config);
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const createTemplate = async (
  data: CreateTemplateRequest,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(baseUrl + `templates`, data, config);
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const updateTemplate = async (
  id: string,
  data: CreateTemplateRequest,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.patch(
      baseUrl + `templates/${id}`,
      data,
      config,
    );
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const deleteTemplate = async (id: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.delete(baseUrl + `templates/${id}`, config);
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

export default {
  getAllTemplates,
  getTemplateById,
  createTemplate,
  updateTemplate,
  deleteTemplate,
};
