import React from "react";

interface ContainerProps {
  id?: string;
  size?: string;
  children: JSX.Element;
  className?: string;
}

function Container(props: ContainerProps): React.ReactElement {
  return (
    <div id={props.id} className={`container${props.size} ${props.className}`}>
      {props.children}
    </div>
  );
}

Container.defaultProps = {
  size: "",
};

export default Container;
