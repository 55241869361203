import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faTrash,
  faCopy,
  faEdit,
  faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";

import "./Orders.scss";
import ordersService from "./services/ordersService";
import Pagination from "./components/Pagination";
import { Order, OrderItem, SignSource, Template, TemplateStub } from "./types";
import Spinner from "./components/Spinner";
import OrderDialog from "./components/Dialog/OrderDialog";
import ImportOrderDialog from "./components/Dialog/ImportOrderDialog";
import templateService from "./services/templateService";

interface Orders {
  onMessage: (type: string, text: string) => void;
  order: Pick<Order, "id"> | null;
  onNavigate: (
    type: string,
    order: Order | null,
    orderItem: Partial<OrderItem> | null,
    template: TemplateStub,
  ) => void;
}

function Orders(props: Orders): JSX.Element {
  const [orders, setOrders] = useState<Order[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(true);

  const handleSelect = (order: Order | null) => {
    setSelectedOrder(order);
  };

  const handleOrderItemEdit = (order: Order, orderItemId: string) => {
    let template: Template;
    let templateId: string;
    setActive(false);

    ordersService.getOrderItemById(orderItemId).then((response) => {
      if (response.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const orderItem = response.data as OrderItem;
        templateId = response.data.templateId;

        templateService.getTemplateById(templateId).then((response) => {
          if (response.status === 200) {
            template = response.data;
            props.onNavigate("/editor", order, orderItem, template);
          } else {
            props.onMessage(
              "danger",
              "Virhe tarjouksen kylttipohjaa haettaessa",
            );
            setActive(true);
          }
        });
      } else {
        props.onMessage("danger", "Virhe tarjousta haettaessa");
        setActive(true);
      }
    });
  };

  const handleOrderItemCopy = (order: Order, orderItemId: string) => {
    let template: Template;
    let templateId: string;
    setActive(false);

    ordersService.getOrderItemById(orderItemId).then((response) => {
      if (response.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { id: _id, ...orderItem } = response.data; //remove id for orderitem clone;
        templateId = response.data.templateId;

        templateService.getTemplateById(templateId).then((response) => {
          if (response.status === 200) {
            template = response.data;
            props.onNavigate(
              "/editor",
              order,
              orderItem as Partial<OrderItem>,
              template,
            );
          } else {
            props.onMessage(
              "danger",
              "Virhe tarjouksen kylttipohjaa haettaessa",
            );
            setActive(true);
          }
        });
      } else {
        props.onMessage("danger", "Virhe kopioitavaa tarjousta haettaessa");
        setActive(true);
      }
    });
  };

  const handleOrderItemDelete = (order: Order, orderItemId: string) => {
    setActive(false);
    ordersService.deleteOrderItem(orderItemId).then((response) => {
      if (response.status === 200) {
        getAllOrders(page);
        props.onMessage("success", "Kyltti poistettu");
      } else {
        props.onMessage("danger", "Ongelmia kyltin poistossa");
      }
      setActive(true);
    });
  };

  const handleOrderAddNewSign = (
    order: Order | null,
    template: TemplateStub,
  ) => {
    props.onNavigate("/editor", order, null, template);
  };

  const handleOrderImport = (order: Order | null) => {
    console.log("Order imported", order);
    if (order) {
      props.onMessage("success", "Order imported");
      getAllOrders(page);
    }
  };

  const getAllOrders = (page: number) => {
    ordersService
      .getAllOrders(page, 25, "created_at", 1, "")
      .then((response) => {
        if (response && response.status === 200) {
          // console.log('data', response)
          setLimit(response.data.limit);
          setTotal(response.data.totalCount);
          for (const order of response.data.data) {
            order.orderItems.sort(
              (a: OrderItem, b: OrderItem) =>
                (a.remoteId ?? Number.MAX_SAFE_INTEGER) -
                (b.remoteId ?? Number.MIN_SAFE_INTEGER),
            );
          }
          const orders = response.data.data as Order[];
          setOrders(orders);
          if (props.order && !selectedOrder) {
            setSelectedOrder(
              orders.find((order) => props.order?.id === order.id) || null,
            );
          }
          setLoading(false);
        } else {
          props.onMessage("danger", "Tarjousten latauksessa tapahtui virhe");
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    getAllOrders(page);
  }, [page]);

  return (
    <>
      <ImportOrderDialog
        onImport={(order) => handleOrderImport(order)}
        onMessage={(type, text) => props.onMessage(type, text)}
      />
      <OrderDialog
        order={selectedOrder}
        onSetTemplate={(order, template) =>
          handleOrderAddNewSign(order, template)
        }
        onMessage={(type, text) => props.onMessage(type, text)}
      />
      <div className="headerArea">
        <h1>Tarjoukset</h1>
        <button
          type="button"
          className="btn btn-primary btn-sm btn-kaskea uppercase"
          data-bs-toggle="modal"
          data-bs-target="#importOrderModal"
        >
          Tuo numerolla
        </button>
        <button
          type="button"
          className="btn btn-primary btn-sm btn-kaskea uppercase"
          data-bs-toggle="modal"
          data-bs-target="#orderModal"
          onClick={(e) => {
            e.preventDefault();
            setSelectedOrder(null);
          }}
        >
          Uusi tarjous
        </button>
      </div>

      {loading ? (
        <div className="spinner-center">
          <Spinner />
        </div>
      ) : orders.length === 0 ? (
        <div className="spinner-center">No data</div>
      ) : (
        <>
          <table className="table">
            <thead className="table">
              <tr>
                <th scope="col"></th>
                <th scope="col">Id</th>
                <th scope="col">Viite</th>
                <th scope="col">Kuvaus</th>
                <th scope="col">Myyjä</th>
                <th scope="col">Päivämäärä</th>
                <th scope="col">Opasteet</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <React.Fragment key={order.id}>
                  <tr>
                    <td>
                      {selectedOrder?.id !== order.id ? (
                        <FontAwesomeIcon
                          icon={faPlus}
                          onClick={() => handleSelect(order)}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faMinus}
                          onClick={() => handleSelect(null)}
                        />
                      )}
                    </td>
                    <td>{order.remoteId}</td>
                    <td>{order.reference}</td>
                    <td>{order.description}</td>
                    <td>
                      {order.createdBy.firstname} {order.createdBy.lastname}
                    </td>
                    <td>{new Date(order.createdAt).toLocaleString("fi")}</td>
                    <td>{order.orderItems.length}</td>
                    <td>
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#orderModal"
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedOrder(order);
                        }}
                      >
                        Lisää tarjoukseen
                      </a>
                    </td>
                  </tr>
                  {selectedOrder?.id === order.id && (
                    <tr>
                      <td colSpan={8}>
                        <table className="table mb-0 table-nested">
                          <thead>
                            <tr>
                              <th className="th-white" scope="col"></th>
                              <th scope="col">Lisänimike</th>
                              <th scope="col">Työnumero</th>
                              <th scope="col">Koodi</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {order.orderItems.map((item, i) => (
                              <tr key={i}>
                                <td className="td-white"></td>
                                <td className="td-name">
                                  {item.source == SignSource.WEB && (
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (active) {
                                          handleOrderItemEdit(order, item.id);
                                        }
                                      }}
                                    >
                                      {item.identifier}
                                    </a>
                                  )}
                                  {item.source == SignSource.NOVAPOINT && (
                                    <span>{item.identifier}</span>
                                  )}
                                </td>
                                <td className="td-name">{item.remoteId}</td>
                                <td className="td-name">{item.productCode}</td>
                                <td className="td-icons">
                                  {item.source == SignSource.WEB && (
                                    <FontAwesomeIcon
                                      className="icon"
                                      icon={faEdit}
                                      color={active ? "#000" : "#ddd"}
                                      onClick={() => {
                                        if (active) {
                                          handleOrderItemEdit(order, item.id);
                                        }
                                      }}
                                    />
                                  )}
                                  {item.source == SignSource.WEB && (
                                    <FontAwesomeIcon
                                      className="icon"
                                      icon={faCopy}
                                      color={active ? "#000" : "#ddd"}
                                      onClick={() => {
                                        if (active) {
                                          handleOrderItemCopy(order, item.id);
                                        }
                                      }}
                                    />
                                  )}
                                  {item.source == SignSource.WEB && (
                                    <FontAwesomeIcon
                                      className="icon"
                                      icon={faTrash}
                                      color={active ? "#000" : "#ddd"}
                                      onClick={() => {
                                        if (active) {
                                          handleOrderItemDelete(order, item.id);
                                        }
                                      }}
                                    />
                                  )}
                                  {item.source == SignSource.WEB && (
                                    <a
                                      href={`/eps/${item.id}.eps`}
                                      download={`${[
                                        order.remoteId,
                                        item.remoteId,
                                        item.identifier,
                                        item.id,
                                      ]
                                        .filter((i) => !!i)
                                        .join("_")}.eps`}
                                    >
                                      <FontAwesomeIcon
                                        className="icon"
                                        icon={faCloudDownloadAlt}
                                        color={active ? "#000" : "#ddd"}
                                      />
                                    </a>
                                  )}
                                  {item.source == SignSource.WEB &&
                                    item.twoSided && (
                                      <a
                                        href={`/eps/${item.id}_back.eps`}
                                        download={`${[
                                          order.remoteId,
                                          item.remoteId,
                                          item.identifier,
                                          item.id,
                                          "back",
                                        ]
                                          .filter((i) => !!i)
                                          .join("_")}.eps`}
                                      >
                                        <FontAwesomeIcon
                                          className="icon"
                                          icon={faCloudDownloadAlt}
                                          color={active ? "#000" : "#ddd"}
                                        />
                                      </a>
                                    )}

                                  {item.source == SignSource.NOVAPOINT &&
                                    item.sheetAmount > 0 &&
                                    Array.from(
                                      Array(item.sheetAmount).keys(),
                                    ).map((index) => (
                                      <a
                                        key={index}
                                        href={`/eps/${item.id}_${index}.eps`}
                                        download={`${[
                                          order.remoteId,
                                          item.remoteId,
                                          item.identifier,
                                          item.id,
                                          index,
                                        ]
                                          .filter((i) => i !== undefined)
                                          .join("_")}.eps`}
                                      >
                                        <FontAwesomeIcon
                                          className="icon"
                                          icon={faCloudDownloadAlt}
                                          color={active ? "#000" : "#ddd"}
                                        />
                                        <span>{index}</span>
                                      </a>
                                    ))}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <Pagination
            page={page}
            total={total}
            limit={limit}
            onPageChange={(pageNum) => setPage(pageNum)}
          />
        </>
      )}
    </>
  );
}

export default Orders;
