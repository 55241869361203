import svgpath from "svgpath";
import { AXIS, PathSegment, SplittedSvgData, SVGObjects } from "./svgUtils";

type SplitSidePathSegment = { segment: PathSegment; x: number; y: number };
type SplitSidePath = SplitSidePathSegment[];
type SplitSidePaths = SplitSidePath[];
type SplitSideData = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paths: SplitSidePaths;
  current_path: { segment: PathSegment; x: number; y: number }[];
};

export enum Side {
  A,
  B,
}

type SvgState = {
  prevX: number | null;
  prevY: number | null;
  prevSide: Side | null;
  startX: Side | null;
  startY: Side | null;
  startSide: Side | null;
};

export type BoundingBox = {
  left: number;
  right: number;
  bottom: number;
  top: number;
};

export type CutLine = {
  axis: AXIS;
  offset: number;
  signSplit: boolean;
};

export enum SplitType {
  LINE_SPLIT,
  OBJECT_SPLIT,
  WEIGHT_ONLY,
}

export const PATH_THRESHOLD = 0.1; // 10th of the millimeter
const CUT_WEIGHT_STRAIGHT_LINE = 1;
const CUT_WEIGHT_DIAGONAL_LINE = 100;
const MIN_INNER_CUT_PATH_LENGTH = 100; // in millimeters
const CUTLINE_OFFSET = 10; // in millimeters
export const SHEET_HEIGHT_REDUCTION = 7;

const updateBoundingBox = (
  boundingBox: BoundingBox | undefined,
  x: number,
  y: number,
): BoundingBox => {
  return {
    left: Math.min(boundingBox?.left ?? x, x),
    right: Math.max(boundingBox?.right ?? x, x),
    top: Math.min(boundingBox?.top ?? y, y),
    bottom: Math.max(boundingBox?.bottom ?? y, y),
  };
};

export const calcPathBoundingBoxAndCutLines = (
  path: string,
  svgWidth: number,
  svgHeight: number,
  splittedVertically: boolean,
  splittedHorizontally: boolean,
  addCutlines?: (...cutlines: CutLine[]) => void,
): BoundingBox | undefined => {
  let boundingBox: BoundingBox | undefined = undefined;
  const segments: PathSegment[] = [];

  let prevX = 0;
  let prevY = 0;
  let startX = 0;
  let startY = 0;

  svgpath(path).iterate((segment: PathSegment) => {
    // copy segments
    segments.push([...segment]);
  });

  for (const segment of segments) {
    const type = segment[0];
    switch (type) {
      case "M":
      case "Z":
      case "L": {
        const x = type === "Z" ? startX : parseFloat(segment[1] as string);
        const y = type === "Z" ? startY : parseFloat(segment[2] as string);

        if (type === "M") {
          startX = x;
          startY = y;
        }

        boundingBox = updateBoundingBox(boundingBox, x, y);

        if (type === "L") {
          if (
            addCutlines &&
            Math.abs(x - prevX) < PATH_THRESHOLD &&
            Math.abs(y - prevY) > MIN_INNER_CUT_PATH_LENGTH
          ) {
            const top = Math.min(y, prevY);
            const bottom = Math.max(y, prevY);
            addCutlines(
              {
                axis: AXIS.X,
                offset: top + CUTLINE_OFFSET,
                signSplit: false,
              },
              {
                axis: AXIS.X,
                offset: bottom - CUTLINE_OFFSET,
                signSplit: false,
              },
            );
          }

          if (
            addCutlines &&
            Math.abs(y - prevY) < PATH_THRESHOLD &&
            Math.abs(x - prevX) > MIN_INNER_CUT_PATH_LENGTH
          ) {
            const left = Math.min(x, prevX);
            const right = Math.max(x, prevX);
            addCutlines(
              {
                axis: AXIS.Y,
                offset: left + CUTLINE_OFFSET,
                signSplit: false,
              },
              {
                axis: AXIS.Y,
                offset: right - CUTLINE_OFFSET,
                signSplit: false,
              },
            );
          }
        }

        prevX = x;
        prevY = y;
        break;
      }
      case "H": {
        const x = parseFloat(segment[1] as string);
        const y = prevY;

        boundingBox = updateBoundingBox(boundingBox, x, y);
        prevX = x;
        break;
      }
      case "V": {
        const x = prevX;
        const y = parseFloat(segment[1] as string);

        boundingBox = updateBoundingBox(boundingBox, x, y);
        prevY = y;
        break;
      }
      case "A": {
        const {
          // 1: _rx,
          // 2: _ry,
          // 3: _xrot,
          // 4: _large_flag,
          // 5: _sweep_flag,
          6: x,
          7: y,
        } = segment;
        boundingBox = updateBoundingBox(
          boundingBox,
          parseFloat(x as string),
          parseFloat(y as string),
        );
        prevX = parseFloat(x as string);
        prevY = parseFloat(y as string);
        break;
      }
    }
  }

  if (boundingBox && addCutlines) {
    const signSplitX =
      !splittedVertically &&
      Math.abs(boundingBox.left) < PATH_THRESHOLD &&
      Math.abs(boundingBox.right - svgWidth) < PATH_THRESHOLD;

    if (boundingBox.top - CUTLINE_OFFSET > PATH_THRESHOLD) {
      addCutlines({
        axis: AXIS.X,
        offset: signSplitX ? boundingBox.top : boundingBox.top - CUTLINE_OFFSET,
        signSplit: signSplitX,
      });
    }

    const signSplitY =
      !splittedHorizontally &&
      Math.abs(boundingBox.top) < PATH_THRESHOLD &&
      Math.abs(boundingBox.bottom - svgHeight) < PATH_THRESHOLD;
    if (boundingBox.bottom + CUTLINE_OFFSET < svgHeight + PATH_THRESHOLD) {
      addCutlines({
        axis: AXIS.X,
        offset: signSplitX
          ? boundingBox.bottom
          : boundingBox.bottom + CUTLINE_OFFSET,
        signSplit: signSplitX,
      });
    }

    if (boundingBox.left - CUTLINE_OFFSET > PATH_THRESHOLD) {
      addCutlines({
        axis: AXIS.Y,
        offset: signSplitY
          ? boundingBox.left
          : boundingBox.left - CUTLINE_OFFSET,
        signSplit: signSplitY,
      });
    }

    if (boundingBox.right + CUTLINE_OFFSET < svgWidth + PATH_THRESHOLD) {
      addCutlines({
        axis: AXIS.Y,
        offset: signSplitY
          ? boundingBox.right
          : boundingBox.right + CUTLINE_OFFSET,
        signSplit: signSplitY,
      });
    }
  }

  return boundingBox;
};

export const findCutLineProposals = (
  svgObj: SplittedSvgData,
): { boundingBoxes: BoundingBox[]; cutLines: CutLine[] } => {
  const boundingBoxes: BoundingBox[] = [];
  const cutLines: CutLine[] = [];

  const addCutlines = (...newCutLines: CutLine[]) => {
    for (const cutline of newCutLines) {
      if (
        !cutLines.some(
          (cl) =>
            cl.axis == cutline.axis &&
            Math.abs(cl.offset - cutline.offset) < PATH_THRESHOLD,
        )
      ) {
        cutLines.push(cutline);
      }
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  for (const obj of svgObj.svgObjects as any[]) {
    if (obj.name === "g") {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      for (const c of obj.children) {
        if (c.name === "path" && c.properties["d"]) {
          const boundingBox = calcPathBoundingBoxAndCutLines(
            c.properties["d"],
            svgObj.width,
            svgObj.height,
            svgObj.splittedVertically,
            svgObj.splittedHorizontally,
            addCutlines,
          );
          if (boundingBox) {
            boundingBoxes.push(boundingBox);
          }
        }
      }
    }
  }

  return { boundingBoxes, cutLines };
};

export const calcSplitPath = (
  sideAPaths: Array<SVGObjects>,
  sideBPaths: Array<SVGObjects>,
  axis: AXIS,
  offset: number,
  attributes: Record<string, string>,
  splitType: SplitType,
  forceSide: Side | null,
): {
  success: boolean;
  weight: number;
  signature: number;
  has_diagonal: boolean;
} => {
  let weight = 0;
  let signature = 0;
  let has_diagonal = false;

  const updateWeight = (_w: number, _x: number, _y: number) => {
    weight += _w;
    signature += _x + _y;
  };

  const aSide: SplitSideData = {
    paths: [],
    current_path: [],
  };

  const bSide: SplitSideData = {
    paths: [],
    current_path: [],
  };

  const state: SvgState = {
    prevX: null,
    prevY: null,
    prevSide: null,
    startX: null,
    startY: null,
    startSide: null,
  };

  const setPrevState = (dx: number, dy: number, dSide: Side): void => {
    state.prevX = dx;
    state.prevY = dy;
    state.prevSide = dSide;
    if (state.startSide == null) {
      state.startX = dx;
      state.startY = dy;
      state.startSide = dSide;
    }
  };

  const detectSideChange = (side: Side) => {
    return state.prevSide != null && state.prevSide != side;
  };

  const calcCrossCutPoint = (
    cross_point: number,
    prev_cross_point: number,
    point: number,
    prev_point: number,
  ): { value: number; diagonal: boolean } => {
    // Support both line directions
    // TODO: replace with library math library.
    const len = Math.abs(point - prev_point);
    const offsetDiff = offset - Math.min(point, prev_point);
    const f = offsetDiff > 0 && len > 0 ? Math.max(0, offsetDiff / len) : 0;
    const c_min = Math.min(cross_point, prev_cross_point);
    const c_len = Math.abs(cross_point - prev_cross_point);
    const cOffsetDiff = c_len * f;
    return {
      value:
        c_min +
        ((point < offset && prev_cross_point > cross_point) ||
        (point > offset && prev_cross_point < cross_point)
          ? cOffsetDiff
          : c_len - cOffsetDiff),
      diagonal: Math.abs(cross_point - prev_cross_point) > PATH_THRESHOLD,
    };
  };

  const checkPoint = (
    x: number,
    y: number,
  ): {
    side: Side;
    pos: { x: number; y: number };
    can_cut: boolean; // Allow cutting only straight lines.
    cuts: {
      [Side.A]: { x: number; y: number };
      [Side.B]: { x: number; y: number };
    };
    diagonal: boolean;
  } => {
    switch (axis) {
      case AXIS.Y: {
        const side: Side = forceSide ?? (x <= offset ? Side.A : Side.B);
        const sideChanged = detectSideChange(side);
        const { value: cutY, diagonal } = sideChanged
          ? calcCrossCutPoint(y, state.prevY ?? 0, x, state.prevX ?? 0)
          : { value: y, diagonal: false };
        return {
          side,
          pos: {
            x: side === Side.B ? x - offset : x,
            y,
          },
          can_cut: sideChanged,
          cuts: {
            [Side.A]: { x: offset, y: cutY },
            [Side.B]: { x: 0, y: cutY },
          },
          diagonal,
        };
      }
      case AXIS.X: {
        const side: Side = forceSide ?? y <= offset ? Side.A : Side.B;
        const sideChanged = detectSideChange(side);
        const { value: cutX, diagonal } = sideChanged
          ? calcCrossCutPoint(x, state.prevX ?? 0, y, state.prevY ?? 0)
          : { value: x, diagonal: false };

        return {
          side,
          pos: {
            x,
            y: side === Side.B ? y - offset : y,
          },
          can_cut: sideChanged,
          cuts: {
            [Side.A]: {
              x: cutX,
              y: offset,
            },
            [Side.B]: { x: cutX, y: 0 },
          },
          diagonal,
        };
      }
    }
  };

  const onSideChange = (sideData: SplitSideData) => {
    if (sideData.current_path.length) {
      sideData.paths.push([...sideData.current_path]);
      sideData.current_path = [];
    }
  };

  const pushSegment = (
    segment: PathSegment,
    x: number,
    y: number,
    side: Side,
  ) => {
    if (splitType === SplitType.WEIGHT_ONLY) return;
    if (detectSideChange(side)) {
      onSideChange(aSide);
      onSideChange(bSide);
    }
    switch (side) {
      case Side.A:
        aSide.current_path.push({ segment, x, y });
        break;
      case Side.B:
        bSide.current_path.push({ segment, x, y });
        break;
    }
  };

  const segments: PathSegment[] = [];
  svgpath(attributes.d).iterate((segment: PathSegment) => {
    // copy segments
    segments.push([...segment]);
  });

  for (const segment of segments) {
    const type = segment[0];
    switch (type) {
      case "M": {
        const { 1: x, 2: y } = segment;
        const { side, pos } = checkPoint(x as number, y as number);

        // Apply offsets and change letter to L.
        // This will be handled on post process.

        pushSegment(["L", pos.x, pos.y], pos.x, pos.y, side);

        setPrevState(x as number, y as number, side);
        break;
      }
      case "Z":
      case "L": {
        const { 1: x, 2: y } =
          type == "L" ? segment : [type, state.startX ?? 0, state.startY ?? 0];
        const { side, pos, can_cut, cuts, diagonal } = checkPoint(
          x as number,
          y as number,
        );

        if (can_cut && state.prevSide != null) {
          //push cut point to the prev_side;
          const pcut = cuts[state.prevSide];
          pushSegment(["L", pcut.x, pcut.y], pcut.x, pcut.y, state.prevSide);

          //push cut point to new side;
          const ncut = cuts[side];
          pushSegment(["L", ncut.x, ncut.y], ncut.x, ncut.y, side);
          setPrevState(ncut.x, ncut.y, side);

          if (diagonal) {
            has_diagonal = true;
          }

          // TODO: calculate weight from cut line lenght. really short lines should have bigger weight
          updateWeight(
            diagonal ? CUT_WEIGHT_DIAGONAL_LINE : CUT_WEIGHT_STRAIGHT_LINE,
            x as number,
            y as number,
          );
        }

        // Apply offsets.
        pushSegment(["L", pos.x, pos.y], pos.x, pos.y, side);

        setPrevState(x as number, y as number, side);
        break;
      }
      case "H": {
        const x = segment[1];
        const y = state.prevY ?? 0;
        const { side, pos, can_cut, cuts } = checkPoint(
          x as number,
          y as number,
        );

        if (can_cut && state.prevSide) {
          //push cut point to the prev_side;
          const pcut = cuts[state.prevSide];
          pushSegment(["H", pcut.x], pcut.x, pcut.y, state.prevSide);

          //push cut point to new side;
          const ncut = cuts[side];
          pushSegment(["L", ncut.x, ncut.y], ncut.x, ncut.y, side);
          setPrevState(ncut.x, ncut.y, side);

          updateWeight(CUT_WEIGHT_STRAIGHT_LINE, x as number, y as number);
        }

        // Apply offsets.
        pushSegment(["H", pos.x], pos.x, pos.y, side);

        setPrevState(x as number, y as number, side);
        break;
      }
      case "V": {
        const x = state.prevX ?? 0;
        const y = segment[1];
        const { side, pos, can_cut, cuts } = checkPoint(
          x as number,
          y as number,
        );

        if (can_cut && state.prevSide) {
          //push cut point to the prev_side;
          const pcut = cuts[state.prevSide];
          pushSegment(["V", pcut.y], pcut.x, pcut.y, state.prevSide);

          //push cut point to new side;
          const ncut = cuts[side];
          pushSegment(["L", ncut.x, ncut.y], ncut.x, ncut.y, side);
          setPrevState(ncut.x, ncut.y, side);

          updateWeight(CUT_WEIGHT_STRAIGHT_LINE, x as number, y as number);
        }

        // Apply offsets.
        pushSegment(["V", pos.y], pos.x, pos.y, side);

        setPrevState(x as number, y as number, side);
        break;
      }
      case "A": {
        const {
          1: rx,
          2: ry,
          3: xrot,
          4: large_flag,
          5: sweep_flag,
          6: x,
          7: y,
        } = segment;
        const { side, pos } = checkPoint(x as number, y as number);

        if (state.prevSide != side) {
          // It's not allowed to cut through arc
          if (splitType === SplitType.LINE_SPLIT) {
            console.error("Can't cut through the arc");
          }
          updateWeight(1000, x as number, y as number);
          return {
            success: false,
            weight,
            signature,
            has_diagonal,
          };
        } /* else {
            const point =
              axis === SplitAxis.X ? state.prevY ?? 0 : state.prevX ?? 0;
            const pPoint = axis === SplitAxis.X ? y : x;
            const mPoint = (pPoint + point) / 2;
            const radius = axis === SplitAxis.X ? ry : rx;

            const aMax = mPoint + radius;
            const aMin = mPoint - radius;

            if (offset > aMin && offset < aMax) return false;
          }*/

        // Apply offsets.
        pushSegment(
          ["A", rx, ry, xrot, large_flag, sweep_flag, pos.x, pos.y],
          pos.x,
          pos.y,
          side,
        );

        setPrevState(x as number, y as number, side);
        break;
      }
    }
  }

  if (splitType === SplitType.WEIGHT_ONLY) {
    return { success: true, weight, signature, has_diagonal };
  }

  // Joins start and end of the path
  switch (state.prevSide) {
    case Side.A:
      if (aSide.paths.length) {
        //Combine segments
        aSide.paths[0] = [...aSide.current_path, ...aSide.paths[0]];
        aSide.current_path = [];
      } else if (aSide.current_path.length) {
        aSide.paths.push([...aSide.current_path]);
      }
      break;
    case Side.B:
      if (bSide.paths.length) {
        //Combine segments
        bSide.paths[0] = [...bSide.current_path, ...bSide.paths[0]];
        bSide.current_path = [];
      } else if (bSide.current_path.length) {
        bSide.paths.push([...bSide.current_path]);
      }

      break;
  }

  const onCutline = (side: Side, offset: number): boolean => {
    return Math.abs(offset - (side == Side.A ? offset : 0)) < PATH_THRESHOLD;
  };

  const findMiddlePath = (
    side: Side,
    paths: SplitSidePaths,
    p1: number,
    p2: number,
  ): number => {
    const max = Math.max(p1, p2);
    const min = Math.min(p1, p2);
    for (let ri = paths.length - 1; ri >= 0; ri--) {
      const path = paths[ri];
      if (!path.length) {
        continue;
      }
      //const cut_offset = axis == AXIS.X ? path[0].y : path[0].x;
      //if (onCutline(side, cut_offset)) {
      const start_offset = axis == AXIS.X ? path[0].x : path[0].y;
      if (start_offset < max && start_offset > min) {
        return ri;
      }
      //}
    }
    return -1;
  };

  // Loops though paths and builds final paths.
  const combinePaths = (side: Side, paths: SplitSidePaths) => {
    if (paths.length < 2) {
      return paths;
    }

    const handled_paths: number[] = [];

    paths.sort((a, b) => {
      if (axis == AXIS.Y) {
        return a[0].y - b[0].y;
      } else {
        return b[0].x - a[0].x;
      }
    });

    // Loops through paths and check if paths are on cutline, and if path continues between start and end points, join them
    for (const [pi] of paths.entries()) {
      if (!handled_paths.includes(pi)) {
        handled_paths.push(pi);
        let path = paths[pi];
        const cut_offset = axis == AXIS.X ? path[0].y : path[0].x;
        if (onCutline(side, cut_offset)) {
          const p1 = axis == AXIS.X ? path[0].x : path[0].y;
          let p2 =
            axis == AXIS.X ? path[path.length - 1].x : path[path.length - 1].y;
          let mp_index = findMiddlePath(side, paths, p1, p2);
          while (mp_index >= 0) {
            handled_paths.push(mp_index);
            path = [...path, ...paths[mp_index]];
            paths[mp_index] = [];
            p2 =
              axis == AXIS.X
                ? path[path.length - 1].x
                : path[path.length - 1].y;
            mp_index = findMiddlePath(side, paths, p1, p2);
          }
          paths[pi] = path; //update path
        }
      }
    }

    return paths.filter((path) => path.length > 0); //clear empty paths
  };

  aSide.paths = combinePaths(Side.A, aSide.paths);
  bSide.paths = combinePaths(Side.B, bSide.paths);

  sideAPaths.push(
    ...aSide.paths.map((path) => ({
      name: "path",
      properties: {
        ...attributes,
        d: "M".concat(
          path
            .map((p) => p.segment)
            .flat()
            .join(" ")
            .concat(" Z")
            .substring(1),
        ),
      },
    })),
  );
  sideBPaths.push(
    ...bSide.paths.map((path) => ({
      name: "path",
      properties: {
        ...attributes,
        d: "M".concat(
          path
            .map((p) => p.segment)
            .flat()
            .join(" ")
            .concat(" Z")
            .substring(1),
        ),
      },
    })),
  );

  return { success: true, weight, signature, has_diagonal };
};
