import { PlateType, ExtraType, MountingMethod } from "./types";

const FieldNames = {
  plateType: {
    [PlateType.ALUMINUM_2]: "Alumiini 2mm",
    [PlateType.ALUMINUM_27]: "Alumiini 2.7mm",
    [PlateType.ALUMINUM_3]: "Alumiini 3mm",
    [PlateType.COMPOSITE_3]: "Komposiitti 3mm",
  },
  extraType: {
    [ExtraType.ONE_SIDE]: "Yksipuolinen yhdelle putkelle",
    [ExtraType.TWO_SIDE]: "Kaksipuolinen yhdelle putkelle",
    [ExtraType.TWO_PIPES]: "Kahdelle putkelle",
  },
  mountingMethod: {
    [MountingMethod.METHOD_1A]: "55 rei'itys yksi kiinnike kalvoa läpäisemätön",
    [MountingMethod.METHOD_1B]: "55 rei'itys yksi kiinnike kalvoa läpäisemätön",
    [MountingMethod.METHOD_2A]:
      "55 rei'itys kaksi kiinnikettä kalvoa läpäisemätön",
    [MountingMethod.METHOD_2B]:
      "55 rei'itys kaksi kiinnikettä kalvoa läpäisemätön",
    [MountingMethod.METHOD_3A]:
      "55 rei'itys yksi kiinnike kalvon läpäiseväaaaaa",
    [MountingMethod.METHOD_3B]:
      "55 rei'itys yksi kiinnike kalvon läpäiseväaaaaa",
    [MountingMethod.METHOD_4A]:
      "55 rei'itys kaksi kiinnikettä kalvon läpäisevä",
    [MountingMethod.METHOD_4B]:
      "55 rei'itys kaksi kiinnikettä kalvon läpäisevä",
    [MountingMethod.METHOD_5A]: "Perforoitu - Suora, ei kulmintaa tai jatkoa",
    [MountingMethod.METHOD_5B]: "Perforoitu - Kulmittu jatkettava",
    [MountingMethod.METHOD_5C]:
      "Perforoitu - Keskeltä kulmittu molemmista päistä",
    [MountingMethod.METHOD_5D]: "Erkanemisviitta 65 astetta kulma",
    [MountingMethod.METHOD_5E]: "Erkanemisviitta jatkettava 65 astetta",
    [MountingMethod.METHOD_5F]:
      "Perforoitu - Rei'itys keskitetysti suora ei kulmintaa",
    [MountingMethod.METHOD_6A]: "644 - Toisesta päästä",
    [MountingMethod.METHOD_6B]: "644 - Molemmista päistä",
    [MountingMethod.METHOD_6C]:
      "644 – Vanhanajan viitta pyöreä kärki korkeus 180",
    [MountingMethod.METHOD_6D]:
      "644 – Vanhanajan viitta pyöreä kärki korkeus 200",
    [MountingMethod.METHOD_7A]: "Vannekiinnitin",
    [MountingMethod.METHOD_8A]: "Huippukiinnitin",
    [MountingMethod.METHOD_9A]:
      "Seinäkiinnitys - Reijitys kulmiin, reikä 4mm, ei nurkkapyöristystä",
    [MountingMethod.METHOD_9B]:
      "Seinäkiinnitys - Reijitys kulmiin + sivuille 400 jaolla, reikä 4mm",
    [MountingMethod.METHOD_9C]:
      "Ilman reunataivutusta - Reijitys tolppaan yksi 4mm reikä ylös ja alas",
    [MountingMethod.METHOD_9D]:
      "Ilman reunataivutusta - Olli kiinnitys yhdelle kiinnikkeelle ei nurkkapyöristystä",
    [MountingMethod.METHOD_9E]:
      "Ilman reunataivutusta - Olli kiinnitys kahdelle kiinnikkeelle ei nurkkapyöristystä",
    [MountingMethod.METHOD_9F]:
      "Ilman reunataivutusta- Olli kiinnitys yhdelle kiinnikkeelle R40",
    [MountingMethod.METHOD_9G]:
      "Ilman reunataivutusta- Olli kiinnitys kahdelle kiinnikkeelle R40",
    [MountingMethod.METHOD_9H]:
      "Ilman reunataivutusta - Olli kiinnitys yhdelle kiinnikkeelle R30",
    [MountingMethod.METHOD_9I]:
      "Ilman reunataivutusta - Olli kiinnitys kahdelle kiinnikkeelle R30",
    [MountingMethod.METHOD_9J]:
      "Ilman reunataivutusta - Olli kiinnitys yhdelle kiinnikkeelle R20",
    [MountingMethod.METHOD_9K]:
      "Ilman reunataivutusta - Olli kiinnitys kahdelle kiinnikkeelle R20",
    [MountingMethod.METHOD_9L]:
      "Ilman reunataivutusta - Reijitys kulmiin, reikä 4mm, R40 nurkkapyöristys",
    [MountingMethod.METHOD_9M]:
      "Ilman reunataivutusta - Reijitys kulmiin, reikä 4mm, R30 nurkkapyöristys",
    [MountingMethod.METHOD_AA]: "Listakiinnitys r40",
    [MountingMethod.METHOD_AB]: "Listakiinnitys r30",
    [MountingMethod.METHOD_AC]: "Listakiinnitys suorakulma",
  },
};

export { FieldNames };
