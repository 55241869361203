import { Modal } from "bootstrap";
import React, { useEffect, useState, useRef, useCallback } from "react";
import TemplateDialog from "./components/Dialog/TemplateDialog";
import ConfirmationDialog from "./components/Dialog/ConfirmationDialog";
import Pagination from "./components/Pagination";
import Spinner from "./components/Spinner";
import templateService from "./services/templateService";
import Sign from "./Sign";

import { AuthContext } from "./App";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

import "./Templates.scss";
import { Order, OrderItem, Template, TextSide } from "./types";

interface TemplateProps {
  onMessage: (type: string, text: string) => void;
  onNavigate: (
    type: string,
    order: Order | null,
    orderItem: OrderItem | null,
    template: Template | null,
  ) => void;
}

function Templates(props: TemplateProps): JSX.Element {
  const [templateModal, setTemplateModal] = useState<Modal | null>(null);
  const [confirmationModal, setConfirmationModal] =
    useState<Modal | null>(null);
  const [selectedTemplate, setSelectedTemplate] =
    useState<Template | null>(null);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(8);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loading, setLoading] = useState(false);

  const auth = React.useContext(AuthContext);

  const templateModalRef = useRef<HTMLDivElement>(null);
  const confirmationModalRef = useRef<HTMLDivElement>(null);

  const handleTemplateEdit = (template: Template) => {
    setSelectedTemplate(template);
    templateModal?.show();
  };

  const handleConfirmation = (template: Template) => {
    setSelectedTemplate(template);
    confirmationModal?.show();
  };

  const handleTemplateRemoval = () => {
    if (selectedTemplate) {
      templateService.deleteTemplate(selectedTemplate.id).then((result) => {
        if (result.status === 200) {
          const newTemplates = templates.filter(
            (t) => t.id !== selectedTemplate.id,
          );

          setTemplates(newTemplates);
          confirmationModal?.hide();
          props.onMessage("success", "Kylttipohja poistettu onnistuneesti");
          setSelectedTemplate(null);
        } else {
          confirmationModal?.hide();
          props.onMessage("danger", "Kylttipohjan poistossa tapahtui virhe");
          setSelectedTemplate(null);
        }
      });
    }
  };

  useEffect(() => {
    if (templateModalRef.current) {
      setTemplateModal(
        new Modal(templateModalRef.current, { backdrop: "static" }),
      );
    }
  }, []);

  useEffect(() => {
    if (confirmationModalRef.current) {
      setConfirmationModal(
        new Modal(confirmationModalRef.current, { backdrop: "static" }),
      );
    }
  }, []);

  const getAllTemplates = useCallback(() => {
    templateService.getAllTemplates(page, limit, "name", 0).then((response) => {
      if (response && response.status === 200) {
        setLimit(response.data.limit);
        setTotal(response.data.totalCount);
        setTemplates(response.data.data);
        setLoading(false);
      } else {
        props.onMessage("danger", "Kylttipohjien latauksessa tapahtui virhe");
      }
    });
  }, [page]);

  useEffect(() => {
    setLoading(true);

    getAllTemplates();
  }, [page]);

  return (
    <>
      <TemplateDialog
        modal={templateModal}
        template={selectedTemplate}
        onCancel={() => setSelectedTemplate(null)}
        onUpdate={() => {
          setSelectedTemplate(null);
          getAllTemplates();
        }}
        onAddTemplate={() => getAllTemplates()}
        onMessage={(type, text) => props.onMessage(type, text)}
        ref={templateModalRef}
      />
      <ConfirmationDialog
        modal={confirmationModal}
        template={selectedTemplate}
        onConfirmation={() => handleTemplateRemoval()}
        ref={confirmationModalRef}
      />
      <div className="headerArea">
        <h1>Kylttipohjat</h1>
        {auth.state.user?.role === "admin" && (
          <button
            type="button"
            className="btn btn-primary btn-sm btn-kaskea uppercase"
            onClick={() => templateModal?.show()}
          >
            Tuo pohja
          </button>
        )}
      </div>

      {loading ? (
        <div className="spinner-center">
          <Spinner />
        </div>
      ) : (
        <>
          {templates.length === 0 ? (
            <div className="spinner-center">No data</div>
          ) : (
            <>
              <div className="row g-4 templates">
                {templates.map((template) => {
                  return (
                    <div key={template.id} className="col-3">
                      <div className="p-3 border sign-image">
                        {auth.state.user?.role === "admin" && (
                          <div className="template-actions">
                            <FontAwesomeIcon
                              className="template-actions-icon"
                              icon={faEdit}
                              onClick={() => handleTemplateEdit(template)}
                            />
                            <FontAwesomeIcon
                              className="template-actions-icon"
                              icon={faTrash}
                              onClick={() => handleConfirmation(template)}
                            />
                          </div>
                        )}

                        <div
                          className="template-preview"
                          onClick={() =>
                            props.onNavigate("/editor", null, null, template)
                          }
                        >
                          <Sign
                            key={`t-${template.id}`}
                            template={template}
                            isBackSide={false}
                            className="sign-preview"
                            data={{
                              identifier: "",
                              colorPreset:
                                template.properties.colorPresets[0].name,
                              texts: template.properties.texts
                                .filter((tt) => tt.side !== TextSide.BACK)
                                .map((tt) => ({
                                  id: tt.id,
                                  text: tt.placeHolder,
                                  fontFamily: tt.fonts[0],
                                  fontSize:
                                    template.properties.defaultFontSize &&
                                    tt.fontSizes.includes(
                                      template.properties.defaultFontSize,
                                    )
                                      ? template.properties.defaultFontSize
                                      : tt.fontSizes[0],
                                })),
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="p-3 border bg-light template-info"
                        onClick={() =>
                          props.onNavigate("/editor", null, null, template)
                        }
                      >
                        <div className="sign-title">
                          <h3>{template.name}</h3>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <Pagination
                page={page}
                total={total}
                limit={limit}
                onPageChange={(pageNum) => setPage(pageNum)}
              />
            </>
          )}
        </>
      )}
    </>
  );
}

export default Templates;
