import React, { useState } from "react";

import Container from "./components/Container";
import Row from "./components/Row";
import Grid from "./components/Grid";
import Alert from "./components/Alert";

import authService from "./services/authService";

import logo from "./images/kaskealogo2.png";
import "./Login.scss";

import { AuthContext } from "./App";

function Login(): JSX.Element {
  const { dispatch } = React.useContext(AuthContext);

  const initialState = {
    email: "",
    password: "",
    isSubmitting: false,
    errorMessage: "",
  };

  const [data, setData] = useState(initialState);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setData({
      ...data,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    authService
      .login({ email: data.email, password: data.password })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: "LOGIN",
            user: response.data.user,
          });
        } else if (response?.status === 401) {
          setData({
            ...data,
            errorMessage: "Väärä käyttäjätunnus tai salasana",
          });
          setTimeout(
            () =>
              setData({
                ...data,
                password: "",
              }),
            1500,
          );
        } else {
          setData({
            ...data,
            errorMessage:
              "Ongelmia kirjautumisessa, kokeile myöhemmin uudelleen",
          });
        }
      });
  };

  return (
    <>
      {data.errorMessage && (
        <Alert label={"danger"} message={data.errorMessage}></Alert>
      )}
      <Container className="loginPage">
        <Row
          id={"loginRow"}
          rowExtra={"justify-content-center align-items-center"}
        >
          <Grid id={"loginColumn"} column={"4"}>
            <Grid id={"loginBox"} column={"12"}>
              <form onSubmit={(e) => handleSubmit(e)}>
                <div className={"loginLogo"}>
                  <img src={logo} />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputEmail1" className="form-label">
                    Sähköpostiosoite
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    onChange={handleInputChange}
                    value={data.email}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Salasana
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    onChange={handleInputChange}
                    value={data.password}
                  />
                </div>
                <div className="d-grid gap-2">
                  <button className="btn btn-primary" type="submit">
                    Kirjaudu sisään
                  </button>
                </div>
              </form>
            </Grid>
          </Grid>
        </Row>
      </Container>
    </>
  );
}

export default Login;
