import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Template,
  ItemData,
  TemplateStub,
  TextHorizontalAlign,
  TextSide,
  TextAnchorX,
  TextAnchorY,
} from "./types";
import "./Sign.scss";

import svgpath from "svgpath";
import {
  AXIS,
  calculatePoint,
  parseSegment,
  parseSvgSize,
} from "./utils/svgUtils";

export type SignErrors = { [key: string]: string[] };

const supportedFonts: {
  [key: string]: { sizefactor: number; letterspacing?: number };
} = {
  Traficom20: { sizefactor: 1.6 },
  "Arial MT Pro": { sizefactor: 1.393 },
  "Swiss721 BT": { sizefactor: 1.391, letterspacing: 1.25 },
  Helvetica: { sizefactor: 1.393 },
};
interface SignProps {
  className: string;
  template: Template | TemplateStub | null;
  isBackSide: boolean;
  data: Partial<ItemData>;
  waterMark?: boolean;
  onErrors?: (errors: SignErrors) => void;
}

const allowedGroupAttributes = ["fill-rule"];

function Sign(props: SignProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [svgObject, setSvgObject] = useState<Record<string, any>>();
  const [svgWidth, setSvgWidth] = useState<number | null>(null);
  const [svgHeight, setSvgHeight] = useState<number | null>(null);

  const svgEle = useRef<SVGSVGElement>(null);

  const parseTemplate = () => {
    if (props.template) {
      const useBackTemplate = props.isBackSide && !!props.template.backTemplate;

      const parser = new DOMParser();
      const doc = parser.parseFromString(
        useBackTemplate && props.template.backTemplate
          ? props.template.backTemplate
          : props.template.template,
        "image/svg+xml",
      );

      const svgSize = parseSvgSize(doc);
      setSvgWidth(svgSize.width);
      setSvgHeight(svgSize.height);

      if (svgSize.width && svgSize.height) {
        parseSvgElements(
          doc,
          svgSize.width,
          svgSize.height,
          !!props.isBackSide,
          useBackTemplate,
        );
      }
    }
  };

  const calculateAnchorPoint = (
    parent: SVGSVGElement,
    type: AXIS,
    anchorId: string,
    anchorLocation: TextAnchorX | TextAnchorY,
    offset: number,
    fallback: number,
  ): number => {
    const anchorTextElements = parent.querySelectorAll(
      `text.${getTextClass(anchorId)}`,
    ) as unknown as SVGTextElement[];

    if (anchorTextElements.length) {
      const [minX, maxX, minY, maxY] = [...anchorTextElements].reduce(
        (
          out: [number | null, number | null, number | null, number | null],
          elem,
        ) => {
          const box = elem.getBBox();
          if (out[0] === null || out[0] > box.x) out[0] = box.x;
          if (out[1] === null || out[1] < box.x + box.width)
            out[1] = box.x + box.width;
          if (out[2] === null || out[2] > box.y) out[2] = box.y;
          if (out[3] === null || out[3] < box.y + box.height)
            out[3] = box.y + box.height;
          return out;
        },
        [null, null, null, null],
      );

      if (type == AXIS.X && minX && maxX) {
        return (
          offset +
          ((anchorLocation as TextAnchorX) === TextAnchorX.START ? minX : maxX)
        );
      } else if (type == AXIS.Y && minY && maxY) {
        return (
          offset +
          ((anchorLocation as TextAnchorY) === TextAnchorY.TOP ? minY : maxY)
        );
      }
    }

    return fallback;
  };

  const calculatePointHelper = (
    type: AXIS,
    original_value: number,
    refSize: number,
    flipPoints: boolean,
    flipExtendAreas: boolean,
  ): number => {
    const baseExternAreas =
      type == AXIS.X
        ? props.template?.properties.horizontalExtends
        : props.template?.properties.verticalExtends;
    const targetSize =
      type == AXIS.X
        ? props.data.width || props.template?.properties.previewWidth
        : getDataHeight(props.data) || props.template?.properties.previewHeight;

    return calculatePoint(
      type,
      original_value,
      refSize,
      flipPoints,
      flipExtendAreas,
      targetSize,
      baseExternAreas,
    );
  };

  const getColorFromTemplateMap = (color: string) => {
    if (props.data.colorPreset && props.template?.properties.colorPresets) {
      const preset = props.template?.properties.colorPresets.find(
        (preset) => preset.name == props.data.colorPreset,
      );
      if (preset) {
        return preset.map[color] ?? color;
      }
    }
    return color;
  };

  const getTextColorFromTemplate = () => {
    if (props.data.colorPreset && props.template?.properties.colorPresets) {
      const preset = props.template?.properties.colorPresets.find(
        (preset) => preset.name == props.data.colorPreset,
      );
      if (preset) {
        return preset.textColor;
      }
    }
    return "#000";
  };

  const parseSvgElements = (
    doc: XMLDocument,
    width: number,
    height: number,
    backSide: boolean,
    flippedDoc = false,
  ) => {
    const svgObjects = [];

    const svg = doc.querySelector("svg");

    if (svg) {
      const targetWidth =
        props.data.width || props.template?.properties.previewWidth;
      const targetHeight =
        getDataHeight(props.data) || props.template?.properties.previewHeight;

      for (let i = 0; i < svg.childNodes.length; i++) {
        if (svg.children[i] !== undefined) {
          const child = svg.children[i];

          if (child.tagName === "g") {
            const group = child;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const tmpG: Record<string, any> = {};

            tmpG["name"] = group.tagName;
            tmpG["properties"] = {};
            tmpG["children"] = [];

            let offsetX = 0,
              offsetY = 0;

            for (let ii = 0; ii < group.attributes.length; ii++) {
              if (group.attributes[ii].name == "transform") {
                for (const match of group.attributes[ii].value.matchAll(
                  /translate\(\s*([-\d.]+)[, ]([-\d.]+)\s*\)/g,
                )) {
                  if (match.length === 3) {
                    offsetX = parseFloat(match[1]);
                    offsetY = parseFloat(match[2]);
                  }
                }
              } else if (
                allowedGroupAttributes.includes(group.attributes[ii].name)
              ) {
                tmpG["properties"][group.attributes[ii].name] =
                  group.attributes[ii].value;
              }
            }

            for (let j = 0; j < group.childNodes.length; j++) {
              const baby = group.children[j];

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const tmpChild: Record<string, any> = {};
              tmpChild["properties"] = {};

              if (baby !== undefined) {
                tmpChild["name"] = baby.tagName;

                for (let jj = 0; jj < baby.attributes.length; jj++) {
                  if (baby.attributes[jj] !== undefined) {
                    if (baby.tagName === "path") {
                      if (baby.attributes[jj].name === "d") {
                        if (width && height) {
                          // eslint-disable-next-line @typescript-eslint/no-unused-vars
                          const dPath = svgpath(baby.attributes[jj].value)
                            .abs()
                            .iterate((segment) => {
                              return [
                                parseSegment(
                                  segment,
                                  offsetX,
                                  offsetY,
                                  width,
                                  height,
                                  flippedDoc,
                                  targetWidth,
                                  targetHeight,
                                  props.template?.properties.horizontalExtends,
                                  props.template?.properties.verticalExtends,
                                ),
                              ];
                            });

                          tmpChild["properties"][baby.attributes[jj].name] =
                            dPath.toString();

                          if (backSide && !flippedDoc) {
                            const translateAmount =
                              props.data.width ||
                              props.template?.properties.previewWidth ||
                              svgWidth;
                            tmpChild["properties"][
                              "transform"
                            ] = `scale(-1, 1) translate(-${translateAmount}, 0)`;
                          }
                        }
                      } else if (baby.attributes[jj].name === "fill") {
                        tmpChild["properties"][baby.attributes[jj].name] =
                          getColorFromTemplateMap(baby.attributes[jj].value) ??
                          baby.attributes[jj].value;
                      }
                    } else {
                      tmpChild["properties"][baby.attributes[jj].name] =
                        baby.attributes[jj].value;
                    }
                  }
                }
                tmpG.children.push(tmpChild);
              }
            }
            svgObjects.push(tmpG);
          } else {
            console.error(
              "Flat svg cannot be processed, must have group inside.",
            );
          }
        }
      }
      setSvgObject(svgObjects);
    }
  };

  const appendSVGChild = (
    elementType: string,
    target: HTMLElement | SVGElement,
    attributes: Record<string, unknown> = {},
    text = "",
  ) => {
    const element: SVGElement = document.createElementNS(
      "http://www.w3.org/2000/svg",
      elementType,
    );
    Object.entries(attributes).map((a) =>
      element.setAttribute(a[0], a[1] as string),
    );
    if (text) {
      const textNode = document.createTextNode(text);
      element.appendChild(textNode);
    }

    target.appendChild(element);
    return element;
  };

  function removeAllChildNodes(parent: SVGSVGElement) {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  }

  const getTextClass = (id: string) => {
    return `text${id.replace(" ", "_")}`;
  };

  function checkTextOverFlowErrors(parent: SVGSVGElement) {
    if (props.onErrors && svgWidth && svgHeight) {
      const errors: SignErrors = {};

      const textIdElements: { [id: string]: SVGTextElement[] } = {};

      //First find all svg text elements by id
      for (const text of props.data.texts || []) {
        textIdElements[text.id] = parent.querySelectorAll(
          `text.${getTextClass(text.id)}`,
        ) as unknown as SVGTextElement[];
      }

      for (const text of props.data.texts || []) {
        const textProps = props.template?.properties.texts.find(
          (tp) => tp.id == text.id && sideCheck(tp.side),
        );
        if (textProps && textIdElements[text.id]) {
          const flip = !!props.isBackSide && textProps.side !== TextSide.BACK;

          for (const textElement of textIdElements[text.id]) {
            const textBox = textElement.getBBox();
            const leftMarker = calculatePointHelper(
              AXIS.X,
              flip
                ? textProps.rightLimit ?? svgWidth
                : textProps.leftLimit ?? 0,
              svgWidth,
              flip,
              props.isBackSide,
            );
            const rightMarker = calculatePointHelper(
              AXIS.X,
              flip
                ? textProps.leftLimit ?? 0
                : textProps.rightLimit ?? svgWidth,
              svgWidth,
              flip,
              props.isBackSide,
            );

            const topMarker = calculatePointHelper(
              AXIS.Y,
              textProps.topLimit ?? 0,
              svgHeight,
              flip,
              props.isBackSide,
            );
            const bottomMarker = calculatePointHelper(
              AXIS.Y,
              textProps.bottomLimit ?? svgHeight,
              svgHeight,
              flip,
              props.isBackSide,
            );

            const content =
              textElement.textContent && textElement.textContent?.length > 8
                ? `${textElement.textContent?.slice(0, 6)}...`
                : textElement.textContent;

            if (textBox.x < leftMarker) {
              if (!errors[text.id]) errors[text.id] = [];

              errors[text.id].push(
                `Rivi "${content}" ylittyy vasemmasta laidasta ${Math.round(
                  Math.abs(textBox.x - leftMarker),
                )}mm`,
              );
            }

            if (textBox.x + textBox.width > rightMarker) {
              if (!errors[text.id]) errors[text.id] = [];
              errors[text.id].push(
                `Rivi "${content}" ylittyy oikeasta laidasta ${Math.round(
                  Math.abs(textBox.x + textBox.width - rightMarker),
                )}mm`,
              );
            }

            const textElementY = textElement.getAttribute("y");
            if (
              textElementY &&
              parseFloat(textElementY) - text.fontSize < topMarker
            ) {
              if (!errors[text.id]) errors[text.id] = [];

              errors[text.id].push(
                `Rivi "${content}" ylittyy ylälaidasta ${Math.round(
                  Math.abs(
                    parseFloat(textElementY) - text.fontSize - topMarker,
                  ),
                )}mm`,
              );
            }
            if (textElementY && parseFloat(textElementY) > bottomMarker) {
              if (!errors[text.id]) errors[text.id] = [];
              errors[text.id].push(
                `Rivi "${content}" ylittyy alalaidasta ${Math.round(
                  Math.abs(parseFloat(textElementY) - bottomMarker),
                )}mm`,
              );
            }

            if (textProps.horizontalTextGapLimit && textProps.x) {
              const posX = calculatePointHelper(
                AXIS.X,
                textProps.x,
                svgWidth,
                flip,
                props.isBackSide,
              );

              //loop through other text id elements and check that gap is enough
              for (const otherTextId of Object.keys(textIdElements).filter(
                (key) => key !== text.id,
              )) {
                const otherTextProps = props.template?.properties.texts.find(
                  (tp) => tp.id == otherTextId && sideCheck(tp.side),
                );
                if (otherTextProps && otherTextProps.x) {
                  const otherFlip =
                    !!props.isBackSide && otherTextProps.side !== TextSide.BACK;

                  const otherPosX = calculatePointHelper(
                    AXIS.X,
                    otherTextProps.x,
                    svgWidth,
                    otherFlip,
                    props.isBackSide,
                  );

                  for (const otherTextElement of textIdElements[otherTextId]) {
                    const otherTextBox = otherTextElement.getBBox();
                    if (
                      (otherPosX > posX &&
                        otherTextBox.x - (textBox.x + textBox.width) <
                          textProps.horizontalTextGapLimit) ||
                      (otherPosX < posX &&
                        textBox.x - (otherTextBox.x + otherTextBox.width) <
                          textProps.horizontalTextGapLimit)
                    ) {
                      if (!errors[text.id]) errors[text.id] = [];
                      errors[text.id].push(
                        `Rivi "${content}" on liian lähellä toista tekstiä`,
                      );
                    }
                  }
                }
              }
            }
          }
        }
      }
      props.onErrors(errors);
    }
  }

  const getTextAlign = useCallback(
    (align: TextHorizontalAlign, flip: boolean): TextHorizontalAlign => {
      if (flip) {
        switch (align) {
          case TextHorizontalAlign.START:
            return TextHorizontalAlign.END;
          case TextHorizontalAlign.END:
            return TextHorizontalAlign.START;
        }
      }
      return align;
    },
    [props.isBackSide],
  );

  useEffect(() => {
    if (props.data && svgEle.current && svgWidth && svgHeight) {
      const svg = svgEle.current;

      // first clean the svg element
      removeAllChildNodes(svg);

      // append parsed elements to svg element
      if (svgObject) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        svgObject.forEach((obj: any) => {
          if (obj.name === "g") {
            const group = appendSVGChild(obj.name, svg, obj.properties);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            obj.children.forEach((c: any) => {
              appendSVGChild(c.name, group, c.properties);
            });

            if (props.data.texts && props.data.texts.length) {
              const supportedFontFamilies = Object.keys(supportedFonts);

              // Insert texts in rounds to support anchored texts
              let roundIds: string[] = props.data.texts
                .filter((text) =>
                  props.template?.properties.texts.find(
                    (tp) =>
                      tp.id == text.id && sideCheck(tp.side) && !tp.anchorId,
                  ),
                )
                .map((tt) => tt.id);
              const addedIds: string[] = [];

              do {
                for (const textId of roundIds) {
                  const textData = props.data.texts.find(
                    (text) => text.id == textId,
                  );
                  if (!textData) continue;

                  const textProps = props.template?.properties.texts.find(
                    (tp) => tp.id == textData.id && sideCheck(tp.side),
                  );
                  if (!textProps) continue;

                  const flip =
                    !!props.isBackSide && textProps.side !== TextSide.BACK;

                  const fontFamily = supportedFontFamilies.includes(
                    textData.fontFamily,
                  )
                    ? textData.fontFamily
                    : supportedFontFamilies[0];
                  const fontSize =
                    textData.fontSize *
                      supportedFonts[fontFamily]?.sizefactor ?? 1.5;

                  const targetX =
                    textProps.anchorId && textProps.anchorX
                      ? calculateAnchorPoint(
                          svg,
                          AXIS.X,
                          textProps.anchorId,
                          textProps.anchorX,
                          textProps.anchorXOffset ?? 0,
                          textProps.x ?? 0,
                        )
                      : calculatePointHelper(
                          AXIS.X,
                          textProps.x ?? 0,
                          svgWidth,
                          flip,
                          props.isBackSide,
                        );

                  const targetY =
                    textProps.anchorId && textProps.anchorY
                      ? calculateAnchorPoint(
                          svg,
                          AXIS.Y,
                          textProps.anchorId,
                          textProps.anchorY,
                          textProps.anchorYOffset ?? 0,
                          textProps.y ?? 0,
                        )
                      : calculatePointHelper(
                          AXIS.Y,
                          textProps.y ?? 0,
                          svgHeight,
                          flip,
                          props.isBackSide,
                        );

                  const rawText =
                    textData.referenceText &&
                    textData.referenceText.split("\n").length ===
                      textData.text.split("\n").length
                      ? textData.referenceText
                      : textData.text;

                  const textlines = rawText
                    .split("\n")
                    .map((tl) => tl.trim())
                    .filter((tl) => !!tl);

                  const linesAmount = Math.min(
                    textlines.length,
                    textProps.maxLines,
                  );

                  const lineHeight =
                    textData.fontSize + textData.fontSize * 0.4;

                  const letterSpacing =
                    supportedFonts[fontFamily]?.letterspacing;

                  for (let li = 0; li < linesAmount; li++) {
                    const textline =
                      fontFamily == "Traficom20"
                        ? textlines[li].replaceAll(" ", "_")
                        : textlines[li];
                    appendSVGChild(
                      "text",
                      group,
                      {
                        "font-family": fontFamily,
                        "font-size": fontSize,
                        "text-anchor": getTextAlign(
                          textProps.horizontalAlign,
                          flip,
                        ),
                        x: targetX,
                        y:
                          targetY +
                          lineHeight * (li - (linesAmount - 1) / 2) +
                          textData.fontSize / 2,
                        fill: getTextColorFromTemplate(),
                        class: getTextClass(textData.id),
                        ...(letterSpacing
                          ? {
                              "letter-spacing": letterSpacing,
                            }
                          : {}),
                      },
                      textline,
                    );
                  }

                  addedIds.push(textId);
                }

                //Update next round ids
                roundIds = props.data.texts
                  .filter(
                    (text) =>
                      !addedIds.includes(text.id) &&
                      props.template?.properties.texts.find(
                        (tp) =>
                          tp.id == text.id &&
                          sideCheck(tp.side) &&
                          tp.anchorId &&
                          addedIds.includes(tp.anchorId),
                      ),
                  )
                  .map((tt) => tt.id);
              } while (roundIds.length);

              // Loop through added text ids and update textAlignment
              for (const textId of addedIds) {
                const textData = props.data.texts.find(
                  (text) => text.id == textId,
                );
                if (!textData) continue;

                const textProps = props.template?.properties.texts.find(
                  (tp) => tp.id == textData.id && sideCheck(tp.side),
                );
                if (
                  !textProps ||
                  (!textProps.allowSizeReference && !textProps.textAdjust)
                )
                  continue;

                const anchorTextElements = svg.querySelectorAll(
                  `text.${getTextClass(textId)}`,
                ) as unknown as SVGTextElement[];

                const [minX, maxX] = [...anchorTextElements].reduce(
                  (out: [number | null, number | null], elem) => {
                    const box = elem.getBBox();
                    if (out[0] === null || out[0] > box.x) out[0] = box.x;
                    if (out[1] === null || out[1] < box.x + box.width)
                      out[1] = box.x + box.width;
                    return out;
                  },
                  [null, null],
                );

                if (!minX || !maxX) continue;

                const textlines = textData.text
                  .split("\n")
                  .map((tl) => tl.trim())
                  .filter((tl) => !!tl);

                const linesAmount = Math.min(
                  textlines.length,
                  textProps.maxLines,
                );

                for (
                  let li = 0;
                  li < linesAmount || li < anchorTextElements.length;
                  li++
                ) {
                  const elem = anchorTextElements[li];
                  if (!elem) continue;
                  const textElement = elem.firstChild;
                  if (!textElement) continue;

                  (textElement as Text).textContent = `${textlines[li]}`;

                  if (textProps.textAdjust) {
                    let x = 0;
                    switch (textProps.textAdjust) {
                      case TextHorizontalAlign.START:
                        x = minX;
                        break;
                      case TextHorizontalAlign.MIDDLE:
                        x = minX + (maxX - minX) / 2;
                        break;
                      case TextHorizontalAlign.END:
                        x = maxX;
                        break;
                    }

                    elem.setAttribute("x", `${x}`);
                    elem.setAttribute("text-anchor", textProps.textAdjust);
                  }
                }
              }

              if (props.waterMark) {
                const watermarkFontSize =
                  (getDataHeight(props.data) ?? svgHeight) / 10;
                const watermarkX = calculatePointHelper(
                  AXIS.X,
                  svgWidth / 2,
                  svgWidth,
                  false,
                  false,
                );
                const watermarkY =
                  calculatePointHelper(
                    AXIS.Y,
                    svgHeight / 2,
                    svgHeight,
                    false,
                    false,
                  ) +
                  watermarkFontSize / 2;

                appendSVGChild(
                  "text",
                  group,
                  {
                    "font-family": supportedFontFamilies[0],
                    "font-size": watermarkFontSize * 1.6,
                    "text-anchor": "middle",
                    x: watermarkX,
                    y: watermarkY,
                    fill: "#eee",
                    "fill-opacity": "30%",
                    transform: `rotate(35,${watermarkX},${watermarkY})`,
                    class: getTextClass("watermark"),
                  },
                  "KASKEA GROUP",
                );
              }
            }
          } else {
            console.error(
              "Flat svg cannot be processed, must have group inside.",
            );

            appendSVGChild(obj.name, svg, obj.properties);
          }
        });
      }

      checkTextOverFlowErrors(svg);
    }
  }, [props.data, svgObject, svgEle.current, svgWidth, svgHeight]);

  useEffect(() => {
    parseTemplate();
  }, [props.data]);

  const getWidth = useCallback(() => {
    return (
      props.data.width ||
      props.template?.properties.previewWidth ||
      svgWidth ||
      100
    );
  }, [props.data.width, svgWidth]);

  const getHeight = useCallback(() => {
    return (
      getDataHeight(props.data) ||
      props.template?.properties.previewHeight ||
      svgHeight ||
      100
    );
  }, [props.data.height, svgHeight]);

  const sideCheck = (side: TextSide) =>
    side == TextSide.BOTH ||
    (!props.isBackSide && side == TextSide.FRONT) ||
    (props.isBackSide && side == TextSide.BACK);

  const getDataHeight = (data: Partial<ItemData>) => {
    if (
      data.height &&
      data.height > 0 &&
      data.mountingMethod &&
      data.mountingMethod.startsWith("5")
    ) {
      return data.height - 7; //for mounting method 5X sheet is 7mm less than actual sign size
    }
    return data.height;
  };

  return (
    <>
      <svg
        className={`svg-component ${props.className}`}
        viewBox={`0 0 ${getWidth()} ${getHeight()}`}
        width={getWidth() + "mm"}
        height={getHeight() + "mm"}
        ref={svgEle}
      />
    </>
  );
}

export default Sign;
