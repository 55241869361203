import { Modal } from "bootstrap";
import React, { useState } from "react";
import templatePresetService from "../../services/templatePresetService";
import { ItemData, TemplatePreset } from "../../types";

interface SaveTemplatePresetAsModal {
  modal: Modal | null;
  templateId: string;
  data: ItemData;
  onSave: (preset: TemplatePreset | null) => void;
  onMessage: (type: string, text: string) => void;
}

// eslint-disable-next-line react/display-name
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line react/display-name
const SaveTemplatePresetAsDialog = React.forwardRef(
  (props: SaveTemplatePresetAsModal, ref: React.Ref<HTMLDivElement>) => {
    const [presetName, setPresetName] = useState<string | null>("");

    const handleCancel = () => {
      props.modal?.hide();
    };

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
      setPresetName(e.currentTarget.value || null);
    };

    const handleSubmit = () => {
      if (presetName && props.templateId) {
        const presetData: Omit<ItemData, "identifier"> = {
          colorPreset: props.data.colorPreset,
          width: props.data.width,
          height: props.data.height,
          sheetType: props.data.sheetType,
          mountingMethod: props.data.mountingMethod,
          plateType: props.data.plateType,
          extraType: props.data.extraType,
          backSide: props.data.backSide,
          texts: props.data.texts,
        };

        templatePresetService
          .createTemplatePreset({
            data: presetData,
            templateId: props.templateId,
            name: presetName,
          })
          .then((response) => {
            if (response.status === 201) {
              props.onMessage("success", "Asetuspohja luotu");
              props.onSave(response.data);
              props.modal?.hide();
            } else {
              props.onMessage("danger", "Virhe asetuspohjaa tallennettaessa");
              props.onSave(null);
              props.modal?.hide();
            }
          });
      }
    };

    return (
      <div
        id="saveTemplatePresetAsModal"
        className="modal modal-import-order"
        tab-index="-1"
        ref={ref}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Anna kylttipohjan nimi</h5>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => handleCancel()}
              ></button>
            </div>
            <form>
              <div className="modal-body">
                <div className="mb-3">
                  <label htmlFor="presetname" className="col-form-label">
                    Pohjan nimi:
                  </label>
                  <input
                    className="form-control"
                    name="presetname"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </form>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => handleCancel()}
              >
                Sulje
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => handleSubmit()}
              >
                Tallenna
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default SaveTemplatePresetAsDialog;
