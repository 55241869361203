import React from "react";
import { FormikState, useFormik } from "formik";
import * as Yup from "yup";

import userService from "../../services/userService";

import { CreateUser, UserModal } from "../../types";

const CreateUserSchema = Yup.object().shape({
  email: Yup.string()
    .email("Sähköposti ei kelpaa")
    .required("Pakollinen kenttä"),
  firstname: Yup.string()
    .min(3, "Etunimen minimipituus on 3-merkkiä!")
    .max(25, "Liian pitkä etunimi")
    .required("Pakollinen kenttä"),
  lastname: Yup.string()
    .min(3, "Sukunimen minimipituus on 3-merkkiä!")
    .max(25, "Liian pitkä sukunimi")
    .required("Pakollinen kenttä"),
  role: Yup.string().required("Pakollinen kenttä"),
  active: Yup.boolean().required("Pakollinen kenttä"),
  password: Yup.string()
    .min(8, "Salasanan minimipituus on 8-merkkiä!")
    .max(25, "Liian pitkä salasana!")
    .required("Pakollinen kenttä"),
  password_confirmation: Yup.string()
    .min(8, "Salasanan minimipituus on 8-merkkiä!")
    .max(25, "Liian pitkä salasana!")
    .oneOf([Yup.ref("password"), null], "Salasanat eivät täsmää")
    .required("Pakollinen kenttä"),
});

const UpdateUserSchema = Yup.object().shape({
  email: Yup.string()
    .email("Sähköposti ei kelpaa")
    .required("Pakollinen kenttä"),
  firstname: Yup.string()
    .min(3, "Etunimen minimipituus on 3-merkkiä!")
    .max(25, "Liian pitkä etunimi")
    .required("Pakollinen kenttä"),
  lastname: Yup.string()
    .min(3, "Sukunimen minimipituus on 3-merkkiä!")
    .max(25, "Liian pitkä sukunimi")
    .required("Pakollinen kenttä"),
  role: Yup.string().required("Pakollinen kenttä"),
  active: Yup.boolean().required("Pakollinen kenttä"),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const UserDialog = React.forwardRef((props: UserModal, ref: any) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: props.user ? props.user.email : "",
      firstname: props.user ? props.user.firstname : "",
      lastname: props.user ? props.user.lastname : "",
      role: props.user ? props.user.role : "admin",
      active: props.user ? props.user.active : true,
      password: "",
      password_confirmation: "",
    },
    validationSchema: props.user === null ? CreateUserSchema : UpdateUserSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, resetForm);
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = (
    data: CreateUser,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resetForm: (nextState?: Partial<FormikState<any>>) => void,
  ) => {
    if (props.user === null) {
      userService
        .createUser({
          email: data.email,
          firstname: data.firstname,
          lastname: data.lastname,
          active: data.active,
          role: data.role,
          password: data.password,
        })
        .then((response) => {
          try {
            if (response.status === 201) {
              resetForm({});
              props.onMessage("success", "Käyttäjä luotu onnistuneesti");
              props.onSetUser();
              props.modal?.hide();
            } else if (response.status === 409) {
              resetForm({});
              props.onMessage("danger", "Sähköpostiosoite on jo käytössä");
              props.modal?.hide();
            } else {
              resetForm({});
              props.onMessage("danger", "Käyttäjän luonnissa tapahtui virhe");
              props.modal?.hide();
            }
          } catch (e) {
            props.onMessage("danger", "Käyttäjän luonnissa tapahtui virhe");
            props.modal?.hide();
          }
        });
    } else {
      const id = props.user.id;
      userService
        .updateUser(id, {
          email: data.email,
          firstname: data.firstname,
          lastname: data.lastname,
          role: data.role,
          active: data.active,
        })
        .then((response) => {
          if (response.status === 200) {
            resetForm({});
            props.onSetUser();
            props.onMessage("success", "Käyttäjä päivitetty onnistuneesti");
            props.modal?.hide();
          } else if (response.status === 409) {
            resetForm({});
            props.onMessage("danger", "Sähköpostiosoite on jo käytössä");
            props.modal?.hide();
          } else {
            props.onMessage("danger", "Käyttäjän päivityksessä tapahtui virhe");
          }
        });
    }
  };

  const handleCancel = () => {
    formik.resetForm({});
    props.modal?.hide();
  };

  return (
    <div id="createUser" className="modal" tab-index="-1" ref={ref}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {props.user === null ? "Lisää käyttäjä" : "Muokkaa käyttäjää"}
            </h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => handleCancel()}
            ></button>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="email" className="col-form-label">
                  Sähköpostiosoite:
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.errors.email && formik.touched.email ? (
                  <div className="text-danger">{formik.errors.email}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="firstname" className="col-form-label">
                  Etunimi:
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="firstname"
                  name="firstname"
                  onChange={formik.handleChange}
                  value={formik.values.firstname}
                />
                {formik.errors.firstname && formik.touched.firstname ? (
                  <div className="text-danger">{formik.errors.firstname}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="lastname" className="col-form-label">
                  Sukunimi:
                </label>
                <input
                  className="form-control"
                  type="text"
                  id="lastname"
                  name="lastname"
                  onChange={formik.handleChange}
                  value={formik.values.lastname}
                />
                {formik.errors.lastname && formik.touched.lastname ? (
                  <div className="text-danger">{formik.errors.lastname}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="role" className="col-form-label">
                  Rooli:
                </label>
                <select
                  className="form-select"
                  id="role"
                  name="role"
                  onChange={formik.handleChange}
                  value={formik.values.role}
                >
                  <option value="admin">Pääkäyttäjä</option>
                  <option value="sales">Myyjä</option>
                </select>
                {formik.errors.role && formik.touched.role ? (
                  <div className="text-danger">{formik.errors.role}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="active" className="col-form-label">
                  Aktiivinen:
                </label>
                <select
                  className="form-select"
                  id="active"
                  name="active"
                  onChange={() =>
                    formik.setFieldValue("active", !formik.values.active)
                  }
                >
                  <option value={"1"}>Kyllä</option>
                  <option value={"0"}>Ei</option>
                </select>
                {formik.errors.active && formik.touched.active ? (
                  <div className="text-danger">{formik.errors.active}</div>
                ) : null}
              </div>
            </div>
            {!props.user && (
              <>
                <hr></hr>
                <div className="modal-body">
                  <div className="mb-3">
                    <label htmlFor="password" className="col-form-label">
                      Salasana:
                    </label>
                    <input
                      className="form-control"
                      id="password"
                      name="password"
                      type="password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    {formik.errors.password && formik.touched.password ? (
                      <div className="text-danger">
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="password_confirmation"
                      className="col-form-label"
                    >
                      Salasana uudelleen:
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password_confirmation"
                      name="password_confirmation"
                      onChange={formik.handleChange}
                      value={formik.values.password_confirmation}
                    />
                    {formik.errors.password_confirmation &&
                    formik.touched.password_confirmation ? (
                      <div className="text-danger">
                        {formik.errors.password_confirmation}
                      </div>
                    ) : null}
                  </div>
                </div>
              </>
            )}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => handleCancel()}
              >
                Sulje
              </button>
              <button type="submit" className="btn btn-primary">
                Tallenna
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default UserDialog;
