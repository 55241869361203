import React, { useEffect, useState, MouseEvent, useCallback } from "react";
import {
  ExtendArea,
  LayoutHorizontal,
  LayoutItem,
  LayoutItemType,
  LayoutNode,
  LayoutVertical,
  ProjectItem,
} from "./types";
import "./SignSplitter.scss";

import svgpath from "svgpath";
import {
  AXIS,
  parseSplitEdgesString,
  parseNodeId,
  parseSvgSize,
  SplittedSvgData,
  SVGObjects,
  getTargetSize,
  SignSplitLine,
} from "./utils/svgUtils";
import {
  BoundingBox,
  CutLine,
  calcSplitPath,
  findCutLineProposals,
  SplitType,
  Side,
  calcPathBoundingBoxAndCutLines,
} from "./utils/splitCalculator";
import SplittedLayout from "./SplittedLayout";

declare global {
  interface Crypto {
    randomUUID: () => string;
  }
}

const LAYOUT_PADDING_HEIGHT = 200;
const LAYOUT_PADDING_WIDTH = 100;
const MIN_EXTEND_AREA_HEIGHT = 20;
const MIN_EXTEND_AREA_WIDTH = 30;
const SINGLE_SIGN_CUTLINE_AMOUNT = 6;

interface SignSplitterProps {
  className: string;
  projectItem: Partial<ProjectItem>;
  snap: boolean;
  visibleExtendAreas: boolean;
}

const autocadColorMap: { [key: string]: string } = {
  ["#005eb8"]: "#3366ff", // Blue
  ["#009775"]: "#009775", // Green
  ["#e4002b"]: "#e4002b", // Red
  ["#ff3f00"]: "#e4002b", // Red
  ["#101820"]: "#000000", // Black
  ["#fe5000"]: "#ff9900", // Orange
  ["#ffff00"]: "#ffff00", // Yellow
  ["#ffcd00"]: "#ffff00", // Yellow
  ["#6b3529"]: "#d1a077", // Brown
  ["#969696"]: "#c0c0c0", // Grey
  ["#ffffff"]: "#ffffff",
  ["#fff"]: "#ffffff",
};

function SignSplitter(props: SignSplitterProps): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const allowedGroupAttributes = ["fill-rule"];
  const [splittedSvgObjects, setSplittedSvgObjects] = useState<
    SplittedSvgData[]
  >([]);
  const [splitAxis, setSplitAxis] = useState<AXIS>(AXIS.X);
  const [ctrlPressed, setCtlrPressed] = useState<boolean>(false);
  const [shiftPressed, setShiftPressed] = useState<boolean>(false);
  const [nextNodeId, setNextNodeId] = useState<number>(0);

  const findLayoutItemByIndex = (
    node: LayoutItem,
    node_id: number,
    parent?: LayoutHorizontal | LayoutVertical,
    parent_index?: number,
  ): {
    found: boolean;
    item?: LayoutNode;
    parent?: LayoutItem;
    parent_index?: number;
  } => {
    switch (node.type) {
      case LayoutItemType.NODE:
        if (node.node_id == node_id) {
          return {
            found: true,
            item: node,
            parent,
            parent_index,
          };
        }
        break;
      case LayoutItemType.HORIZONTAL:
      case LayoutItemType.VERTICAL:
        for (const [sub_item_index, sub_item] of node.children.entries()) {
          const res = findLayoutItemByIndex(
            sub_item,
            node_id,
            node,
            sub_item_index,
          );
          if (res.found) {
            return res;
          }
        }
        break;
    }
    return { found: false };
  };

  const splitLayout = useCallback(
    (a_side: SplittedSvgData, b_side: SplittedSvgData, axis: AXIS): boolean => {
      if (!props.projectItem.layout) {
        return false;
      }

      const target = findLayoutItemByIndex(
        props.projectItem.layout,
        a_side.nodeId,
      );
      if (!target.found) {
        console.error("Failed to find split target from layout");
        return false;
      }

      const new_layout_item: LayoutItem = {
        type:
          axis === AXIS.X ? LayoutItemType.HORIZONTAL : LayoutItemType.VERTICAL,
        width: axis === AXIS.Y ? a_side.width + b_side.width : a_side.width,
        height: axis === AXIS.X ? a_side.height + b_side.height : a_side.height,
        children: [
          {
            type: LayoutItemType.NODE,
            width: a_side.width,
            height: a_side.height,
            node_id: a_side.nodeId,
          },
          {
            type: LayoutItemType.NODE,
            width: b_side.width,
            height: b_side.height,
            node_id: b_side.nodeId,
          },
        ],
      };

      if (target.parent) {
        const parent = target.parent as LayoutHorizontal | LayoutVertical;
        if (parent.type == new_layout_item.type) {
          parent.children.splice(
            target.parent_index || 0,
            1,
            ...new_layout_item.children,
          );
        } else {
          parent.children.splice(target.parent_index || 0, 1, new_layout_item);
        }
        if (parent.type == LayoutItemType.HORIZONTAL) {
          parent.height = parent.children.reduce(
            (sum, item) => sum + item.height,
            0,
          );
        } else {
          parent.width = parent.children.reduce(
            (sum, item) => sum + item.width,
            0,
          );
        }
      } else {
        props.projectItem.layout = new_layout_item;
      }

      return true;
    },
    [props.projectItem.layout],
  );

  const updateNextNodeId = useCallback(
    (index?: number) => {
      setNextNodeId(index ?? nextNodeId + 1);
      return nextNodeId;
    },
    [nextNodeId],
  );

  const parseSvg = (svg: string, full: boolean): SplittedSvgData | null => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svg, "image/svg+xml");

    const svgSize = parseSvgSize(doc);

    if (svgSize.width && svgSize.height) {
      const svgObject = parseSvgElements(doc);
      const splitEdges = parseSplitEdgesString(doc);
      const splitIndex = parseNodeId(doc);
      const targetSize = getTargetSize(
        svgSize.width,
        svgSize.height,
        splitEdges,
      );

      return {
        svgObjects: svgObject,
        width: svgSize.width,
        height: svgSize.height,
        targetWidth: targetSize.width,
        targetHeight: targetSize.height,
        verticalExtends: [],
        horizontalExtends: [],
        splitEdges,
        key: crypto.randomUUID(),
        offset: { x: 0, y: 0 },
        nodeId: splitIndex || updateNextNodeId(),
        splittedHorizontally: !full,
        splittedVertically: !full,
        //DEBUG__CUTLINES: [],
        signSplitLines: [],
      };
    }
    return null;
  };

  const parseSvgElements = (doc: XMLDocument): SVGObjects => {
    const svgObjects = [];

    const svg = doc.querySelector("svg");

    if (svg) {
      for (let i = 0; i < svg.childNodes.length; i++) {
        if (svg.children[i] !== undefined) {
          const child = svg.children[i];

          if (child.tagName === "g") {
            const group = child;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const tmpG: Record<string, any> = {};

            tmpG["name"] = group.tagName;
            tmpG["properties"] = {};
            tmpG["children"] = [];

            for (let ii = 0; ii < group.attributes.length; ii++) {
              if (allowedGroupAttributes.includes(group.attributes[ii].name)) {
                tmpG["properties"][group.attributes[ii].name] =
                  group.attributes[ii].value;
              }
            }

            for (let j = 0; j < group.childNodes.length; j++) {
              const baby = group.children[j];

              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              const tmpChild: Record<string, any> = {};
              tmpChild["properties"] = {};

              if (baby !== undefined) {
                tmpChild["name"] = baby.tagName;

                for (let jj = 0; jj < baby.attributes.length; jj++) {
                  if (baby.attributes[jj] !== undefined) {
                    if (
                      baby.tagName === "path" &&
                      baby.attributes[jj].name === "d"
                    ) {
                      // Convert paths to absolute paths
                      const absolutePath = svgpath(baby.attributes[jj].value)
                        .abs()
                        .toString();

                      tmpChild["properties"][baby.attributes[jj].name] =
                        absolutePath.toString();
                    } else if (baby.attributes[jj].name === "fill") {
                      const color = baby.attributes[jj].value.toLowerCase();
                      tmpChild["properties"][baby.attributes[jj].name] =
                        autocadColorMap[color] ?? color;
                    }
                  }
                }
                tmpG.children.push(tmpChild);
              }
            }
            svgObjects.push(tmpG);
          } else {
            console.error(
              "Flat svg cannot be processed, must have group inside.",
            );
          }
        }
      }
    }
    return svgObjects;
  };

  useEffect(() => {
    updateNextNodeId(1);
    let svgObjects: SplittedSvgData[] = [];
    if (
      props.projectItem.splitData &&
      props.projectItem.splitData.length &&
      props.projectItem.layout
    ) {
      svgObjects = [
        ...(props.projectItem.splitData
          .map((data) => parseSvg(data.svg, false))
          .filter((data) => data != null) as SplittedSvgData[]),
      ];
    } else if (props.projectItem.svg) {
      const splittedSVGObject = parseSvg(props.projectItem.svg, true);

      if (splittedSVGObject) {
        props.projectItem.layout = {
          type: LayoutItemType.NODE,
          node_id: splittedSVGObject.nodeId,
          width: splittedSVGObject.width,
          height: splittedSVGObject.height,
        };

        svgObjects = [splittedSVGObject];
      }
    }

    for (const splittedObject of svgObjects) {
      findLines(splittedObject);
    }

    setSplittedSvgObjects(svgObjects);
  }, [props.projectItem.svg, props.projectItem.splitData]);

  const onRightMouseClick = (
    event: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
  ) => {
    setSplitAxis(splitAxis === AXIS.X ? AXIS.Y : AXIS.X);
    event.preventDefault();
    event.stopPropagation();
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.ctrlKey && !ctrlPressed) {
      setCtlrPressed(true);
    }
    if (event.shiftKey && !shiftPressed) {
      setShiftPressed(true);
    }
  };

  const onKeyUp = (event: KeyboardEvent) => {
    if (!event.ctrlKey) {
      setCtlrPressed(false);
    }
    if (!event.shiftKey) {
      setShiftPressed(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  const addExtendArea = (
    index: number,
    axis: AXIS,
    start: number,
    end: number,
    symmetric = false,
  ) => {
    if (splittedSvgObjects[index]) {
      const splitTarget = splittedSvgObjects[index];
      const areas =
        axis == AXIS.X
          ? splitTarget.verticalExtends
          : splitTarget.horizontalExtends;

      const refSize = axis == AXIS.X ? splitTarget.height : splitTarget.width;
      const setAreas = [
        { start, end },
        ...(symmetric ? [{ start: refSize - end, end: refSize - start }] : []),
      ];

      for (const setArea of setAreas) {
        let overlappingIndex = -1;
        let merged = false;
        let overlappingArea: ExtendArea | null = null;

        //Find all areas that can be merged together
        do {
          overlappingIndex = areas.findIndex(
            (area) =>
              (area.start > setArea.start && area.start < setArea.end) ||
              (area.end > setArea.start && area.end < setArea.end) ||
              (area.start < setArea.start && area.end > setArea.end),
          );
          if (overlappingIndex >= 0) {
            if (
              areas[overlappingIndex].start <= setArea.start &&
              areas[overlappingIndex].end >= setArea.end
            ) {
              // Remove area
              areas.splice(overlappingIndex, 1);
            } else {
              if (overlappingArea) {
                //Extend already found area and remove newly found. Store start and end.
                setArea.start = Math.min(
                  areas[overlappingIndex].start,
                  setArea.start,
                );
                setArea.end = Math.max(
                  areas[overlappingIndex].end,
                  setArea.end,
                );
                areas.splice(overlappingIndex, 1);
              } else {
                overlappingArea = areas[overlappingIndex];
              }

              overlappingArea.start = Math.min(
                overlappingArea.start,
                setArea.start,
              );
              overlappingArea.end = Math.max(overlappingArea.end, setArea.end);
              setArea.start = overlappingArea.start;
              setArea.end = overlappingArea.end;
            }
            merged = true;
          }
        } while (overlappingIndex >= 0);

        if (!merged) {
          areas.push({ start: setArea.start, end: setArea.end });
        }
      }

      splitTarget.key = crypto.randomUUID();

      setSplittedSvgObjects([...splittedSvgObjects]);
    }
  };

  const findExtendAreas = (
    svgObj: SplittedSvgData,
    cutLines: CutLine[],
    boundingBoxes: BoundingBox[],
    axes: AXIS[],
  ) => {
    const processPendingExtendAreas = (
      axis: AXIS,
      pendingExtendAreas: {
        weight: number;
        start: number;
        end: number;
      }[],
    ) => {
      const filtered = pendingExtendAreas.filter(
        (pea) =>
          pea.end - pea.start >
          (axis === AXIS.Y ? MIN_EXTEND_AREA_WIDTH : MIN_EXTEND_AREA_HEIGHT),
      );
      filtered.sort((a, b) => a.weight - b.weight);

      if (filtered.length) {
        for (const ea of filtered) {
          if (ea.weight > filtered[0].weight + SINGLE_SIGN_CUTLINE_AMOUNT) {
            break;
          }
          (axis === AXIS.Y
            ? svgObj.horizontalExtends
            : svgObj.verticalExtends
          ).push({
            start: ea.start,
            end: ea.end,
          });
        }
      }
    };

    const collidesBoundingBox = (
      axis: AXIS,
      start_offset: number,
      end_offset: number,
    ) => {
      switch (axis) {
        case AXIS.X:
          return boundingBoxes.some(
            (bb) =>
              (bb.top >= start_offset && bb.top <= end_offset) ||
              (bb.bottom >= start_offset && bb.bottom <= end_offset),
          );
          break;
        case AXIS.Y:
          return boundingBoxes.some(
            (bb) =>
              (bb.left >= start_offset && bb.left <= end_offset) ||
              (bb.right >= start_offset && bb.right <= end_offset),
          );
          break;
      }
    };

    for (const axis of axes) {
      const pendingExtendAreas: {
        weight: number;
        start: number;
        end: number;
      }[] = [];

      const filteredCutLines = cutLines
        .filter((cl) => cl.axis == axis && !cl.signSplit)
        .sort((a, b) => a.offset - b.offset);

      if (!filteredCutLines.length) return;

      let start_offset: number | null = null;
      let end_offset: number | null = null;
      let prev_cutline_signature: number | null = null;
      let prev_cutline_weight: number | null = null;
      let pending_weight = 0;
      let fail = false;

      for (const cl of filteredCutLines) {
        let cutline_weight = 0;
        fail = false;
        let cutline_signature = 0;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        for (const obj of svgObj.svgObjects as any[]) {
          if (obj.name === "g") {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            for (const c of obj.children) {
              if (c.name === "path" && c.properties["d"]) {
                const { success, weight, signature, has_diagonal } =
                  calcSplitPath(
                    [],
                    [],
                    axis,
                    cl.offset,
                    c.properties,
                    SplitType.WEIGHT_ONLY,
                    null,
                  );
                if (!success && has_diagonal) {
                  fail = true;
                  break;
                }
                cutline_weight += weight;
                cutline_signature += signature;
              }
            }
          }
        }

        if (fail) {
          continue;
        }

        if (
          prev_cutline_signature == cutline_signature &&
          prev_cutline_weight == cutline_weight &&
          start_offset &&
          !collidesBoundingBox(axis, start_offset, cl.offset)
        ) {
          end_offset = cl.offset;
          pending_weight = cutline_weight;
        } else {
          if (start_offset && end_offset) {
            pendingExtendAreas.push({
              weight: pending_weight,
              start: start_offset,
              end: end_offset,
            });
          }
          end_offset = null;
          start_offset = cl.offset;
          prev_cutline_signature = cutline_signature;
          prev_cutline_weight = cutline_weight;
        }
      }

      if (start_offset && end_offset) {
        pendingExtendAreas.push({
          weight: pending_weight,
          start: start_offset,
          end: end_offset,
        });
      }

      processPendingExtendAreas(axis, pendingExtendAreas);
    }
  };

  const findLines = (svgObj: SplittedSvgData) => {
    if (!props.projectItem.approved) {
      const { boundingBoxes, cutLines } = findCutLineProposals(svgObj);
      //svgObj.DEBUG__CUTLINES = cutLines;

      findExtendAreas(svgObj, cutLines, boundingBoxes, [
        ...(svgObj.horizontalExtends.length ? [] : [AXIS.Y]),
        ...(svgObj.verticalExtends.length ? [] : [AXIS.X]),
      ]);
      svgObj.signSplitLines = cutLines
        .filter((cl) => cl.signSplit)
        .map((cl) => ({ axis: cl.axis, offset: cl.offset }));
    }
  };

  const findObjectSide = (
    axis: AXIS,
    offset: number,
    path: string,
    obj: SplittedSvgData,
  ): Side | null => {
    const boundingBox = calcPathBoundingBoxAndCutLines(
      path,
      obj.width,
      obj.height,
      obj.splittedVertically,
      obj.splittedHorizontally,
    );
    if (boundingBox) {
      switch (axis) {
        case AXIS.X:
          return boundingBox.top + (boundingBox.bottom - boundingBox.top) / 2 >
            offset
            ? Side.A
            : Side.B;
        case AXIS.Y:
          return boundingBox.left + (boundingBox.right - boundingBox.left) / 2 <
            offset
            ? Side.A
            : Side.B;
      }
    }
    return null;
  };

  const splitSVG = (
    index: number,
    axis: AXIS,
    offset: number,
    signSplitLine?: SignSplitLine,
  ) => {
    if (splittedSvgObjects[index]) {
      const splitTarget = splittedSvgObjects[index];

      const sideAPaths: Array<SVGObjects> = [];
      const sideBPaths: Array<SVGObjects> = [];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      for (const obj of splitTarget.svgObjects as any[]) {
        if (obj.name === "g") {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          for (const c of obj.children) {
            if (c.name === "path" && c.properties["d"]) {
              const forceSide: Side | null = signSplitLine
                ? findObjectSide(
                    axis,
                    signSplitLine.offset,
                    c.properties["d"],
                    splitTarget,
                  )
                : null;

              if (
                !calcSplitPath(
                  sideAPaths,
                  sideBPaths,
                  axis,
                  Math.floor(offset),
                  c.properties,
                  signSplitLine ? SplitType.OBJECT_SPLIT : SplitType.LINE_SPLIT,
                  forceSide,
                ).success
              ) {
                //Cutting not allowed... stop processing.
                return;
              }
            }
          }
        }
      }

      const splittedHorizontally =
        axis === AXIS.X ? true : splitTarget.splittedHorizontally;
      const splittedVertically =
        axis === AXIS.Y ? true : splitTarget.splittedVertically;

      const aWidth = axis === AXIS.Y ? offset : splitTarget.width;
      const aHeight = axis === AXIS.X ? offset : splitTarget.height;

      const aSplitEdges = {
        ...splitTarget.splitEdges,
        right: axis === AXIS.Y ? true : splitTarget.splitEdges.right,
        bottom: axis === AXIS.X ? true : splitTarget.splitEdges.bottom,
      };

      const aTargetSize = getTargetSize(aWidth, aHeight, aSplitEdges);

      const newSplitIndex = updateNextNodeId();

      const sideA: SplittedSvgData = {
        nodeId: splitTarget.nodeId,
        offset: splitTarget.offset,
        width: aWidth,
        height: aHeight,
        key: crypto.randomUUID(),
        horizontalExtends:
          axis === AXIS.Y
            ? splitTarget.horizontalExtends.reduce(
                (out: ExtendArea[], area) => {
                  if (area.start < offset && area.end <= offset) {
                    out.push(area);
                  } else if (area.start < offset) {
                    out.push({
                      start: area.start,
                      end: offset,
                    });
                  }
                  return out;
                },
                [],
              )
            : [...splitTarget.horizontalExtends],
        verticalExtends:
          axis === AXIS.X
            ? splitTarget.verticalExtends.reduce((out: ExtendArea[], area) => {
                if (area.start < offset && area.end <= offset) {
                  out.push(area);
                } else if (area.start < offset) {
                  out.push({
                    start: area.start,
                    end: offset,
                  });
                }
                return out;
              }, [])
            : [...splitTarget.verticalExtends],
        targetWidth: aTargetSize.width,
        targetHeight: aTargetSize.height,
        splitEdges: aSplitEdges,
        svgObjects: [{ name: "g", children: sideAPaths }],
        splittedHorizontally,
        splittedVertically,
        //DEBUG__CUTLINES: [],
        signSplitLines: [],
      };

      const bWidth =
        axis === AXIS.Y ? splitTarget.width - offset : splitTarget.width;
      const bHeight =
        axis === AXIS.X ? splitTarget.height - offset : splitTarget.height;

      const bSplitEdges = {
        ...splitTarget.splitEdges,
        left: axis === AXIS.Y ? true : splitTarget.splitEdges.left,
        top: axis === AXIS.X ? true : splitTarget.splitEdges.top,
      };

      const bTargetSize = getTargetSize(bWidth, bHeight, bSplitEdges);

      const sideB: SplittedSvgData = {
        nodeId: newSplitIndex,
        offset: {
          ...splitTarget.offset,
          ...(axis === AXIS.Y
            ? { x: splitTarget.offset.x + offset }
            : { y: splitTarget.offset.x + offset }),
        },
        width: bWidth,
        height: bHeight,
        key: crypto.randomUUID(),
        horizontalExtends:
          axis === AXIS.Y
            ? splitTarget.horizontalExtends.reduce(
                (out: ExtendArea[], area) => {
                  if (area.start >= offset && area.end > offset) {
                    out.push({
                      start: area.start - offset,
                      end: area.end - offset,
                    });
                  } else if (area.end > offset) {
                    out.push({
                      start: 0,
                      end: area.end - offset,
                    });
                  }
                  return out;
                },
                [],
              )
            : [...splitTarget.horizontalExtends],
        verticalExtends:
          axis === AXIS.X
            ? splitTarget.verticalExtends.reduce((out: ExtendArea[], area) => {
                if (area.start >= offset && area.end > offset) {
                  out.push({
                    start: area.start - offset,
                    end: area.end - offset,
                  });
                } else if (area.end > offset) {
                  out.push({
                    start: 0,
                    end: area.end - offset,
                  });
                }
                return out;
              }, [])
            : [...splitTarget.verticalExtends],
        targetWidth: bTargetSize.width,
        targetHeight: bTargetSize.height,
        splitEdges: bSplitEdges,
        svgObjects: [{ name: "g", children: sideBPaths }],
        splittedHorizontally,
        splittedVertically,
        //DEBUG__CUTLINES: [],
        signSplitLines: [],
      };

      // Update extend areas and find sign split lines.
      findLines(sideA);
      findLines(sideB);

      const out = [...splittedSvgObjects];
      out.splice(index, 1, sideA, sideB);

      if (!splitLayout(sideA, sideB, axis)) {
        return;
      }
      setSplittedSvgObjects(out);
    }
  };

  const [sizeFactor, setSizeFactor] = useState<number>(1.0);

  // Handler to call on window resize
  const handleResize = () => {
    // Set window width/height to state
    const element = document.querySelector(".editor-preview-sign");
    if (element) {
      const sizeFactor = Math.min(
        (element.clientWidth - LAYOUT_PADDING_WIDTH) /
          (props.projectItem.data?.width ?? 1),
        (element.clientHeight - LAYOUT_PADDING_HEIGHT) /
          (props.projectItem.data?.height ?? 1),
      );
      setSizeFactor(sizeFactor);
    }
  };

  useEffect(() => {
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, [props.projectItem]); // Force reset handleResize on project item change

  if (!props.projectItem.layout) {
    return <div></div>;
  }

  return (
    <div onContextMenu={onRightMouseClick} className="splitted-sign-container">
      <SplittedLayout
        key={props.projectItem.id}
        snap={props.snap}
        visibleExtendAreas={props.visibleExtendAreas}
        sizeFactor={sizeFactor}
        splittedObjects={splittedSvgObjects}
        layoutItem={props.projectItem.layout}
        editable={!props.projectItem.approved}
        productCode={props.projectItem.productCode ?? ""}
        splitAxis={splitAxis}
        ctrlPressed={ctrlPressed}
        shiftPressed={shiftPressed}
        onSplitRequest={(
          index: number,
          axis: AXIS,
          offset: number,
          signSplitLine?: SignSplitLine,
        ) => {
          splitSVG(index, axis, offset, signSplitLine);
        }}
        onExtendArea={(
          index: number,
          axis: AXIS,
          start: number,
          end: number,
          symmetric: boolean,
        ) => {
          addExtendArea(index, axis, start, end, symmetric);
        }}
      ></SplittedLayout>
    </div>
  );
}

export default SignSplitter;
