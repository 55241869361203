import React from "react";

import "./Pagination.css";

interface PaginationProps {
  page: number;
  total: number;
  limit: number;
  onPageChange: (pageNum: number) => void;
}

function Pagination(props: PaginationProps): JSX.Element {
  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">
        <li className={props.page === 0 ? "page-item disabled" : "page-item"}>
          <a
            className="page-link"
            href="#"
            aria-label="Previous"
            onClick={(e) => {
              e.preventDefault();
              props.onPageChange(props.page - 1);
            }}
          >
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        {Array(Math.ceil(props.total / props.limit) || 0)
          .fill(props.page)
          .map((m, i) => (
            <li
              key={i + 1}
              className={
                props.page + 1 === i + 1 ? "page-item disabled" : "page-item"
              }
            >
              <a
                className="page-link"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  props.onPageChange(i);
                }}
              >
                {i + 1}
              </a>
            </li>
          ))}
        <li
          className={
            (props.page + 1) * props.limit < props.total
              ? "page-item"
              : "page-item disabled"
          }
        >
          <a
            className="page-link"
            href="#"
            aria-label="Next"
            onClick={(e) => {
              e.preventDefault();
              props.onPageChange(props.page + 1);
            }}
          >
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
