import axios, { AxiosError, AxiosResponse } from "axios";

import { User, CreateUser, UpdateUser, CreateUserResponse } from "../types";

const baseUrl =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3000/api/"
    : process.env.REACT_APP_API_URL;

const getUsers = async (): Promise<User[]> => {
  const config = {
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  };

  try {
    const response = await axios.get(baseUrl + "users", config);
    return response.data;
  } catch (e) {
    return [];
  }
};

const createUser = async (data: CreateUser): Promise<CreateUserResponse> => {
  const config = {
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  };

  try {
    const user = await axios.post(baseUrl + "auth/register", data, config);
    return user;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const updateUser = async (
  id: string,
  data: UpdateUser,
): Promise<CreateUserResponse> => {
  const config = {
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
  };

  try {
    const user = await axios.patch(baseUrl + `users/${id}`, data, config);
    return user;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

export default { getUsers, createUser, updateUser };
