/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect, useCallback } from "react";
import "./ProjectItemEditor.scss";
import {
  ProjectItem,
  Project,
  MountingMethod,
  ExtraType,
  SheetType,
  PlateType,
  ProjectItemData,
  SplitData,
} from "./types";

import projectsService from "./services/projectsService";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FieldNames } from "./FieldNames";
import SignSplitter from "./SignSplitter";

interface ProjectItemEditorProps {
  project: Partial<Project> | null;
  projectItemId: string | null;
  previousPage: string;
  onMessage: (type: string, text: string) => void;
  onNavigate: (
    type: string,
    project: Project | null,
    projectItem: Partial<ProjectItem> | null,
  ) => void;
}

const getValidationSchema = () =>
  Yup.object().shape({
    plateType: Yup.string()
      .oneOf(Object.values(PlateType))
      .required("Anna valinta"),
    mountingMethod: Yup.string()
      .oneOf(Object.values(MountingMethod))
      .required("Anna valinta"),
    sheetType: Yup.string()
      .oneOf(Object.values(SheetType))
      .required("Pakollinen kenttä"),
    extraType: Yup.string()
      .oneOf(Object.values(ExtraType))
      .required("Pakollinen kenttä"),
  });

function ProjectItemEditor(props: ProjectItemEditorProps): JSX.Element {
  const [mounted, setMounted] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  const [saving, setSaving] = useState<boolean>(false);

  const [projectItem, setProjectItem] = useState<ProjectItem | null>(null);

  const [exitTimeout, setExitTimeout] = useState<number | null>(null);

  const [snap, setSnap] = useState<boolean>(true);
  const [visibleExtendAreas, setVisibleExtendAreas] = useState<boolean>(false);

  const formik = useFormik({
    //enableReinitialize: true,
    initialValues: {
      plateType: PlateType.ALUMINUM_3,
      mountingMethod: MountingMethod.METHOD_5A,
      extraType: ExtraType.ONE_SIDE,
      sheetType: SheetType.SHEET_R2,
      identifier: "",
    },
    validateOnChange: true,
    validationSchema: getValidationSchema(),
    // onSubmit: values => {
    //   alert(JSON.stringify(values, null, 2))
    // },
    onSubmit: () => {
      handleSubmit();
    },
  });

  useEffect(() => {
    setMounted(true);
    const currentIndex = !props.project
      ? -1
      : props.project.projectItems?.findIndex(
          (projectItem) => projectItem.id === props.projectItemId,
        ) ?? -1;

    if (currentIndex < 0) {
      props.onMessage(
        "danger",
        "Näkymä on avattava uusiksi projektit-näkymän kautta",
      );

      setExitTimeout(
        setTimeout(() => {
          if (mounted) {
            props.onNavigate("/projects", null, null);
          }
        }, 3000) as unknown as number,
      );
    } else {
      setCurrentIndex(currentIndex);
    }

    return () => {
      if (exitTimeout) {
        clearTimeout(exitTimeout);
        setExitTimeout(null);
      }
      setMounted(false);
    };
  }, [mounted]);

  useEffect(() => {
    if (currentIndex >= 0) {
      const projectItem =
        props.project?.projectItems &&
        props.project?.projectItems[currentIndex];

      if (projectItem) {
        setProjectItem(projectItem);
      }
    }
  }, [currentIndex]);

  useEffect(() => {
    if (projectItem) {
      formik.resetForm({
        values: {
          extraType: ExtraType.ONE_SIDE,
          mountingMethod:
            projectItem.data.mountingMethod ?? MountingMethod.METHOD_5A,
          plateType: projectItem.data.plateType ?? PlateType.ALUMINUM_3,
          sheetType: projectItem.data.sheetType ?? SheetType.SHEET_R2,
          identifier: projectItem.data.identifier ?? "",
        },
      });
    }
  }, [projectItem]);

  const handleReset = useCallback(() => {
    if (projectItem) {
      const resetted = { ...projectItem };
      resetted.splitData = [];
      resetted.divided = false;
      resetted.approved = false;
      if (props.project?.projectItems && currentIndex >= 0) {
        props.project.projectItems[currentIndex] = {
          ...resetted,
        };
      }
      setSaving(false);
      setProjectItem(resetted);
    }
  }, [projectItem, setProjectItem]);

  const handleSubmit = () => {
    setSaving(true);

    if (projectItem) {
      const nextIndex =
        !projectItem.approved &&
        currentIndex + 1 != props.project?.projectItems?.length &&
        currentIndex + 1;

      const updatedData: ProjectItemData = {
        ...projectItem.data,
        plateType: formik.values.plateType,
        mountingMethod: formik.values.mountingMethod,
        extraType: formik.values.extraType,
        sheetType: formik.values.sheetType,
        identifier: formik.values.identifier ?? "",
      };

      const splittedSVGs = document.querySelectorAll(".splitter-svg-component");
      const splitData: SplitData[] = [];
      for (const splittedSVG of splittedSVGs) {
        const plateWidth = splittedSVG.getAttribute("data-plate-width");
        const plateHeight = splittedSVG.getAttribute("data-plate-height");
        const mountingMethod = splittedSVG.getAttribute("data-mounting-method");

        // Clean up overlay items...
        splittedSVG.querySelectorAll(".svg-overlay").forEach((item) => {
          item.remove();
        });

        const svg = splittedSVG.outerHTML;

        if (
          plateHeight == null ||
          plateWidth == null ||
          mountingMethod == null ||
          !svg.length
        ) {
          setSaving(false);
          return;
        }

        splitData.push({
          width: Math.round(Number(plateWidth)),
          height: Math.round(Number(plateHeight)),
          mountingMethod: mountingMethod as MountingMethod,
          svg,
        });
      }
      projectItem.splitData = splitData;

      projectsService
        .approveProjectItem(
          projectItem.id,
          updatedData,
          projectItem.splitData,
          projectItem.layout,
          true,
        )
        .then((response) => {
          if (response.status === 200) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const updatedProjectItem = response.data as ProjectItem;
            if (props.project?.projectItems && currentIndex >= 0) {
              props.project.projectItems[currentIndex] = {
                ...projectItem,
                ...updatedProjectItem,
              };
            }

            setProjectItem(updatedProjectItem);
          } else {
            props.onMessage("danger", "Virhe kylttiä päivittäessä");
          }
        })
        .finally(() => {
          setSaving(false);
          if (nextIndex) {
            setCurrentIndex(nextIndex);
          }
        });
    }
  };

  const handleSelectChange = (
    type: string,
    e: React.FormEvent<HTMLSelectElement>,
  ): void => {
    const name = (e.target as HTMLSelectElement)?.name as string;
    formik.setFieldValue(
      name,
      type == "string"
        ? e.currentTarget.value
        : parseInt(e.currentTarget.value, 10),
    );
  };

  return (
    <>
      <div className="editor">
        <div className="editor-sidebar">
          <form id="editor-form" onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <label htmlFor="identifier" className="form-label">
                Tunniste
              </label>
              <input
                disabled={false}
                type="text"
                className="form-control"
                id="identifier"
                name="identifier"
                onChange={(e) => formik.handleChange(e)}
                value={formik.values.identifier}
              />
              {formik.errors.identifier && formik.touched.identifier ? (
                <div className="text-danger">{formik.errors.identifier}</div>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="plateType" className="form-label">
                Taustamateriaali
              </label>
              <select
                disabled={true}
                className="form-select"
                id="plateType"
                name="plateType"
                onChange={(e) => handleSelectChange("string", e)}
                value={formik.values.plateType}
              >
                <option key={0} value={""}>
                  Valitse
                </option>
                {Object.values(PlateType).map((plate, i) => (
                  <option key={i} value={plate}>
                    {FieldNames.plateType[plate]}
                  </option>
                ))}
              </select>
              {formik.errors.plateType && formik.touched.plateType ? (
                <div className="text-danger">{formik.errors.plateType}</div>
              ) : null}
            </div>
            {/* <div className="mb-3">
              <label htmlFor="mountingMethod" className="form-label">
                Kiinnitys
              </label>
              <select
                className="form-select"
                id="mountingMethod"
                name="mountingMethod"
                onChange={(e) => handleSelectChange("string", e)}
                value={formik.values.mountingMethod}
              >
                <option key={0} value={""}>
                  Valitse
                </option>
                {Object.values(MountingMethod).map((mount, i) => (
                  <option key={i} value={mount}>
                    {FieldNames.mountingMethod[mount]}
                  </option>
                ))}
              </select>
              {formik.errors.mountingMethod && formik.touched.mountingMethod ? (
                <div className="text-danger">
                  {formik.errors.mountingMethod}
                </div>
              ) : null}
            </div>
            <div className="mb-3">
              <label htmlFor="extraType" className="form-label">
                Lisämääritys
              </label>
              <select
                className="form-select"
                id="extraType"
                name="extraType"
                onChange={(e) => handleSelectChange("string", e)}
                value={formik.values.extraType}
              >
                <option key={0} value={""}>
                  Valitse
                </option>
                {Object.values(ExtraType).map((et, i) => (
                  <option key={i} value={et}>
                    {FieldNames.extraType[et]}
                  </option>
                ))}
              </select>
              {formik.errors.extraType && formik.touched.extraType ? (
                <div className="text-danger">{formik.errors.extraType}</div>
              ) : null}
            </div> */}

            <div className="mb-3">
              <label htmlFor="sheetType" className="form-label">
                Heijastusluokka
              </label>
              <select
                className="form-select"
                id="sheetType"
                name="sheetType"
                onChange={(e) => handleSelectChange("string", e)}
                value={formik.values.sheetType}
              >
                <option key={0} value={""}>
                  Valitse
                </option>
                {Object.values(SheetType).map((et, i) => (
                  <option key={i} value={et}>
                    {et}
                  </option>
                ))}
              </select>
              {formik.errors.sheetType && formik.touched.sheetType ? (
                <div className="text-danger">{formik.errors.sheetType}</div>
              ) : null}
            </div>
          </form>
          <div className="instructions">
            <strong>Hiiren oikea nappi:</strong> suunnanvaihto
          </div>
          <div className="instructions">
            <strong>Hiiren vasen nappi:</strong> leikkaus
          </div>
          <div className="instructions">
            <strong>Ctrl + Piirto hiirellä:</strong> venytysalue
          </div>
          <div className="instructions">
            <strong>Ctrl + Vaihto + Piirto hiirellä:</strong> symmetrinen
            venytysalue
          </div>
          <div className="instructions">
            Venytysalue poistetaan klikkaamalla Ctrl pohjassa venytysalueen
            sisällä.
          </div>
        </div>

        <div className="editor-preview">
          <div className="editor-preview-sign">
            {projectItem && (
              <SignSplitter
                key={projectItem.id}
                className="svg-container"
                projectItem={projectItem}
                snap={snap}
                visibleExtendAreas={visibleExtendAreas}
              />
            )}
          </div>
          {/* <div className="editor-sign-info">{`Koko: ${projectItem?.data.size}, Kuvan leveys: ${projectItem?.data.width}, Kuvan korkeus: ${projectItem?.data.height}, Tuote: ${projectItem?.productCode}`}</div> */}
          <div className="editor-sign-info">{`Kyltin leveys: ${projectItem?.data.width}mm, Kyltin korkeus: ${projectItem?.data.height}mm, Tuotekoodi: ${projectItem?.productCode}`}</div>
          <div className="editor-actions">
            <div className="editor-preview-action-buttons">
              <div className="action-toggle">
                <div className="form-check form-switch">
                  <input
                    disabled={saving || projectItem?.approved}
                    className="form-check-input"
                    type="checkbox"
                    id="visibleExtendAreasToggle"
                    checked={visibleExtendAreas}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setVisibleExtendAreas(
                        (event.target as HTMLInputElement).checked,
                      );
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="visibleExtendAreasToggle"
                  >
                    Laajennusalueet
                  </label>
                </div>
              </div>
              <div className="action-toggle">
                <div className="form-check form-switch">
                  <input
                    disabled={saving || projectItem?.approved}
                    className="form-check-input"
                    type="checkbox"
                    id="snapToggle"
                    checked={snap}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setSnap((event.target as HTMLInputElement).checked);
                    }}
                  />
                  <label className="form-check-label" htmlFor="snapToggle">
                    Snap
                  </label>
                </div>
              </div>
              <div className="action-button">
                <button
                  type="button"
                  className="btn btn-kaskea-inverse bold uppercase"
                  disabled={saving}
                  onClick={() =>
                    props.onNavigate(
                      props.previousPage,
                      props.project as Project,
                      null,
                    )
                  }
                >
                  Sulje
                </button>
              </div>
              <div className="action-button">
                <button
                  type="button"
                  disabled={saving}
                  className="btn btn-kaskea-inverse bold uppercase"
                  onClick={() =>
                    setCurrentIndex(
                      ((props.project?.projectItems?.length || 0) +
                        currentIndex -
                        1) %
                        (props.project?.projectItems?.length || 0),
                    )
                  }
                >
                  Edellinen
                </button>
              </div>
              <div className="action-button">
                <button
                  type="button"
                  disabled={true}
                  className="btn disabled bold uppercase"
                >
                  {currentIndex + 1} /{" "}
                  {props.project?.projectItems?.length || 0}
                </button>
              </div>
              <div className="action-button">
                <button
                  type="button"
                  disabled={saving}
                  className="btn btn-kaskea-inverse bold uppercase"
                  onClick={() =>
                    setCurrentIndex(
                      (currentIndex + 1) %
                        (props.project?.projectItems?.length || 0),
                    )
                  }
                >
                  Seuraava
                </button>
              </div>
              {projectItem && (
                <div className="action-button">
                  <button
                    type="button"
                    onClick={handleReset}
                    disabled={saving}
                    form="editor-form"
                    className="btn btn-warning btn-kaskea bold uppercase"
                  >
                    Reset
                  </button>
                </div>
              )}
              {projectItem && projectItem.approved && !formik.dirty && (
                <div className="action-button">
                  <button
                    type="submit"
                    disabled={true}
                    form="editor-form"
                    className="btn btn-primary btn-kaskea bold uppercase"
                  >
                    Hyväksytty
                  </button>
                </div>
              )}
              {projectItem && (!projectItem.approved || formik.dirty) && (
                <div className="action-button">
                  <button
                    type="submit"
                    disabled={saving}
                    form="editor-form"
                    className="btn btn-primary btn-kaskea bold uppercase"
                  >
                    {projectItem.approved ? "Päivitä" : "Hyväksy"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectItemEditor;
