import svgpath from "svgpath";
import React, { useRef, useState, useEffect, useCallback } from "react";
import { SHEET_HEIGHT_REDUCTION } from "./utils/splitCalculator";
import {
  AXIS,
  SplittedSvgData,
  parseSegment,
  calculatePoint,
  getTargetSize,
  genSplitEdgesString,
  SignSplitLine,
} from "./utils/svgUtils";

const HEIGHT_MAX = 1200; // sticker sheet max
const WIDTH_MAX = 3000; // metal plate max

type SplittedSignProps = {
  snap: boolean;
  visibleExtendAreas: boolean;
  editable: boolean;
  splitAxis: AXIS;
  ctrlPressed: boolean;
  shiftPressed: boolean;
  sizeFactor: number;
  obj: SplittedSvgData;
  productCode: string;
  onSplitRequest: (
    splitAxis: AXIS,
    offset: number,
    signSplitLine?: SignSplitLine,
  ) => void;
  onExtendArea: (
    expandExis: AXIS,
    start: number,
    end: number,
    symmetric: boolean,
  ) => void;
};

type TargetSize = {
  plate_width: number;
  plate_height: number;
  sheet_width: number;
  sheet_height: number;
};

type Line = {
  line: SVGElement;
  title_a: SVGElement;
  title_b: SVGElement;
  title_a_text: Text;
  title_b_text: Text;
};

const SIGN_SPLIT_LINE_THRESHOLD = 16;

function SplittedSign(props: SplittedSignProps): JSX.Element {
  const svgEle = useRef<SVGSVGElement>(null);
  const [showLine, setShowLine] = useState<boolean>(false);
  const [lines, setLines] = useState<Line[] | null>(null);
  const [linePos, setLinePos] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const [expandStartPoint, setExpandStartPoint] = useState<number | null>(null);
  const [mountingMethod, setMountingMethod] = useState<string>("");
  const [targetSize, setTargetSize] = useState<TargetSize>();
  const [signSplitLine, setSignSplitLine] =
    useState<SignSplitLine | undefined>(undefined);

  useEffect(() => {
    const splitEdges = props.obj.splitEdges;
    let mountingMethod = "5B";
    if (props.productCode.startsWith("F14")) {
      if (!splitEdges.left && !splitEdges.right) {
        mountingMethod = "5D";
      } else if (splitEdges.left && splitEdges.right) {
        mountingMethod = "5C";
      } else if (splitEdges.left) {
        mountingMethod = "5E";
      }
    } else {
      if (!splitEdges.left && !splitEdges.right) {
        mountingMethod = "5A";
      } else if (splitEdges.left && splitEdges.right) {
        mountingMethod = "5C";
      }
    }
    setMountingMethod(mountingMethod);
  }, [props.productCode, props.obj.splitEdges]);

  // const buildTitle = () => {
  //   return `${props.obj.targetWidth}x${props.obj.targetHeight} ${mountingMethod}`;
  // };

  const appendSVGChild = (
    elementType: string,
    target: HTMLElement | SVGElement,
    attributes: Record<string, unknown> = {},
    targetSize: TargetSize,
    text = "",
  ) => {
    const element: SVGElement = document.createElementNS(
      "http://www.w3.org/2000/svg",
      elementType,
    );
    Object.entries(attributes).map((a) => {
      if (a[0] === "d") {
        element.setAttribute(
          a[0],
          svgpath(a[1] as string)
            .iterate((segment) => {
              return [
                parseSegment(
                  segment,
                  0,
                  0,
                  props.obj.width,
                  props.obj.height,
                  false,
                  targetSize.sheet_width,
                  targetSize.sheet_height,
                  props.obj.horizontalExtends,
                  props.obj.verticalExtends,
                ),
              ];
            })
            .toString(),
        );
      } else {
        element.setAttribute(a[0], a[1] as string);
      }
    });
    if (text) {
      const textNode = document.createTextNode(text);
      element.appendChild(textNode);
    }

    target.appendChild(element);
    return element;
  };

  useEffect(() => {
    return setTargetSize(
      props.ctrlPressed || !props.editable
        ? {
            plate_width: props.obj.width,
            plate_height: props.obj.height,
            sheet_width: props.obj.width,
            sheet_height: props.obj.height,
          }
        : {
            plate_width: props.obj.targetWidth,
            plate_height: props.obj.targetHeight,
            sheet_width: props.obj.targetWidth,
            sheet_height: props.obj.targetHeight - SHEET_HEIGHT_REDUCTION,
          },
    );
  }, [
    props.ctrlPressed,
    props.editable,
    props.obj.width,
    props.obj.height,
    props.obj.targetWidth,
    props.obj.targetHeight,
  ]);

  useEffect(() => {
    if (props.obj.svgObjects && targetSize && svgEle.current) {
      const svg = svgEle.current;

      // first clean the svg element
      removeAllChildNodes(svg);

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      props.obj.svgObjects.forEach((obj: any) => {
        if (obj.name === "g") {
          const group = appendSVGChild(
            obj.name,
            svg,
            obj.properties,
            targetSize,
          );

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          obj.children.forEach((c: any) => {
            appendSVGChild(c.name, group, c.properties, targetSize);
          });
        } else {
          console.error(
            "Flat svg cannot be processed, must have group inside.",
          );

          appendSVGChild(obj.name, svg, obj.properties, targetSize);
        }
      });

      if (props.editable) {
        appendLine(svg, props.ctrlPressed && props.shiftPressed ? 2 : 1);

        if (props.ctrlPressed || props.visibleExtendAreas) {
          appendExtends(svg);
        }

        /*if (props.ctrlPressed) {
          for (const cutline of props.obj.signSplitLines) {
            const cl = cutline as CutLine;
            const line_element: SVGElement = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "line",
            );
            const color = Math.floor(Math.random() * 255).toString(16);
            line_element.setAttribute("stroke", `#${color}${color}${color}`);
            line_element.setAttribute(
              "stroke-width",
              `${1 / props.sizeFactor}mm`,
            );
            line_element.setAttribute("stroke-dasharray", "5,5");

            if (cl.axis === AXIS.X) {
              line_element.setAttribute("x1", (0).toString());
              line_element.setAttribute(
                "x2",
                targetSize.sheet_width.toString(),
              );
              line_element.setAttribute("y1", cl.offset.toString());
              line_element.setAttribute("y2", cl.offset.toString());
            } else {
              line_element.setAttribute("x1", cl.offset.toString());
              line_element.setAttribute("x2", cl.offset.toString());
              line_element.setAttribute("y1", (0).toString());
              line_element.setAttribute(
                "y2",
                targetSize.sheet_height.toString(),
              );
            }

            line_element.classList.add("svg-overlay");

            svg.appendChild(line_element);
          }
        }*/

        /*if (props.ctrlPressed) {
          for (const cutline of props.obj.DEBUG__CUTLINES) {
            const cl = cutline as CutLine;
            const line_element: SVGElement = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "line",
            );

            line_element.setAttribute("stroke", "#000000");
            line_element.setAttribute(
              "stroke-width",
              `${0.2 / props.sizeFactor}mm`,
            );
            line_element.setAttribute("stroke-dasharray", "5,5");

            if (cl.axis === AXIS.X) {
              line_element.setAttribute("x1", (0).toString());
              line_element.setAttribute(
                "x2",
                targetSize.sheet_width.toString(),
              );
              line_element.setAttribute("y1", cl.offset.toString());
              line_element.setAttribute("y2", cl.offset.toString());
            } else {
              line_element.setAttribute("x1", cl.offset.toString());
              line_element.setAttribute("x2", cl.offset.toString());
              line_element.setAttribute("y1", (0).toString());
              line_element.setAttribute(
                "y2",
                targetSize.sheet_height.toString(),
              );
            }

            line_element.classList.add("svg-overlay");

            svg.appendChild(line_element);
          }
        }*/
      }
    }
  }, [
    props.obj.svgObjects,
    props.ctrlPressed,
    props.shiftPressed,
    targetSize,
    props.sizeFactor,
    props.visibleExtendAreas,
  ]);

  const getTextElement = () => {
    const element: SVGElement = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "text",
    );
    element.setAttribute("font-size", `${12 / props.sizeFactor}px`);
    element.classList.add("splitter-text", "svg-overlay");
    const textNode = document.createTextNode("");
    element.appendChild(textNode);
    return { element, textNode };
  };

  const appendLine = (target: HTMLElement | SVGElement, amount: number) => {
    const elements: Line[] = [];
    for (let i = 0; i < amount; i++) {
      const line_element: SVGElement = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "line",
      );

      line_element.setAttribute("stroke", props.ctrlPressed ? "#fff" : "#f00");
      line_element.setAttribute("stroke-width", `${1 / props.sizeFactor}mm`);
      line_element.classList.add("splitter-line", "svg-overlay");
      target.appendChild(line_element);

      const title_a = getTextElement();
      target.appendChild(title_a.element);
      const title_b = getTextElement();
      target.appendChild(title_b.element);

      elements.push({
        line: line_element,
        title_a: title_a.element,
        title_b: title_b.element,
        title_a_text: title_a.textNode,
        title_b_text: title_b.textNode,
      });
    }
    setLines(elements);
  };

  const appendExtend = (
    target: HTMLElement | SVGElement,
    x: number,
    y: number,
    width: number,
    height: number,
  ) => {
    const element: SVGElement = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "rect",
    );
    element.setAttribute("class", "svg-overlay");
    element.setAttribute("x", x.toString());
    element.setAttribute("width", Math.max(1, width).toString());
    element.setAttribute("y", y.toString());
    element.setAttribute("height", Math.max(1, height).toString());
    element.setAttribute("fill", "#f00");
    element.setAttribute("fill-opacity", "0.4");
    element.setAttribute("stroke", "#f00");
    element.setAttribute("stroke-dasharray", "2 1");
    target.appendChild(element);
  };

  const appendExtends = (target: HTMLElement | SVGElement) => {
    if (!targetSize) return;
    for (const he of props.obj.horizontalExtends) {
      const start = calculatePoint(
        AXIS.X,
        he.start,
        props.obj.width,
        false,
        false,
        targetSize.sheet_width,
        props.obj.horizontalExtends,
      );
      const end = calculatePoint(
        AXIS.X,
        he.end,
        props.obj.width,
        false,
        false,
        targetSize.sheet_width,
        props.obj.horizontalExtends,
      );

      appendExtend(target, start, 0, end - start, targetSize.sheet_height);
    }

    for (const he of props.obj.verticalExtends) {
      const start = calculatePoint(
        AXIS.Y,
        he.start,
        props.obj.height,
        false,
        false,
        targetSize.sheet_height,
        props.obj.verticalExtends,
      );
      const end = calculatePoint(
        AXIS.Y,
        he.end,
        props.obj.height,
        false,
        false,
        targetSize.sheet_height,
        props.obj.verticalExtends,
      );

      appendExtend(target, 0, start, targetSize.sheet_width, end - start);
    }
  };

  const removeAllChildNodes = (parent: SVGSVGElement) => {
    while (parent.firstChild) {
      parent.removeChild(parent.firstChild);
    }
  };

  const handleMouseVisibility = (visible: boolean) => {
    if (!props.editable) return;
    setShowLine(visible);
    // if (lines) {
    //   for (const line of lines) {
    //     line.setAttribute("display", visible ? "visible" : "none");
    //   }
    // }
  };

  const checkEdgeSnaps = useCallback(
    (
      x: number,
      y: number,
    ): { edgeOffsetX: number | null; edgeOffsetY: number | null } => {
      for (const sl of props.obj.signSplitLines) {
        switch (props.splitAxis) {
          case AXIS.X:
            if (Math.abs(sl.offset - y) < SIGN_SPLIT_LINE_THRESHOLD) {
              setSignSplitLine(sl);
              return { edgeOffsetX: null, edgeOffsetY: sl.offset };
            }

            break;
          case AXIS.Y:
            if (Math.abs(sl.offset - x) < SIGN_SPLIT_LINE_THRESHOLD) {
              setSignSplitLine(sl);
              return { edgeOffsetX: sl.offset, edgeOffsetY: null };
            }
            break;
        }
      }
      setSignSplitLine(undefined);
      return { edgeOffsetX: null, edgeOffsetY: null };
    },
    [props.splitAxis, props.obj.signSplitLines],
  );

  const handleMouseMove = (
    event: React.MouseEvent<SVGSVGElement, globalThis.MouseEvent>,
  ) => {
    if (!props.editable) return;
    const rect = svgEle.current?.querySelector("g")?.getBoundingClientRect();
    if (rect) {
      setShowLine(true);
      const widthFactor = props.obj.width / rect.width;
      const heightFactor = props.obj.height / rect.height;
      let offsetX = Math.min(
        props.obj.width,
        Math.max(0, (event.clientX - (rect?.left ?? 0)) * widthFactor),
      );
      let offsetY = Math.min(
        props.obj.height,
        Math.max(0, (event.clientY - (rect?.top ?? 0)) * heightFactor),
      );

      if (props.snap && !props.ctrlPressed) {
        const { edgeOffsetX, edgeOffsetY } = checkEdgeSnaps(offsetX, offsetY);

        const targetSize = getTargetSize(offsetX, offsetY, {
          ...props.obj.splitEdges,
          right: true,
        });
        offsetX = edgeOffsetX ?? targetSize.width;
        offsetY = edgeOffsetY ?? targetSize.height;
      }

      if (linePos.x != offsetX || linePos.y != offsetY) {
        setLinePos({ x: offsetX, y: offsetY });
      }
    } else {
      setShowLine(false);
    }
  };

  const handleMouseDown = (
    event: React.MouseEvent<SVGSVGElement, globalThis.MouseEvent>,
  ) => {
    if (props.editable && event.button === 0 && props.ctrlPressed) {
      switch (props.splitAxis) {
        case AXIS.X:
          setExpandStartPoint(linePos.y ?? 0);
          break;
        case AXIS.Y:
          setExpandStartPoint(linePos.x ?? 0);
          break;
      }
    }
  };

  const handleMouseUp = (
    event: React.MouseEvent<SVGSVGElement, globalThis.MouseEvent>,
  ) => {
    if (
      props.editable &&
      event.button === 0 &&
      props.ctrlPressed &&
      expandStartPoint != null
    ) {
      switch (props.splitAxis) {
        case AXIS.X:
          {
            const start = Math.min(expandStartPoint, linePos.y ?? 0);
            const end = Math.max(expandStartPoint, linePos.y ?? 0);
            props.onExtendArea(props.splitAxis, start, end, props.shiftPressed);
          }
          break;
        case AXIS.Y:
          {
            const start = Math.min(expandStartPoint, linePos.x ?? 0);
            const end = Math.max(expandStartPoint, linePos.x ?? 0);
            props.onExtendArea(props.splitAxis, start, end, props.shiftPressed);
          }
          break;
      }
      setExpandStartPoint(null);
      event.preventDefault();
    }
  };

  const handleMouseClick = () => {
    if (props.ctrlPressed || !props.editable) {
      return;
    }
    props.onSplitRequest(
      props.splitAxis,
      props.splitAxis === AXIS.X ? linePos.y : linePos.x,
      signSplitLine,
    );
  };

  useEffect(() => {
    if (!props.ctrlPressed && expandStartPoint != null) {
      setExpandStartPoint(null);
    }
  }, [props.ctrlPressed]);

  useEffect(() => {
    if (lines && targetSize) {
      for (const [index, line] of lines.entries()) {
        line.line.setAttribute("stroke", props.ctrlPressed ? "#fff" : "#f00");
        switch (props.splitAxis) {
          case AXIS.X: {
            const linePosY =
              (index == 0 ? linePos.y : targetSize.sheet_height - linePos.y) ??
              0;
            const offsetY = calculatePoint(
              AXIS.Y,
              linePosY,
              props.obj.height,
              false,
              false,
              targetSize.sheet_height,
              props.obj.verticalExtends,
            );
            line.line.setAttribute("x1", (0).toString());
            line.line.setAttribute("x2", targetSize.sheet_width.toString());
            line.line.setAttribute("y1", offsetY.toString());
            line.line.setAttribute("y2", offsetY.toString());

            line.title_a.setAttribute("x", (15 / props.sizeFactor).toString());
            line.title_a.setAttribute(
              "y",
              (offsetY - 7 / props.sizeFactor).toString(),
            );
            line.title_a.setAttribute("text-anchor", "start");
            line.title_b.setAttribute("x", (15 / props.sizeFactor).toString());
            line.title_b.setAttribute(
              "y",
              (offsetY + 13 / props.sizeFactor).toString(),
            );
            line.title_a_text.textContent = linePosY.toFixed();
            line.title_b_text.textContent = (
              props.obj.height - linePosY
            ).toFixed();
            break;
          }
          case AXIS.Y: {
            const linePosX =
              (index == 0 ? linePos.x : targetSize.sheet_width - linePos.x) ??
              0;
            const offsetX = calculatePoint(
              AXIS.X,
              linePosX,
              props.obj.width,
              false,
              false,
              targetSize.sheet_width,
              props.obj.horizontalExtends,
            );
            line.line.setAttribute("x1", offsetX.toString());
            line.line.setAttribute("x2", offsetX.toString());
            line.line.setAttribute("y1", (0).toString());
            line.line.setAttribute("y2", targetSize.sheet_height.toString());

            line.title_a.setAttribute(
              "x",
              (offsetX - 50 * props.sizeFactor).toString(),
            );
            line.title_a.setAttribute("y", (10 / props.sizeFactor).toString());
            line.title_a.setAttribute("text-anchor", "end");
            line.title_b.setAttribute(
              "x",
              (offsetX + 50 * props.sizeFactor).toString(),
            );
            line.title_b.setAttribute("y", (10 / props.sizeFactor).toString());

            line.title_a_text.textContent = linePosX.toFixed();
            line.title_b_text.textContent = (
              props.obj.width - linePosX
            ).toFixed();
            break;
          }
        }
        line.line.setAttribute("visibility", showLine ? "visible" : "hidden");
        line.title_a.setAttribute(
          "visibility",
          showLine ? "visible" : "hidden",
        );
        line.title_b.setAttribute(
          "visibility",
          showLine ? "visible" : "hidden",
        );
      }
    }
  }, [
    linePos,
    showLine,
    lines,
    targetSize,
    props.ctrlPressed,
    props.splitAxis,
  ]);

  const getErrorClass = (targetSize: TargetSize) => {
    if (
      targetSize.sheet_height > HEIGHT_MAX ||
      targetSize.sheet_width > WIDTH_MAX ||
      (props.obj.height != targetSize.sheet_height &&
        !props.obj.horizontalExtends.length) ||
      (props.obj.width != targetSize.sheet_width &&
        !props.obj.verticalExtends.length)
    ) {
      return "svg-size-error";
    }
    return "";
  };

  return targetSize ? (
    <svg
      className={`splitter-svg-component ${getErrorClass(targetSize)}`}
      viewBox={`0 0 ${targetSize.sheet_width} ${targetSize.sheet_height}`}
      width={targetSize.sheet_width + "mm"}
      height={targetSize.sheet_height + "mm"}
      data-plate-width={targetSize.plate_width}
      data-plate-height={targetSize.plate_height}
      data-mounting-method={mountingMethod}
      data-split-edges={genSplitEdgesString(props.obj.splitEdges)}
      data-split-id={props.obj.nodeId}
      onMouseEnter={() => handleMouseVisibility(true)}
      onMouseLeave={() => handleMouseVisibility(false)}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onClick={handleMouseClick}
      ref={svgEle}
    />
  ) : (
    <></>
  );
}

export default SplittedSign;
