import { Modal } from "bootstrap";
import React from "react";

export type User = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  password?: string | undefined;
  passwordConfirmation?: string;
  role: string;
  active: boolean;
};

export interface CreateUser {
  email: string;
  firstname: string;
  lastname: string;
  active: boolean;
  role: string;
  password: string;
}

export interface UpdateUser {
  firstname: string;
  lastname: string;
  email: string;
  role: string;
  active: boolean;
}

export interface CreateUserResponse {
  data: string;
  status: number;
}

export interface UpdateUserPassword {
  id: string;
  password: string;
  password_confirmation: string;
}

export interface OrderItemTexts {
  teksti1: string;
}

export enum SignSource {
  WEB = "web",
  NOVAPOINT = "novapoint",
}

export interface ItemData {
  id?: string;
  identifier: string;
  colorPreset: string;
  width: number;
  height: number;
  sheetType: SheetType;
  mountingMethod: MountingMethod;
  plateType: PlateType;
  extraType: ExtraType;
  backSide: boolean;
  texts: ItemTextMapping[];
}

export interface ItemTextMapping {
  id: string;
  text: string;
  referenceText?: string;
  fontFamily: string;
  fontSize: number;
}

export interface OrderItemRequest {
  identifier: string;
  orderId: string;
  templateId: string;
  data: ItemData;
}

export interface OrderItem {
  id: string;
  identifier: string;
  remoteId?: number;
  productCode?: string;
  orderId: string;
  templateId: string;
  data: ItemData;
  source: SignSource;
}

export interface ListOrderItem {
  id: string;
  identifier: string;
  remoteId?: number;
  productCode?: string;
  templateId: string;
  twoSided: boolean;
  source: SignSource;
  sheetAmount: number;
}

export interface CreatedBy {
  firstname: string;
  lastname: string;
}

export interface Order {
  id: string;
  remoteId: string;
  description: string;
  reference: string;
  createdBy: CreatedBy;
  orderItems: ListOrderItem[];
  createdAt: string;
  remoteConfirmed: boolean; //If remote is confimed, order should not be edited anymore on this system
}

export interface TemplateStub {
  id: string;
  name: string;
  properties: TemplateProperties;
  template: string;
  backTemplate?: string;
  createdBy: CreatedBy;
}

export interface Template extends TemplateStub {
  templatePresets: ItemData[];
  createdAt: string;
  updatedAt: string;
}

export interface CreateTemplatePresetRequest {
  name: string;
  templateId: string;
  data: Omit<ItemData, "identifier">;
}

export interface UpdateTemplatePresetRequest {
  name: string;
  data: Omit<ItemData, "identifier">;
}

export interface TemplatePreset {
  id: string;
  name: string;
  templateId: string;
  data: ItemData;
  createdBy: CreatedBy;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateOrder {
  reference?: string;
  description?: string;
}

export type UpdateOrder = CreateOrder;

export interface ImportOrder {
  remoteId: number;
}

interface SizeConstraints {
  type: SizeConstraintType;
  condition: SizeConstraintConditionalBy;
  crossAxisMin?: number;
  crossAxisMax?: number;
  min?: number;
  max?: number;
  steps?: number[];
}

export interface ExtendArea {
  start: number;
  end: number;
}

interface ColorPreset {
  name: string;
  patternString: string;
  textColor: string;
  map: { [key: string]: string };
}

export interface TemplateModal {
  id?: string;
  modal: Modal | null;
  template: Template | null;
  onCancel: () => void;
  onUpdate: () => void;
  onMessage: (type: string, text: string) => void;
  onAddTemplate: () => void;
  ref: React.Ref<HTMLInputElement>;
}

export interface TemplateProperties {
  productPattern: string;
  productType: ProductType;
  horizontalSizes: SizeConstraints[];
  verticalSizes: SizeConstraints[];
  horizontalExtends: ExtendArea[];
  verticalExtends: ExtendArea[];
  mountingMethods: MountingMethod[];
  plateTypes: PlateType[];
  extraTypes: ExtraType[];
  coloringMode: ColoringMode;
  colorPresets: ColorPreset[];
  sheetTypes: SheetType[];
  defaultFontSize: number;
  defaultFont: string;
  texts: TextMapping[];
  previewWidth?: number;
  previewHeight?: number;
}

export interface TextMapping {
  id: string;
  side: TextSide;
  x?: number;
  y?: number;
  anchorId?: string;
  anchorX?: TextAnchorX;
  anchorY?: TextAnchorY;
  anchorXOffset?: number;
  anchorYOffset?: number;
  leftLimit?: number;
  rightLimit?: number;
  horizontalTextGapLimit?: number;
  verticalTextGapLimit?: number;
  topLimit?: number;
  bottomLimit?: number;
  horizontalAlign: TextHorizontalAlign;
  verticalAlign: TextVerticalAlign;
  textAdjust?: TextHorizontalAlign;
  allowSizeReference?: boolean;
  fonts: FontFamily[];
  fontSizes: number[];
  maxLines: number;
  placeHolder: string;
}

export interface CreateTemplateRequest {
  name: string;
  template: string;
  backTemplate: string | null | undefined;
  properties: TemplateProperties | string;
}

// export interface CreateOrderItem {
//   identifier: string
//   orderId: string
//   templateId: string
//   data: OrderItem
// }

export type InitialStateType = {
  isAuthenticated: boolean;
  user: User | null;
};

export interface ActionLogin {
  type: string;
  user: User | null;
}

export interface ActionLogout {
  type: string;
  user: User | null;
}

export type Action = ActionLogin | ActionLogout;

export interface UserModal {
  id?: string;
  modal: Modal | null;
  user: User | null;
  onSetUser: () => void;
  onMessage: (type: string, text: string) => void;
}

export interface PasswordModal {
  user: User | null;
  modal: Modal | null;
  onMessage: (type: string, text: string) => void;
}

export enum SizeConstraintType {
  STEPS = "steps",
  RANGE = "range",
}

export enum SizeConstraintConditionalBy {
  NONE = "none",
  CROSS_AXIS = "cross_axis",
}

export interface SvgNode extends Node {
  tagName: string;
}

// Using finnish cos don't really have translations for these
export enum MountingMethod {
  METHOD_1A = "1A", // 55 rei'itys yksi kiinnike kalvoa läpäisemätön R40
  METHOD_1B = "1B", // 55 rei'itys yksi kiinnike kalvoa läpäisemätön R30
  METHOD_2A = "2A", // 55 rei'itys kaksi kiinnikettä kalvoa läpäisemätön R40
  METHOD_2B = "2B", // 55 rei'itys kaksi kiinnikettä kalvoa läpäisemätön R30
  METHOD_3A = "3A", // 55 rei'itys yksi kiinnike kalvon läpäisevä R40
  METHOD_3B = "3B", // 55 rei'itys yksi kiinnike kalvon läpäisevä R30
  METHOD_4A = "4A", // 55 rei'itys kaksi kiinnikettä kalvon läpäisevä R40
  METHOD_4B = "4B", // 55 rei'itys kaksi kiinnikettä kalvon läpäisevä R30
  METHOD_5A = "5A", // Perforoitu - Suora, ei kulmintaa tai jatkoa
  METHOD_5B = "5B", // Perforoitu - Kulmittu jatkettava
  METHOD_5C = "5C", // Perforoitu - Keskeltä kulmittu molemmista päistä
  METHOD_5D = "5D", // Erkanemisviitta 65 astetta kulma
  METHOD_5E = "5E", // Erkanemisviitta jatkettava 65 astetta
  METHOD_5F = "5F", // Perforoitu - Rei'itys keskitetysti suora ei kulmintaa
  METHOD_6A = "6A", // 644 - Toisesta päästä
  METHOD_6B = "6B", // 644 - Molemmista päistä
  METHOD_6C = "6C", // 644 – Vanhanajan viitta pyöreä kärki korkeus 180
  METHOD_6D = "6D", // 644 – Vanhanajan viitta pyöreä kärki korkeus 200
  METHOD_7A = "7A", // Vannekiinnitin
  METHOD_8A = "8A", // Huippukiinnitin
  METHOD_9A = "9A", // Seinäkiinnitys - Reijitys kulmiin, reikä 4mm, ei nurkkapyöristystä
  METHOD_9B = "9B", // Seinäkiinnitys - Reijitys kulmiin + sivuille 400 jaolla, reikä 4mm
  METHOD_9C = "9C", // Ilman reunataivutusta - Reijitys tolppaan yksi 4mm reikä ylös ja alas
  METHOD_9D = "9D", // Ilman reunataivutusta - Olli kiinnitys yhdelle kiinnikkeelle ei nurkkapyöristystä
  METHOD_9E = "9E", // Ilman reunataivutusta - Olli kiinnitys kahdelle kiinnikkeelle ei nurkkapyöristystä
  METHOD_9F = "9F", // Ilman reunataivutusta- Olli kiinnitys yhdelle kiinnikkeelle R40
  METHOD_9G = "9G", // Ilman reunataivutusta- Olli kiinnitys kahdelle kiinnikkeelle R40
  METHOD_9H = "9H", // Ilman reunataivutusta - Olli kiinnitys yhdelle kiinnikkeelle R30
  METHOD_9I = "9I", // Ilman reunataivutusta - Olli kiinnitys kahdelle kiinnikkeelle R30
  METHOD_9J = "9J", // Ilman reunataivutusta - Olli kiinnitys yhdelle kiinnikkeelle R20
  METHOD_9K = "9K", // Ilman reunataivutusta - Olli kiinnitys kahdelle kiinnikkeelle R20
  METHOD_9L = "9L", // Ilman reunataivutusta - Reijitys kulmiin, reikä 4mm, R40 nurkkapyöristys
  METHOD_9M = "9M", // Ilman reunataivutusta - Reijitys kulmiin, reikä 4mm, R30 nurkkapyöristys
  METHOD_AA = "AA", // Listakiinnitys r40
  METHOD_AB = "AB", // Listakiinnitys r30
  METHOD_AC = "AC", // Listakiinnitys suorakulma
}

export enum SheetType {
  SHEET_R1 = "R1", // Laminaatilla
  SHEET_R2 = "R2", // Laminaatilla
  SHEET_R2O = "R2O", // Laminaatilla (päiväloiste)
  SHEET_R3 = "R3", // Laminaatilla
  SHEET_R3O = "R3O", // Laminaatilla (päiväloiste)
}

export enum PlateType {
  ALUMINUM_3 = "A03", // alumiini 3mm
  ALUMINUM_27 = "A27", // aluminiini 2mm
  ALUMINUM_2 = "A02", // aluminiini 2mm
  COMPOSITE_3 = "K03", // composiitti
}

export enum ColoringMode {
  DURST_CMYK = "durst_cmyk",
  AGFA_RGB = "agfa_rgb",
  RGB = "rgb",
}

export enum FontFamily {
  TRAFICOM = "Traficom20",
  ARIALBOLD = "Arial MT Pro",
  SWISS721BT = "Swiss721 BT",
  HELVETICA = "Helvetica",
}

export enum TextSide {
  BOTH = "both",
  FRONT = "front",
  BACK = "back",
}

export enum TextHorizontalAlign {
  START = "start",
  MIDDLE = "middle",
  END = "end",
}

export enum TextVerticalAlign {
  TOP = "top",
  CENTER = "center",
  BOTTOM = "bottom",
}

export enum TextAnchorX {
  START = "start",
  END = "end",
}

export enum TextAnchorY {
  TOP = "top",
  BOTTOM = "bottom",
}
export enum ExtraType {
  ONE_SIDE = "1",
  TWO_SIDE = "2",
  TWO_PIPES = "3",
}

export enum ProductType {
  COMMON = "common",
  DYNAMIC = "dynamic",
}

// Projects
export interface ListProject {
  id: string;
  projectIdentifier: string;
  projectItems: ListProjectItem[];
  orderId: string;
  createdAt: string;
}

export interface ListProjectItem {
  id: string;
  projectId: string;
  productCode: string;
  divided: boolean;
  approved: boolean;
  identifier?: string;
  sheetAmount: number;
  orderItemId?: string;
}

export enum LayoutItemType {
  NODE = "node",
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export type LayoutNode = {
  type: LayoutItemType.NODE;
  width: number;
  height: number;
  node_id: number;
};

export type LayoutHorizontal = {
  type: LayoutItemType.HORIZONTAL;
  width: number;
  height: number;
  children: LayoutItem[];
};

export type LayoutVertical = {
  type: LayoutItemType.VERTICAL;
  width: number;
  height: number;
  children: LayoutItem[];
};

export type LayoutItem = LayoutNode | LayoutHorizontal | LayoutVertical;

export interface Project {
  id: string;
  projectIdentifier: string;
  projectItems: ProjectItem[];
  orderId: string;
  createdAt: string;
}

export interface ProjectItem {
  id: string;
  projectId: string;
  productCode: string;
  divided: boolean;
  approved: boolean;
  svg?: string;
  data: ProjectItemData;
  splitData?: SplitData[];
  layout?: LayoutItem;
  createdAt: Date;
}

export interface ProjectItemData {
  plateType?: PlateType;
  sheetType?: SheetType;
  mountingMethod?: MountingMethod;
  extraType?: ExtraType;
  size: string;
  width: number;
  height: number;
  identifier?: string;
}

export interface SplitData {
  width: number;
  height: number;
  mountingMethod: MountingMethod;
  svg: string;
}
