import React from "react";

interface GridProps {
  id?: string;
  column: string;
  children?: JSX.Element;
}

function Grid(props: GridProps): React.ReactElement {
  return (
    <div id={props.id} className={`col-` + props.column}>
      {props.children}
    </div>
  );
}

export default Grid;
