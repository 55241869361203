import React from "react";
import templateService from "../../services/templateService";
import { CreateTemplateRequest, TemplateModal } from "../../types";

import { FormikState, useFormik } from "formik";
import * as Yup from "yup";
import DOMPurify from "dompurify";

const TemplateSchema = Yup.object().shape({
  name: Yup.string().required("Pakollinen kenttä"),
  template: Yup.string().required("Pakollinen kenttä"),
  backTemplate: Yup.string().nullable(),
  properties: Yup.mixed().required("Pakollinen kenttä"),
});

// eslint-disable-next-line react/display-name
const SignDialog = React.forwardRef(
  (props: TemplateModal, ref: React.Ref<HTMLDivElement>) => {
    const [jsonErrors, setJsonErrors] = React.useState([]);
    const svgRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const backSvgRef =
      React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const propertiesRef =
      React.useRef() as React.MutableRefObject<HTMLInputElement>;

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: {
        name: props.template ? props.template.name : "",
        template: props.template ? props.template.template : "",
        backTemplate: props.template ? props.template.backTemplate : null,
        properties: props.template ? props.template.properties : "",
      },
      validationSchema: TemplateSchema,

      onSubmit: (values, { resetForm }) => {
        handleSubmit(values, resetForm);
      },
    });

    const handleCancel = () => {
      formik.resetForm({});
      svgRef.current.value = "";
      backSvgRef.current.value = "";
      propertiesRef.current.value = "";
      setJsonErrors([]);
      props.onCancel();

      props.modal?.hide();
    };

    const handleSVGFileChange = (
      e: React.FormEvent<HTMLInputElement>,
    ): void => {
      const filelist: FileList | null = (e.target as HTMLInputElement).files;
      const name = (e.target as HTMLInputElement).name;

      if (filelist && filelist.length) {
        const fileReader = new FileReader();
        fileReader.readAsText(filelist[0], "UTF-8");
        fileReader.onload = (e) => {
          // console.log('type', filelist[0].type)
          if (e.target?.result) {
            const svg = DOMPurify.sanitize(e.target.result as string, {
              USE_PROFILES: { svg: true, svgFilters: true },
            });
            formik.setFieldValue(name, svg);
          } else {
            formik.setFieldValue(name, undefined);
          }
        };
      }
    };

    const handleConfigFileChange = (
      e: React.FormEvent<HTMLInputElement>,
    ): void => {
      const filelist: FileList | null = (e.target as HTMLInputElement).files;
      if (filelist && filelist.length) {
        const fileReader = new FileReader();
        fileReader.readAsText(filelist[0], "UTF-8");
        fileReader.onload = (e) => {
          if (e.target?.result) {
            try {
              const parsed = JSON.parse(e.target.result as string);

              formik.setFieldValue("properties", parsed);
            } catch (err) {
              console.error("Not proper json", err);
              formik.setFieldValue("properties", null);
            }
          } else {
            formik.setFieldValue("properties", null);
          }
        };
      }
    };

    const handleSubmit = (
      data: CreateTemplateRequest,
      resetForm: (
        nextState?: Partial<FormikState<CreateTemplateRequest>>,
      ) => void,
    ) => {
      const templateData = {
        name: data.name,
        template: data.template,
        backTemplate: data.backTemplate ?? null,
        properties: data.properties,
      };

      if (props.template) {
        templateService
          .updateTemplate(props.template.id, templateData)
          .then((result) => {
            if (props.template && result.status === 200) {
              props.onUpdate();
              props.onMessage("success", "Kylttipohjan päivitys onnistui");
              resetForm({});
              props.modal?.hide();
              setJsonErrors([]);
              svgRef.current.value = "";
              backSvgRef.current.value = "";
              propertiesRef.current.value = "";
            } else if (result.status === 400 && result.data.message) {
              setJsonErrors(result.data.message);
            } else {
              props.onMessage(
                "danger",
                "Kylttipohjan päivityksessä tapahtui virhe",
              );
              resetForm({});
              props.modal?.hide();
            }
          });
      } else {
        templateService.createTemplate(templateData).then((response) => {
          if (response.status === 201) {
            resetForm({});
            svgRef.current.value = "";
            backSvgRef.current.value = "";
            propertiesRef.current.value = "";

            props.modal?.hide();
            props.onAddTemplate();
            setJsonErrors([]);
            props.onMessage("success", "Kylttipohjan luonti onnistui");
          } else if (response.status === 400 && response.data.message) {
            setJsonErrors(response.data.message);
          } else {
            props.onMessage("danger", "Kylttipohjan luonnissa tapahtui virhe");
          }
        });
      }
    };

    return (
      <div id="templateModal" className="modal" tab-index="-1" ref={ref}>
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {props.template ? "Muokkaa pohjaa" : "Tuo pohja"}
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => handleCancel()}
              ></button>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body">
                <label htmlFor="formFile" className="form-label">
                  Nimi
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                  />
                </div>
                {formik.errors.name && formik.touched.name ? (
                  <div className="text-danger">{formik.errors.name}</div>
                ) : null}
                <label htmlFor="template" className="form-label">
                  SVG-kylttipohja (SVG)
                </label>
                <div className="input-group mb-3">
                  <input
                    type="file"
                    className="form-control"
                    id="template"
                    name="template"
                    onChange={(event) => handleSVGFileChange(event)}
                    ref={svgRef}
                  />
                  <label className="input-group-text" htmlFor="template">
                    Selaa
                  </label>
                </div>
                <label htmlFor="template" className="form-label">
                  SVG-kylttipohja käännetty (SVG) (Ei pakollinen)
                </label>
                <div className="input-group mb-3">
                  <input
                    type="file"
                    className="form-control"
                    id="backTemplate"
                    name="backTemplate"
                    onChange={(event) => handleSVGFileChange(event)}
                    ref={backSvgRef}
                  />
                  <label className="input-group-text" htmlFor="backTemplate">
                    Selaa
                  </label>
                </div>
                {formik.errors.backTemplate && formik.touched.backTemplate ? (
                  <div className="text-danger">
                    {formik.errors.backTemplate}
                  </div>
                ) : null}
                <label htmlFor="properties" className="form-label">
                  Asetustiedosto (JSON)
                </label>
                <div className="input-group mb-3">
                  <input
                    type="file"
                    className="form-control"
                    id="properties"
                    name="properties"
                    onChange={(event) => handleConfigFileChange(event)}
                    ref={propertiesRef}
                  />
                  <label className="input-group-text" htmlFor="properties">
                    Selaa
                  </label>
                </div>
                {jsonErrors
                  ? jsonErrors.map((m, i) => (
                      <div key={i} className="text-danger">
                        {m}
                      </div>
                    ))
                  : null}
                {formik.errors.properties && formik.touched.properties ? (
                  <div className="text-danger">{formik.errors.properties}</div>
                ) : null}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => handleCancel()}
                >
                  Sulje
                </button>
                <button type="submit" className="btn btn-primary">
                  Lähetä
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  },
);

export default SignDialog;
