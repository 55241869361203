import React from "react";

interface RowProps {
  id?: string;
  rowExtra?: string;
  children?: JSX.Element;
}

function Row(props: RowProps): JSX.Element {
  return (
    <div id={props.id} className={`row ${props.rowExtra}`}>
      {props.children}
    </div>
  );
}

export default Row;
