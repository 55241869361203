import axios, { AxiosResponse, AxiosError } from "axios";
import {
  CreateOrder,
  ImportOrder,
  Order,
  OrderItemRequest,
  UpdateOrder,
} from "../types";

const baseUrl =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3000/api/"
    : process.env.REACT_APP_API_URL;
const config = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

const getAllOrders = async (
  page: number,
  limit: number,
  sortField: string,
  sortDirection: number,
  search: string,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(
      baseUrl +
        `orders?page=${page}&limit=${limit}&sortField=${sortField}&sortDirection=${sortDirection}&search=${search}`,
      config,
    );
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const createOrder = async (data: CreateOrder): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(baseUrl + `orders`, data, config);
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const updateOrder = async (
  id: string,
  data: UpdateOrder,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.patch(baseUrl + `orders/${id}`, data, config);
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const importOrder = async (data: ImportOrder): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(baseUrl + `orders/import`, data, config);
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const createOrderItem = async (
  data: OrderItemRequest,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(baseUrl + `order-items`, data, config);
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const updateOrderItem = async (
  id: string,
  data: OrderItemRequest,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.patch(
      baseUrl + `order-items/${id}`,
      data,
      config,
    );
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const deleteOrderItem = async (id: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.delete(baseUrl + `order-items/${id}`, config);
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const getOrderById = async (id: string): Promise<AxiosResponse<Order>> => {
  try {
    const response = await axios.get(baseUrl + `orders/${id}`, config);
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const getOrderItemById = async (id: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(baseUrl + `order-items/${id}`, config);
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

export default {
  getAllOrders,
  createOrder,
  updateOrder,
  importOrder,
  createOrderItem,
  updateOrderItem,
  deleteOrderItem,
  getOrderById,
  getOrderItemById,
};
