import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faMinus,
  faEdit,
  faCloudDownloadAlt,
} from "@fortawesome/free-solid-svg-icons";

import "./Projects.scss";
import projectsService from "./services/projectsService";
import Pagination from "./components/Pagination";
import {
  ListProject,
  ListProjectItem,
  Order,
  Project,
  ProjectItem,
} from "./types";
import Spinner from "./components/Spinner";
import ordersService from "./services/ordersService";

interface Projects {
  project: Partial<Project> | null;
  onMessage: (type: string, text: string) => void;
  onNavigate: (
    type: string,
    order: Order | null,
    project: Project | null,
    projectItemId: string | null,
  ) => void;
}

function Projects(props: Projects): JSX.Element {
  const [projects, setProjects] = useState<ListProject[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(0);
  const [selectedProject, setSelectedProject] =
    useState<ListProject | null>(null);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(true);
  const [saving, setSaving] = useState<string | null>(null);

  const handleSelect = (project: ListProject | null) => {
    setSelectedProject(project);
  };

  const handleProjectItemEdit = (
    listProject: ListProject,
    projectItemId: string,
  ) => {
    setActive(false);

    projectsService.getProjectById(listProject.id).then((response) => {
      if (response.status === 200) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const project = response.data as Project;

        props.onNavigate("/project-item-editor", null, project, projectItemId);
      } else {
        props.onMessage("danger", "Virhe projektin kylttiä haettaessa");
        setActive(true);
      }
    });
  };

  const getAllProjects = (page: number) => {
    projectsService
      .getAllProjects(page, 25, "created_at", 1, "")
      .then((response) => {
        if (response && response.status === 200) {
          setLimit(response.data.limit);
          setTotal(response.data.totalCount);
          for (const project of response.data.data) {
            project.projectItems.sort(
              (a: ProjectItem, b: ProjectItem) =>
                new Date(a.createdAt || 0).getTime() -
                new Date(b.createdAt || 0).getTime(),
            );
          }

          const _projects = response.data.data as ListProject[];
          setProjects(_projects);

          if (!selectedProject && props.project && props.project.id) {
            setSelectedProject(
              _projects.find((project) => props.project?.id === project.id) ||
                null,
            );
          }

          setLoading(false);
        } else {
          props.onMessage("danger", "Projektien latauksessa tapahtui virhe");
        }
      });
  };

  const openOrderById = async (orderId: string) => {
    setActive(false);
    const orderResponse = await ordersService.getOrderById(orderId);
    if (orderResponse.status === 200) {
      props.onNavigate("/orders", orderResponse.data, null, null);
    } else {
      props.onMessage("danger", "Virhe tarjousta haettassa");
      setActive(true);
      getAllProjects(page);
    }
  };

  useEffect(() => {
    setLoading(true);
    getAllProjects(page);
  }, [page]);

  return (
    <>
      <div className="headerArea">
        <h1>Novapoint-projektit</h1>
      </div>

      {loading ? (
        <div className="spinner-center">
          <Spinner />
        </div>
      ) : projects.length === 0 ? (
        <div className="spinner-center">No data</div>
      ) : (
        <>
          <table className="table">
            <thead className="table">
              <tr>
                <th scope="col"></th>
                <th scope="col">Projektitunniste</th>
                <th scope="col">Päivämäärä</th>
                <th scope="col">Kylttien määrä</th>
                <th scope="col">Hyväksytty</th>
                <th scope="col">Tarjous</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => (
                <React.Fragment key={project.id}>
                  <tr>
                    <td>
                      {selectedProject?.id !== project.id ? (
                        <FontAwesomeIcon
                          icon={faPlus}
                          onClick={() => handleSelect(project)}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faMinus}
                          onClick={() => handleSelect(null)}
                        />
                      )}
                    </td>
                    <td>{project.projectIdentifier}</td>
                    <td>{new Date(project.createdAt).toLocaleString("fi")}</td>
                    <td>{project.projectItems.length}</td>
                    <td>
                      {project.projectItems.every((item) => item.approved)
                        ? "Kyllä"
                        : "Ei"}
                    </td>
                    <td>
                      <div className="btn-group" role="group">
                        {project.orderId && (
                          <button
                            type="button"
                            disabled={!active || saving != null}
                            className="btn btn-secondary bold uppercase"
                            onClick={(e) => {
                              e.preventDefault();

                              if (active && !saving) {
                                openOrderById(project.orderId);
                              }
                            }}
                          >
                            Avaa tarjous
                          </button>
                        )}
                        <button
                          type="button"
                          disabled={
                            project.projectItems.length == 0 ||
                            project.projectItems.some((pi) => !pi.approved) ||
                            saving != null
                          }
                          className="btn btn-primary bold uppercase"
                          onClick={async (e) => {
                            e.preventDefault();
                            if (active && !saving) {
                              setActive(false);
                              setSaving(project.id);
                              const response =
                                await projectsService.buildOfferFromProject(
                                  project.id,
                                );
                              if (response.status === 200) {
                                openOrderById(
                                  (response.data as Project).orderId,
                                );
                              } else {
                                props.onMessage(
                                  "danger",
                                  "Virhe tarjousta luodessa",
                                );
                                setActive(true);
                                setSaving(null);
                                getAllProjects(page);
                              }
                            }
                          }}
                        >
                          {saving == project.id
                            ? "Prosessoidaan..."
                            : project.orderId
                            ? "Päivitä tarjous"
                            : "Luo tarjous"}
                        </button>
                      </div>
                    </td>
                    <td></td>
                  </tr>
                  {selectedProject?.id === project.id && (
                    <tr>
                      <td colSpan={5}>
                        <table className="table mb-0 table-nested">
                          <thead>
                            <tr>
                              <th className="th-white" scope="col"></th>
                              <th scope="col">Koodi</th>
                              <th scope="col">Tunniste</th>
                              <th scope="col">Jaettu lamelleihin</th>
                              <th scope="col">Hyväksytty</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {project.projectItems.map(
                              (item: ListProjectItem, i) => (
                                <tr key={i}>
                                  <td className="td-white"></td>
                                  <td className="td-name">
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();

                                        if (active && !saving) {
                                          handleProjectItemEdit(
                                            project,
                                            item.id,
                                          );
                                        }
                                      }}
                                    >
                                      {item.productCode}
                                    </a>
                                  </td>
                                  <td className="td-name">{item.identifier}</td>
                                  <td className="td-name">
                                    {item.divided ? "Kyllä" : "Ei"}
                                  </td>
                                  <td className="td-name">
                                    {item.approved ? "Kyllä" : "Ei"}
                                  </td>
                                  <td className="td-icons">
                                    <FontAwesomeIcon
                                      className="icon"
                                      icon={faEdit}
                                      color={
                                        active && !saving ? "#000" : "#ddd"
                                      }
                                      onClick={() => {
                                        if (active) {
                                          handleProjectItemEdit(
                                            project,
                                            item.id,
                                          );
                                        }
                                      }}
                                    />
                                    {item.sheetAmount > 0 &&
                                      item.orderItemId &&
                                      Array.from(
                                        Array(item.sheetAmount).keys(),
                                      ).map((index) => (
                                        <a
                                          key={index}
                                          href={`/eps/${item.orderItemId}_${index}.eps`}
                                          download={`${item.orderItemId}_${index}.eps`}
                                        >
                                          <FontAwesomeIcon
                                            className="icon"
                                            icon={faCloudDownloadAlt}
                                            color={
                                              active && !saving
                                                ? "#000"
                                                : "#ddd"
                                            }
                                          />
                                          <span>{index}</span>
                                        </a>
                                      ))}
                                    {/* <a
                                      href={`/eps/${item.id}.eps`}
                                      download={`${[item.id]
                                        .filter((i) => !!i)
                                        .join("_")}.eps`}
                                    >
                                      <FontAwesomeIcon
                                        className="icon"
                                        icon={faCloudDownloadAlt}
                                        color={
                                          active && !saving ? "#000" : "#ddd"
                                        }
                                      />
                                    </a> */}
                                  </td>
                                </tr>
                              ),
                            )}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <Pagination
            page={page}
            total={total}
            limit={limit}
            onPageChange={(pageNum) => setPage(pageNum)}
          />
        </>
      )}
    </>
  );
}

export default Projects;
