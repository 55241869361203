import React from "react";

import Container from "./Container";

import logo from "../images/kaskealogo2.png";

import { AuthContext } from "../App";

import "./Navbar.css";
interface NavbarProps {
  onOpenFrontPage: () => void;
}

function Navbar(props: NavbarProps): JSX.Element {
  const { dispatch } = React.useContext(AuthContext);

  const handleLogout = () => {
    dispatch({
      type: "LOGOUT",
      user: null,
    });
  };

  return (
    <nav className="navbar navbar-light kaskea-navbar bg-kaskea">
      <Container size={"-fluid"}>
        <>
          <a
            className="navbar-brand"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              props.onOpenFrontPage();
            }}
          >
            <img src={logo} alt="" width="30%" height="30%" />
          </a>
          <a
            className="logout"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleLogout();
            }}
          >
            Logout
          </a>
        </>
      </Container>
    </nav>
  );
}

export default Navbar;
