import axios, { AxiosResponse } from "axios";
import { UpdateUserPassword } from "../types";

const baseUrl =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3000/api/"
    : process.env.REACT_APP_API_URL;
const config = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

interface LoginRequest {
  email: string;
  password: string;
}

const login = async (data: LoginRequest): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(baseUrl + "auth/login", data, config);
    return response;
  } catch (e) {
    return e.response;
  }
};

const logout = async (): Promise<AxiosResponse> => {
  try {
    const response = await axios.delete(baseUrl + "auth/logout", config);
    return response;
  } catch (e) {
    return e.response;
  }
};

const isOnline = async (): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(baseUrl + "auth/profile", config);
    return response;
  } catch (e) {
    return e.response;
  }
};

const updatePassword = async (
  data: UpdateUserPassword,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(
      baseUrl + `auth/change-password`,
      data,
      config,
    );
    return response;
  } catch (e) {
    return e.response;
  }
};

export default { login, logout, isOnline, updatePassword };
