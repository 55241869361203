import React from "react";
import authService from "../../services/authService";

import { PasswordModal, UpdateUserPassword } from "../../types";

import { FormikState, useFormik } from "formik";
import * as Yup from "yup";

const ChangePasswordSchema = Yup.object().shape({
  id: Yup.string().required("Pakollinen kenttä"),
  password: Yup.string()
    .min(8, "Salasanan minimipituus on 8-merkkiä!")
    .max(25, "Liian pitkä salasana!")
    .required("Pakollinen kenttä"),
  password_confirmation: Yup.string()
    .min(8, "Salasanan minimipituus on 8-merkkiä!")
    .max(25, "Liian pitkä salasana!")
    .oneOf([Yup.ref("password"), null], "Salasanat eivät täsmää")
    .required("Pakollinen kenttä"),
});

// eslint-disable-next-line react/display-name
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PasswordDialog = React.forwardRef((props: PasswordModal, ref: any) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: props.user ? props.user.id : "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: ChangePasswordSchema,
    onSubmit: (values, { resetForm }) => {
      handleSubmit(values, resetForm);
    },
  });

  const handleSubmit = (
    data: UpdateUserPassword,
    resetForm: (nextState?: Partial<FormikState<UpdateUserPassword>>) => void,
  ) => {
    authService
      .updatePassword({
        id: data.id,
        password: data.password,
        password_confirmation: data.password_confirmation,
      })
      .then((response) => {
        if (response && response.status === 201) {
          resetForm({});
          props.onMessage("success", "Salasanan päivitys onnistui");
          props.modal?.hide();
        } else {
          resetForm({});
          props.onMessage("danger", "Salasanan päivityksessä tapahtui virhe");
          props.modal?.hide();
        }
      });
  };

  const handleCancel = () => {
    formik.resetForm({});
    props.modal?.hide();
  };

  return (
    <div id="changePassword" className="modal" tab-index="-1" ref={ref}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Vaihda salasana</h5>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => handleCancel()}
            ></button>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="password" className="col-form-label">
                  Salasana:
                </label>
                <input
                  className="form-control"
                  id="password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />
                {formik.errors.password && formik.touched.password ? (
                  <div className="text-danger">{formik.errors.password}</div>
                ) : null}
              </div>
              <div className="mb-3">
                <label
                  htmlFor="password_confirmation"
                  className="col-form-label"
                >
                  Salasana uudelleen:
                </label>
                <input
                  className="form-control"
                  id="password_confirmation"
                  name="password_confirmation"
                  type="password"
                  onChange={formik.handleChange}
                  value={formik.values.password_confirmation}
                />
                {formik.errors.password_confirmation &&
                formik.touched.password_confirmation ? (
                  <div className="text-danger">
                    {formik.errors.password_confirmation}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => handleCancel()}
              >
                Sulje
              </button>
              <button type="submit" className="btn btn-primary">
                Tallenna
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default PasswordDialog;
