import React from "react";

import Container from "./Container";

import { AuthContext } from "../App";

import "./NavbarHelper.css";

interface NavbarHelper {
  component: string;
  onNavigate: (type: string) => void;
}

function NavbarHelper(props: NavbarHelper): JSX.Element {
  const auth = React.useContext(AuthContext);

  const data = [
    {
      id: 0,
      name: "Kylttipohjat",
      component: "/templates",
      role: ["admin", "sales"],
    },
    {
      id: 1,
      name: "Tarjoukset",
      component: "/orders",
      role: ["admin", "sales"],
    },
    {
      id: 2,
      name: "Novapoint-projektit",
      component: "/projects",
      role: ["admin", "sales"],
    },
    {
      id: 3,
      name: "Käyttäjät",
      component: "/users",
      role: ["admin"],
    },
  ];

  const buttons = data.map((d) => {
    if (auth.state.user !== null && d.role.includes(auth.state.user.role)) {
      return (
        <li
          key={d.id}
          className={props.component === d.component ? "active" : "unactive"}
          onClick={(e) => {
            e.preventDefault();
            props.onNavigate(d.component);
          }}
        >
          <a href="#">{d.name}</a>
        </li>
      );
    }
  });

  return (
    <nav className="navbar navbar-light bg-kaskea-nav-helper">
      <Container size={"-fluid"}>
        <ul>{buttons}</ul>
      </Container>
    </nav>
  );
}

export default NavbarHelper;
