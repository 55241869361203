import { ExtendArea } from "../types";

export type PathSegment = (string | number)[];

enum AXIS {
  X,
  Y,
}

export type SplittedEdges = {
  left: boolean;
  right: boolean;
  top: boolean;
  bottom: boolean;
};

export type SignSplitLine = { axis: AXIS; offset: number };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SVGObjects = Record<string, any>;

export type SplittedSvgData = {
  key: string;
  width: number;
  height: number;
  targetWidth: number;
  targetHeight: number;
  svgObjects: SVGObjects;
  horizontalExtends: ExtendArea[];
  verticalExtends: ExtendArea[];
  splitEdges: SplittedEdges;
  offset: { x: number; y: number };
  nodeId: number;
  splittedHorizontally: boolean;
  splittedVertically: boolean;
  //DEBUG__CUTLINES: any[];
  signSplitLines: SignSplitLine[];
};

const parseSvgSize = (
  doc: XMLDocument,
): { width: number | null; height: number | null } => {
  const width =
    doc.documentElement.getAttribute("data-plate-width") ??
    doc.documentElement.getAttribute("width");
  const height =
    doc.documentElement.getAttribute("data-plate-height") ??
    doc.documentElement.getAttribute("height");

  return {
    width: width !== null ? parseFloat(width.replace(/[^0-9]/g, "")) : null,
    height: height !== null ? parseFloat(height.replace(/[^0-9]/g, "")) : null,
  };
};

const parseSplitEdgesString = (
  doc: XMLDocument,
): {
  top: boolean;
  bottom: boolean;
  right: boolean;
  left: boolean;
} => {
  const dataValue = doc.documentElement.getAttribute("data-split-edges");

  if (dataValue && dataValue.length == 4) {
    return {
      top: dataValue.charAt(0) === "1",
      bottom: dataValue.charAt(1) === "1",
      left: dataValue.charAt(3) === "1",
      right: dataValue.charAt(2) === "1",
    };
  }

  return {
    top: false,
    bottom: false,
    left: false,
    right: false,
  };
};

const parseNodeId = (doc: XMLDocument): number | null => {
  const dataValue = doc.documentElement.getAttribute("data-split-id");

  if (dataValue && dataValue.length) {
    return parseInt(dataValue);
  }

  return null;
};

const genSplitEdgesString = (se: SplittedEdges): string => {
  return `${se.top ? 1 : 0}${se.bottom ? 1 : 0}${se.left ? 1 : 0}${
    se.right ? 1 : 0
  }`;
};

const calculatePoint = (
  type: AXIS,
  original_value: number,
  refSize: number,
  flipPoints: boolean,
  flipExtendAreas: boolean,
  targetSize?: number,
  baseExtendAreas?: ExtendArea[],
): number => {
  //Flix x coordinate if flipPoints flag is true
  const value =
    type === AXIS.X && flipPoints && refSize
      ? refSize - original_value
      : original_value;

  let calculated_point = value;

  if (refSize) {
    let extendAreas: ExtendArea[] = [];
    let area_extend_amount = 0;

    if (type === AXIS.X) {
      const width = targetSize || refSize;
      const width_increase = width - refSize;
      extendAreas = baseExtendAreas?.length
        ? [...baseExtendAreas].sort((a, b) => a.start - b.start)
        : [{ start: 0, end: refSize } as ExtendArea];
      area_extend_amount = width_increase / extendAreas.length;
    } else {
      const height = targetSize || refSize;
      const height_increase = height - refSize;
      extendAreas = baseExtendAreas?.length
        ? baseExtendAreas?.sort((a, b) => a.start - b.start)
        : [{ start: 0, end: refSize } as ExtendArea];
      area_extend_amount = height_increase / extendAreas.length;
    }

    if (type === AXIS.X && flipExtendAreas && refSize) {
      extendAreas = extendAreas.map((ea) => {
        return { start: refSize - ea.end, end: refSize - ea.start };
      });
      extendAreas.reverse();
    }

    for (const ea of extendAreas) {
      if (value <= ea.start) {
        return calculated_point;
      } else if (value > ea.start && value < ea.end) {
        calculated_point +=
          area_extend_amount * ((value - ea.start) / (ea.end - ea.start));
        return calculated_point;
      } else {
        calculated_point += area_extend_amount;
      }
    }
  }
  return calculated_point;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseSegment = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  segment: any[],
  offsetX: number,
  offsetY: number,
  originalWidth: number,
  originalHeight: number,
  flipExtendAreas: boolean,
  targetWidth?: number,
  targetHeight?: number,
  horizontalExtends?: ExtendArea[],
  verticalExtends?: ExtendArea[],
): (number | string)[] => {
  let x = 0;
  let y = 0;

  let x1 = 0;
  let y1 = 0;
  let x2 = 0;
  let y2 = 0;

  if (originalWidth && originalHeight) {
    switch (segment[0]) {
      case "M":
      case "L":
        x = calculatePoint(
          AXIS.X,
          segment[1] + offsetX,
          originalWidth,
          false,
          flipExtendAreas,

          targetWidth,
          horizontalExtends,
        );
        y = calculatePoint(
          AXIS.Y,
          segment[2] + offsetY,
          originalHeight,
          false,
          flipExtendAreas,
          targetHeight,
          verticalExtends,
        );
        return [segment[0], x, y];
      case "H":
        x = calculatePoint(
          AXIS.X,
          segment[1] + offsetX,
          originalWidth,
          false,
          flipExtendAreas,
          targetWidth,
          horizontalExtends,
        );
        return ["H", x];
      case "V":
        y = calculatePoint(
          AXIS.Y,
          segment[1] + offsetY,
          originalHeight,
          false,
          flipExtendAreas,
          targetHeight,
          verticalExtends,
        );
        return ["V", y];
      case "C":
        //FIXME: calculate curve handles separately to fix some scaling issues.
        x1 = calculatePoint(
          AXIS.X,
          segment[1] + offsetX,
          originalWidth,
          false,
          flipExtendAreas,
          targetWidth,
          horizontalExtends,
        );
        y1 = calculatePoint(
          AXIS.Y,
          segment[2] + offsetY,
          originalHeight,
          false,
          flipExtendAreas,
          targetHeight,
          verticalExtends,
        );
        x2 = calculatePoint(
          AXIS.X,
          segment[3] + offsetX,
          originalWidth,
          false,
          flipExtendAreas,
          targetWidth,
          horizontalExtends,
        );
        y2 = calculatePoint(
          AXIS.Y,
          segment[4] + offsetY,
          originalHeight,
          false,
          flipExtendAreas,
          targetHeight,
          verticalExtends,
        );
        x = calculatePoint(
          AXIS.X,
          segment[5] + offsetX,
          originalWidth,
          false,
          flipExtendAreas,
          targetWidth,
          horizontalExtends,
        );
        y = calculatePoint(
          AXIS.Y,
          segment[6] + offsetY,
          originalHeight,
          false,
          flipExtendAreas,
          targetHeight,
          verticalExtends,
        );
        return ["C", x1, y1, x2, y2, x, y];
      case "A":
        x = calculatePoint(
          AXIS.X,
          segment[6] + offsetX,
          originalWidth,
          false,
          flipExtendAreas,
          targetWidth,
          horizontalExtends,
        );
        y = calculatePoint(
          AXIS.Y,
          segment[7] + offsetY,
          originalHeight,
          false,
          flipExtendAreas,
          targetHeight,
          verticalExtends,
        );

        return [
          "A",
          segment[1],
          segment[2],
          segment[3],
          segment[4],
          segment[5],
          x,
          y,
        ];
      case "z":
      case "Z":
        return ["Z"];
    }
  }
  return segment;
};

const getTargetWidth = (
  width: number,
  splitEdges: SplittedEdges,
): {
  width: number;
} => {
  const widthOffset =
    (splitEdges.left && splitEdges.right) ||
    (!splitEdges.left && !splitEdges.right)
      ? 0
      : 20;

  const frooredWidth = Math.round(width - ((width - widthOffset) % 40));

  return {
    width:
      Math.abs(width - frooredWidth) < 20 ? frooredWidth : frooredWidth + 40,
  };
};

const getTargetHeight = (
  height: number,
): {
  height: number;
} => {
  const flooredHeight = Math.round(height - (height % 10));

  return {
    height:
      Math.abs(height - flooredHeight) < 5 ? flooredHeight : flooredHeight + 10,
  };
};

const getTargetSize = (
  width: number,
  height: number,
  splitEdges: SplittedEdges,
): {
  width: number;
  height: number;
} => {
  return {
    ...getTargetWidth(width, splitEdges),
    ...getTargetHeight(height),
  };
};

export {
  AXIS,
  parseSvgSize,
  parseSplitEdgesString,
  genSplitEdgesString,
  parseNodeId,
  calculatePoint,
  parseSegment,
  getTargetWidth,
  getTargetHeight,
  getTargetSize,
};
