import React from "react";

import "./Alert.css";

interface AlertProps {
  label: string;
  message: string;
}

function Alert(props: AlertProps): React.ReactElement {
  return (
    <div className={`alert alert-${props.label}`} role="alert">
      {props.message}
    </div>
  );
}

Alert.defaultProps = {
  label: "success",
};

export default Alert;
