import { Modal } from "bootstrap";
import React from "react";
import { Template } from "../../types";

interface ConfimationModal {
  modal: Modal | null;
  template: Template | null;
  onConfirmation: () => void;
}

// eslint-disable-next-line react/display-name
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line react/display-name
const ConfirmationDialog = React.forwardRef(
  (props: ConfimationModal, ref: React.Ref<HTMLDivElement>) => {
    const handleCancel = () => {
      props.modal?.hide();
    };

    const handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
      e.preventDefault();
      props.onConfirmation();
    };

    return (
      <div id="confirmationModal" className="modal" tab-index="-1" ref={ref}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Vahvista poisto</h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => handleCancel()}
              ></button>
            </div>
            <form>
              <div className="modal-body">
                Haluatko varmasti poistaa{" "}
                <span className="text-danger">{props.template?.name}</span>?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => handleCancel()}
                >
                  Ei
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={(e) => handleSubmit(e)}
                >
                  Kyllä
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  },
);

export default ConfirmationDialog;
