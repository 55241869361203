import axios, { AxiosError, AxiosResponse } from "axios";
import { LayoutItem, ProjectItemData, SplitData } from "../types";

const baseUrl =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3000/api/"
    : process.env.REACT_APP_API_URL;
const config = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

const getAllProjects = async (
  page: number,
  limit: number,
  sortField: string,
  sortDirection: number,
  search: string,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(
      baseUrl +
        `projects/projects?page=${page}&limit=${limit}&sortField=${sortField}&sortDirection=${sortDirection}&search=${search}`,
      config,
    );
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

// const updateProjectItem = async (
//   id: string,
//   data: ProjectItemRequest,
// ): Promise<AxiosResponse> => {
//   try {
//     const response = await axios.patch(
//       baseUrl + `project-items/${id}`,
//       data,
//       config,
//     );
//     return response;
//   } catch (e) {
//     return e.response;
//   }
// };

const getProjectById = async (id: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(
      baseUrl + `projects/project/${id}`,
      config,
    );
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const buildOfferFromProject = async (id: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(
      baseUrl + `projects/build-offer/${id}`,
      {},
      config,
    );
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const getProjectItemById = async (id: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(
      baseUrl + `projects/project-item/${id}`,
      config,
    );
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const approveProjectItem = async (
  id: string,
  data: ProjectItemData,
  splitData: SplitData[] | undefined,
  layout: LayoutItem | undefined,
  approved: boolean,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(
      baseUrl + `projects/project-item/${id}/approve`,
      { id, data, splitData, layout, approved },
      config,
    );
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

export default {
  getAllProjects,
  getProjectById,
  getProjectItemById,
  approveProjectItem,
  buildOfferFromProject,
};
