import React, { useEffect, useState } from "react";
import templateService from "../../services/templateService";
import Sign from "../../Sign";
import { Order, TemplateStub, TextSide } from "../../types";

import "./OrderDialog.css";

interface OrderModal {
  id?: string;
  order: Order | null;
  onSetTemplate: (order: Order | null, template: TemplateStub) => void;
  onMessage: (type: string, text: string) => void;
}

function OrderDialog(props: OrderModal): React.ReactElement {
  const [templates, setTemplates] = useState<TemplateStub[]>([]);

  useEffect(() => {
    let mounted = true;
    templateService.getAllTemplates(0, 200, "name", 0).then((response) => {
      if (response && response.status === 200 && mounted) {
        setTemplates(response.data.data);
      } else {
        props.onMessage("danger", "Kylttipohjien latauksessa tapahtui virhe");
      }
    });

    return function cleanup() {
      mounted = false;
    };
  }, []);

  return (
    <div id="orderModal" className="modal modal-order" tab-index="-1">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Valitse kyltti</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body order-modal-body">
            <div className="container-fluid">
              <div className="row g-4">
                {templates.map((template) => {
                  return (
                    <div
                      key={template.id}
                      data-bs-dismiss="modal"
                      onClick={() => props.onSetTemplate(props.order, template)}
                      className="col-4 order-modal-template-box"
                    >
                      <div className="p-3 border sign-image">
                        <Sign
                          key={`t-${template.id}`}
                          template={template}
                          isBackSide={false}
                          className="sign-preview"
                          data={{
                            identifier: "",
                            colorPreset:
                              template.properties.colorPresets[0].name,
                            texts: template.properties.texts
                              .filter((tt) => tt.side !== TextSide.BACK)
                              .map((tt) => ({
                                id: tt.id,
                                text: tt.placeHolder,
                                fontFamily: tt.fonts[0],
                                fontSize:
                                  template.properties.defaultFontSize &&
                                  tt.fontSizes.includes(
                                    template.properties.defaultFontSize,
                                  )
                                    ? template.properties.defaultFontSize
                                    : tt.fontSizes[0],
                              })),
                          }}
                        />
                      </div>
                      <div className="p-3 border bg-light">
                        <div className="sign-title">
                          <h3>{template.name}</h3>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Sulje
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDialog;
