import React, { useEffect } from "react";

import Login from "./Login";
import Home from "./Home";

import "./App.scss";
import authService from "./services/authService";

import { InitialStateType, Action } from "./types";
import Loading from "./Loading";

const initialState = {
  isAuthenticated: false,
  user: null,
};

export const AuthContext = React.createContext<{
  state: InitialStateType;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => null,
});

const reducer = (state: InitialStateType, action: Action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.user,
      };
    case "LOGOUT":
      authService.logout();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

function App(): JSX.Element {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    setLoading(true);
    authService.isOnline().then((response) => {
      setLoading(false);
      if (response?.status === 200) {
        dispatch({
          type: "LOGIN",
          user: response.data.user,
        });
      } else {
        dispatch({
          type: "LOGOUT",
          user: null,
        });
      }
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {!state.isAuthenticated && loading ? (
        <Loading />
      ) : !state.isAuthenticated && !loading ? (
        <Login />
      ) : (
        <Home />
      )}
    </AuthContext.Provider>
  );
}

export default App;
