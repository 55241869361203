import React, { useState } from "react";
import ordersService from "../../services/ordersService";
import { Order } from "../../types";

import "./ImportOrderDialog.css";

interface ImportOrderModal {
  onImport: (order: Order | null) => void;
  onMessage: (type: string, text: string) => void;
}

function ImportOrderDialog(props: ImportOrderModal): React.ReactElement {
  const [remoteId, setRemoteId] = useState<number | null>(null);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    setRemoteId(
      (e.currentTarget.value && parseInt(e.currentTarget.value)) || null,
    );
  };

  const handleSubmit = () => {
    if (remoteId) {
      ordersService.importOrder({ remoteId }).then((response) => {
        if (response.status === 201) {
          props.onImport(response.data);
        } else {
          if (response.status === 409) {
            props.onMessage(
              "danger",
              `Tarjous numerolla '${remoteId}' on jo olemassa`,
            );
          } else if (response.status === 404) {
            props.onMessage(
              "danger",
              `Tarjousta numerolla '${remoteId}' ei löytynyt`,
            );
          } else {
            props.onMessage(
              "danger",
              `Virhe tarjousta '${remoteId}' haettaessa`,
            );
          }
          props.onImport(null);
        }
      });
    }
  };

  return (
    <div
      id="importOrderModal"
      className="modal modal-import-order"
      tab-index="-1"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Anna tarjouksen numero</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form>
            <div className="modal-body">
              <div className="mb-3">
                <label htmlFor="remoteId" className="col-form-label">
                  Tarjouksen numero Lemonsoftissa:
                </label>
                <input
                  type="number"
                  className="form-control"
                  name="remoteId"
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </form>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Sulje
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => handleSubmit()}
            >
              Tuo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportOrderDialog;
