import axios, { AxiosError, AxiosResponse } from "axios";
import {
  CreateTemplatePresetRequest,
  UpdateTemplatePresetRequest,
} from "../types";

const baseUrl =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3000/api/"
    : process.env.REACT_APP_API_URL;
const config = {
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
};

const getAllTemplatePresets = async (): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(baseUrl + `template-presets`, config);
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const getTemplatePresetsForTemplate = async (
  id: string,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(
      baseUrl + `template-presets/for-template/${id}`,
      config,
    );
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const createTemplatePreset = async (
  data: CreateTemplatePresetRequest,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(
      baseUrl + `template-presets`,
      data,
      config,
    );
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const updateTemplatePreset = async (
  id: string,
  preset: UpdateTemplatePresetRequest,
): Promise<AxiosResponse> => {
  try {
    const response = await axios.patch(
      baseUrl + `template-presets/${id}`,
      preset,
      config,
    );
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

const deleteTemplatePreset = async (id: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.delete(
      baseUrl + `template-presets/${id}`,
      config,
    );
    return response;
  } catch (e) {
    return (e as AxiosError).response as AxiosResponse;
  }
};

export default {
  getAllTemplatePresets,
  getTemplatePresetsForTemplate,
  createTemplatePreset,
  updateTemplatePreset,
  deleteTemplatePreset,
};
