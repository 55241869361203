import { Modal } from "bootstrap";
import React, { useState } from "react";
import templatePresetService from "../../services/templatePresetService";
import { TemplatePreset } from "../../types";

interface ConfirmTemplatePresetDeleteDialog {
  modal: Modal | null;
  presets: TemplatePreset[];
  onDelete: () => void;
  onMessage: (type: string, text: string) => void;
}

// eslint-disable-next-line react/display-name
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line react/display-name
const ConfirmTemplatePresetDeleteDialog = React.forwardRef(
  (
    props: ConfirmTemplatePresetDeleteDialog,
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const [selectedTemplatePreset, setSelectedTemplatePreset] =
      useState<TemplatePreset | null>(null);

    const handleTemplatePresetChange = (
      e: React.FormEvent<HTMLSelectElement>,
    ) => {
      const value = e.currentTarget.value;
      const preset = props.presets.find((p) => p.id == value) || null;
      setSelectedTemplatePreset(preset);
    };

    const handleCancel = () => {
      props.modal?.hide();
    };

    const handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (selectedTemplatePreset) {
        templatePresetService
          .deleteTemplatePreset(selectedTemplatePreset.id)
          .then((response) => {
            if (response.status === 200) {
              props.onDelete();
              setSelectedTemplatePreset(null);
              props.modal?.hide();
            } else {
              props.onMessage("danger", "Virhe kylttipohjaa poistettaessa");
              setSelectedTemplatePreset(null);
              props.modal?.hide();
            }
          });
      }
    };

    return (
      <div id="confirmationModal" className="modal" tab-index="-1" ref={ref}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"></h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => handleCancel()}
              ></button>
            </div>
            <form>
              <div className="modal-body">
                <select
                  className="form-select"
                  name="preset"
                  onChange={(e) => handleTemplatePresetChange(e)}
                >
                  <option key={0} value="">
                    Ei valittu
                  </option>
                  {props.presets?.map((t) => (
                    <option key={t.id} value={t.id}>
                      {t.name}
                    </option>
                  ))}
                </select>
                Haluatko varmasti poistaa{" "}
                <span className="text-danger">
                  {selectedTemplatePreset?.name ?? "-"}
                </span>
                ?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => handleCancel()}
                >
                  Sulje
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => handleSubmit(e)}
                  disabled={!selectedTemplatePreset}
                >
                  Poista
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  },
);

export default ConfirmTemplatePresetDeleteDialog;
