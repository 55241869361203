import React, { useEffect, useState, useRef } from "react";

import { Modal } from "bootstrap";

import userService from "./services/userService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faKey } from "@fortawesome/free-solid-svg-icons";

import UserDialog from "./components/Dialog/UserDialog";

import { User } from "./types";

import "./Users.scss";
import Spinner from "./components/Spinner";
import PasswordDialog from "./components/Dialog/PasswordDialog";
import Pagination from "./components/Pagination";

interface Users {
  onMessage: (type: string, text: string) => void;
}

function Users(props: Users): JSX.Element {
  const [userModal, setUserModal] = useState<Modal | null>(null);
  const [changePasswordModal, setChangePasswordModal] =
    useState<Modal | null>(null);

  const userModalRef = useRef<Element>();
  const changePasswordModalRef = useRef<Element>();

  const [users, setUsers] = useState<User[]>([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  // const handleUpdateUsers = (type: string, newUser: User) => {
  //   if (type === 'create') {
  //     setUsers(users.concat(newUser))
  //   } else {
  //     setUsers(users.filter(user => user.id !== newUser.id).concat(newUser))
  //   }
  // }

  const handleCreateUser = (user: User | null) => {
    setSelectedUser(user);
    if (userModal) {
      userModal.show();
    }
  };

  const handleUserPasswordChange = (user: User) => {
    setSelectedUser(user);
    if (changePasswordModal) {
      changePasswordModal.show();
    }
  };

  useEffect(() => {
    if (userModalRef.current) {
      setUserModal(new Modal(userModalRef.current, { backdrop: "static" }));
    }
  }, []);

  useEffect(() => {
    if (changePasswordModalRef.current) {
      setChangePasswordModal(
        new Modal(changePasswordModalRef.current, { backdrop: "static" }),
      );
    }
  }, []);

  const fetchUsers = () => {
    setLoading(true);
    userService.getUsers().then((response) => {
      setLimit(25);
      setTotal(response.length);
      setUsers(response);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <UserDialog
        modal={userModal}
        user={selectedUser}
        ref={userModalRef}
        onSetUser={() => fetchUsers()}
        onMessage={(type, text) => props.onMessage(type, text)}
      />
      <PasswordDialog
        modal={changePasswordModal}
        user={selectedUser}
        ref={changePasswordModalRef}
        onMessage={(type, text) => props.onMessage(type, text)}
      />
      <div className="headerArea">
        <h1>Käyttäjät</h1>
        <button
          type="button"
          className="btn btn-primary btn-sm btn-kaskea uppercase"
          onClick={() => handleCreateUser(null)}
        >
          Uusi käyttäjä
        </button>
      </div>
      {loading ? (
        <div className="spinner-center">
          <Spinner />
        </div>
      ) : users.length === 0 ? (
        <div className="spinner-center">No data</div>
      ) : (
        <>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Etunimi</th>
                <th scope="col">Sukunimi</th>
                <th scope="col">Sähköposti</th>
                <th scope="col">Rooli</th>
                <th scope="col">Aktiivinen</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {users
                .sort((a, b) => {
                  if (a.lastname > b.lastname) {
                    return 1;
                  }

                  if (a.lastname < b.lastname) {
                    return -1;
                  }
                  return 0;
                })
                .map((user: User, i: number) => (
                  <tr key={i}>
                    <td>{user.firstname}</td>
                    <td>{user.lastname}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>{user.active ? "Kyllä" : "Ei"}</td>
                    <td>
                      <FontAwesomeIcon
                        className="icon"
                        icon={faEdit}
                        onClick={() => handleCreateUser(user)}
                      />
                      <FontAwesomeIcon
                        className="icon"
                        icon={faKey}
                        onClick={() => handleUserPasswordChange(user)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <Pagination
            page={page}
            total={total}
            limit={limit}
            onPageChange={(pageNum) => setPage(pageNum)}
          />
        </>
      )}
    </>
  );
}

export default Users;
