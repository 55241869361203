import React from "react";

function Loading(): JSX.Element {
  return (
    <>
      <div>Loading...</div>
    </>
  );
}

export default Loading;
